import { React } from "react";
import {moment} from "moment";
const Footer = ({img,title,description}) => {

  return (
    <>
    <div  className="row justify-content-center mt-5">
    <div className="col-12 text-center   mt-3">

    <img
              src={require("../assests/Images/footer-logo.png")}
              className="footer-logo" alt="footer"
            />
            </div>
    </div>
    <div  className="row border-grey  mt-5 mb-5 justify-content-between">
    <div className="col-12 col-md-4 text-center text-md-start mt-3 grey">
    &copy; {new Date().getFullYear()} KHCF. All rights reserved.
    </div>
    <div className="col-12 col-md-4 mt-3 text-center text-md-end footer-icons">
    <i className="fa-brands fa-twitter mx-2  grey pointer"></i>
    <i className="fa-brands  fa-linkedin mx-2 grey pointer"></i>
    <i className="fa-brands  fa-facebook mx-2 grey pointer"></i>
    <i className="fa-brands fa-github mx-2 grey pointer"></i>
    <i className="fa fa-hand-peace mx-2 grey pointer"></i>
    <i className="fa fa-basketball mx-2 grey pointer"></i>

      </div>
    </div>
    </>
  );
};

export default Footer;
