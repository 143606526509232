import { React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Button } from "primereact/button";
import { Steps } from "primereact/steps";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { ProgressBar } from 'primereact/progressbar';
import AppConsts from "../../shared/constant/appConsts";
import { ProjectService } from "../../shared/Services/ProjectService";
import { SharedService } from "../../shared/Services/sharedService";
import { MessageTypeConstant } from "../../shared/constant/messageTypeConstant";
import {Dialog} from "primereact/dialog";
import ViewVolunteer from "./ViewVolunteer";

const VolunteersList = ({}) => {
   
  const { t } = useTranslation();
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [showVolunteer, setShowVolunteer] = useState(false);
  const [vId, setVId] = useState(null);
  const [header, setHeader] = useState("");

  const { isAdmin, isVolunteer, isRequester } = useOutletContext();
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
  });
  function initializeColumns() {
    setColumns([
      { field: "volunteerName", header: t("Volunteer"),  },
      { field: "projectNameEn", header: t("Project Applied"),
      hidden: activeIndex !==1
    },
    { field: "shiftTypeText", header: t("Shift"),
    hidden: activeIndex !==1
  },
      {
        field: "status",
        header: t("Status"),
        body: statusBodyTemplate,
        hidden: activeIndex !==1


      },
      {
        field: "volunteerMobile",
        header: t("Mobile"),
        hidden: activeIndex ===1


      },
      {
        field: "actions",
        header: t("Actions"),
        body: actionsBodyTemplate,
      },
    ]);
  }

  const statusBodyTemplate = (rowData) => {
    return (
      <>
 
          <span className={`status-shadow status-${rowData.statusId}`}>
            {rowData.statusText}
          </span>
        
   
      </>
    );
  };
  const actionsBodyTemplate = (rowData) => {
    return (
      <>
       {rowData.statusId === 1 && (
          <span
            className="actionList"
            onClick={() => {
              SharedService.ShowLoader();
              ProjectService.approveRequestAssignProject(rowData.id).then((res) => {
                SharedService.HideLoader();
                if (res.data.errorCode === 0) {
                 SharedService.ShowToast(t("Approved Successfully"),MessageTypeConstant.Success);
                 fillDataTable();
                }
              });
            }}
          >
            <i role="button" className="fa fa-check" aria-hidden="true" title={t("Approve")}></i>
          </span>
        )}
              {rowData.statusId === 1 && (
          <span
            className="actionList"
            onClick={() => {
              SharedService.ShowLoader();
              ProjectService.RejectRequestAssignProject(rowData.id).then((res) => {
                SharedService.HideLoader();
                if (res.data.errorCode === 0) {
                 SharedService.ShowToast(t("Rejected Successfully"),MessageTypeConstant.Success);
                 fillDataTable();
                }
              });
            }}
          >
            <i role="button" className="fa fa-times" aria-hidden="true" title={t("Reject")}></i>
          </span>
        )}
        <span
          className="actionList"
          onClick={() => {
          setVId(rowData);


          }}
        >
          <i role="button" className="fa fa-eye" aria-hidden="true" title={t("View")}></i>
        </span>
       
     


      </>
    );
  };

  useEffect(() => {
    initializeColumns();
    fillDataTable(true);

    // eslint-disable-next-line
  }, [activeIndex]);

  function fillDataTable(reset,page){
  
    if(reset){
      setLazyParams({
        first:0,
        rows:AppConsts.pageSize,
        page:1,
        sortField:null,
        sortOrder:null
      })
    }
   let data ={
    pageNumber:page? page :1,
    pageSize:AppConsts.pageSize,
    status:activeIndex,
   }
   SharedService.ShowLoader();

    ProjectService.volunteerList(data).then((res) => {
      SharedService.HideLoader();

      if (res.data.errorCode === 0) {
        setTotalRecords(res.data.data.totalFilteredRecords);
        setRows(res.data.data.data);
      }
    });
  }

  const onPageChanged = (e) => {
    setLazyParams(e);

    fillDataTable(false,e.page + 1);
  };
  const dynamicColumns = columns.map((col) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        body={col.body}
        hidden={col.hidden}
      />
    );
  });

  const TabsTemplate = (rowData) => {
    return (
      <>
        <div
          className={`col-3 col-md-6 justify-content-center ${
            activeIndex === rowData.index ? "active" : ""
          }`}
        >
          {/* <div>{rowData.total}</div> */}
          <div>{rowData.label}</div>

          <div></div>
        </div>
      </>
    );
  };
  const tabsItem = [
    {
      label: t("All Volunteers"),
      total: 10,
      template: TabsTemplate,
      index: 0,

      command: (event) => {},
    },
    {
      label: t("Pending Volunteers"),
      command: (event) => {},
      index: 1,
      total: 40,

      template: TabsTemplate,
    },

    {
      label: t("Unassigned Volunteers"),
      command: (event) => {},
      total: 30,

      index: 2,
      template: TabsTemplate,
    },
    {
      label: t("Assigned Volunteers"),
      total: 20,
      command: (event) => {},
      index: 3,
      template: TabsTemplate,
    },
 

  ];

  useEffect(() => {
    initializeColumns();
    fillDataTable();
    // eslint-disable-next-line
  }, []);

  return (
    <>
        <Dialog
      header={vId?.volunteerName}
      visible={vId !==null}
      style={{minWidth:"30vw", maxHeight:"95vh"}}
      onHide={
        ()=>{
          setVId(null);
        }
      }
>
  {(vId)&& <ViewVolunteer id={vId?.volunteerId} />}

    </Dialog>

      <div className="container mt-3 mb-5 ">
        <div className="row justify-content-between">
          <div className="col-6 col-md-3 title">{t("Volunteers List")}</div>
        </div>
        <div className="row mt-4">
          {tabsItem.map((rowData, i) => {
            return (
              <div
                className={`col-md-2 col-6 mt-2 text-center tab-box pointer py-5 ${
                  "tab-box" + rowData.index
                } ${activeIndex === rowData.index ? "active" : ""}`}
                onClick={() => {
                  setActiveIndex(rowData.index);
                }}
                key={"tab" + rowData.index}
              >
                {/* <span className="number">{rowData.total}</span> */}
                <div className="label">{rowData.label}</div>

                <div></div>
              </div>
            );
          })}
        </div>
     
    
        <div className="row mt-5 mb-5 table-div">
        <DataTable
            rows={AppConsts.pageSize}
            paginator
            paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
            value={rows}
            responsiveLayout="scroll"
            stripedRows
            className="centeredColumnContent"
            lazy={true}
            first={lazyParams.first}
            totalRecords={totalRecords}
            onPage={onPageChanged}
            emptyMessage={t("No Records")}
          >
            {dynamicColumns}
          </DataTable>
        </div>
      </div>
    </>
  );
};

export default VolunteersList;

