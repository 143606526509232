export const MessageTypeConstant = {
    Success : 1,
    Info :2,
    Error:3,
    Warning:4,
    Warn : 5,
}



