import { Roles } from "../constant/Roles";
import http from "./httpService";

export const UserService = {

  GetUserRoleEnumsLookups,
  list,
  CreateOrUpdate,
  GetById
};


  function GetUserRoleEnumsLookups(){
    return http.get(`/api/User/GetUserRoleEnumsLookups`);
  }

  function GetById(id){
    return http.get(`/api/User/GetById?id=${id}`);
  }

  function list(data){
    return http.post(`/api/User/List`, data);
  }

  function CreateOrUpdate(data){
    return http.post(`/api/User/CreateOrUpdate`, data);
  }



