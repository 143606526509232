import { useTranslation } from "react-i18next";
import React, { useState, useEffect, useContext } from "react";
import { FileUpload } from 'primereact/fileupload';
import { InputTextarea } from "primereact/inputtextarea";
import { useFormik } from "formik";
import * as yup from "yup";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import moment from "moment";
import { Image } from 'primereact/image';
import { InputNumber } from 'primereact/inputnumber';
import { useNavigate  } from "react-router-dom";


import { WizardDataContext } from "../../../Contexts/WizardDataContext";
import { HomeService } from "../../../shared/Services/HomeService";
import { FormMode } from "../../../shared/constant/FormMode";
import { AuthService } from "../../../shared/Services/AuthService";

const Step1 = () => {
  const { t } = useTranslation();
const [departments, setDepartment] = useState([]);
const [genders, setGenders] = useState([]);
const [boolOptions, setBoolOptions] = useState([]);

const [majors, setMajors] = useState([]);
const navigate = useNavigate();

  const { wizardData, setWizardData,setActiveIndex,setStep1,step1 } = useContext(WizardDataContext);

  useEffect(() => {
    fillDepartments();
    fillMajors();
    fillGenders();
    fillBoolean();
  }, []);
function fillDepartments(){
 
    HomeService.getDepartmentLookups().then((res) => {
      if (res.data.errorCode === 0) {
        let options = res.data.data.map((x) => {
          return { name:  AuthService.isArabic() ? x.nameAr : x.nameEn , code: x.id };
        });
        setDepartment(options);
      }
    });
  
}

function fillMajors(){
 
  HomeService.getMajors().then((res) => {
    if (res.data.errorCode === 0) {
      let options = res.data.data.map((x) => {
        return { name: x.nameEn , code: x.id };
      });
      setMajors(options);
    }
  });

}

function fillGenders() {
  setGenders([
    { name: t("Male"), code: 1 },
    { name: t("Female"), code: 2 },
    { name: t("Both"), code: 3 },
  ]);
}

function fillBoolean() {
  setBoolOptions([
    { name: t("Yes"), code: true },
    { name: t("No"), code: false },
  ]);
}









  const formik = useFormik({

    initialValues: step1? step1: {
      Id:null,
      NameAr:"",
      NameEn:"",
      DescriptionAr:"",
      DescriptionEn:"",
      NumberOfVolunteersNeeded:0,
      VolunteeringLocation: "",
      DepartmentId: "",
      From:"",
      To:"",
      ImageFile:"",
      MajorId:"",
      VoulnteerAgeFrom:0,
      VoulnteerAgeTo:0,
      ProjectVoluntterGender:"",
      DressCode:"",
      TransportationProvided:false,
      ShowSpecialRequirment:false,
      AllowanceProvided:false


    },
    validationSchema: yup.object().shape({
      // ImageFile: yup
      //   .mixed()
      //   .test(
      //     "fileSize",
      //     t("File too large"),
      //     value => value && value.size >= 10000000
      //   ),
        NameAr: yup.string().required(t("Required Field")),
        // NameEn: yup.string().required(t("Required Field")),
        DescriptionAr: yup.string().required(t("Required Field")),
        // DescriptionEn: yup.string().required(t("Required Field")),
        NumberOfVolunteersNeeded: yup.string().required(t("Required Field")),
        DressCode: yup.string().required(t("Required Field")),
        VolunteeringLocation: yup.string().required(t("Required Field")),
        DepartmentId: yup.number().required(t("Required Field")),
        From: yup.string().required(t("Required Field")),
        To: yup.string().required(t("Required Field")),
        MajorId: yup.string().required(t("Required Field")),
        VoulnteerAgeFrom: yup.string().required(t("Required Field")),
        VoulnteerAgeTo: yup.string().required(t("Required Field")),
        ProjectVoluntterGender: yup.number().required(t("Required Field")),
  }),
    enableReinitialize: true,

    onSubmit: (data) => {
      setActiveIndex(1);
      setStep1(data);
    
    },
  });

  return (
    <>
      <div >
        {" "}
        <form onSubmit={formik.handleSubmit} className="p-fluid">
          <div className="row">
            {/* <div className="col-12 mt-1 text-center ">
              <span className="text-start">
                <label htmlFor="NameEn" className="d-inline-block mt-4  w-100">
                  {t("Activity Name/Campaign Name English")}
                </label>
                <InputText
                  id="NameEn"
                  name="NameEn"
                  className="w-100"
                  value={formik.values.NameEn}
                  onChange={formik.handleChange}
                  disabled={
                    !(
                      wizardData.mode === FormMode.NEW ||
                      wizardData.mode === FormMode.EDIT
                    )
                  }
                />
                {formik.touched.NameEn && formik.errors.NameEn && (
                  <label className="errorMessage w-100">
                    {formik.errors.NameEn}
                  </label>
                )}
              </span>
            </div> */}
            <div className="col-12 mt-1 text-center ">
              <span className="text-start">
                <label htmlFor="NameAr" className="d-inline-block mt-4  w-100">
                  {t("Activity Name/Campaign Name Arabic")}
                </label>
                <InputText
                  id="NameAr"
                  name="NameAr"
                  className="w-100"
                  value={formik.values.NameAr}
                  onChange={formik.handleChange}
                  disabled={
                    !(
                      wizardData.mode === FormMode.NEW ||
                      wizardData.mode === FormMode.EDIT
                    )
                  }
                />
                {formik.touched.NameAr && formik.errors.NameAr && (
                  <label className="errorMessage w-100">
                    {formik.errors.NameAr}
                  </label>
                )}
              </span>
            </div>
            <div className="col-12 mt-1 text-center ">
              <span className="text-start">
                <label
                  htmlFor="NumberOfVolunteersNeeded"
                  className="d-inline-block mt-2  w-100"
                >
                  {t("Number of Volunteers Needed")}
                </label>
                <InputNumber
                  showButtons
                  min={1}
                  id="NumberOfVolunteersNeeded"
                  name="NumberOfVolunteersNeeded"
                  className="w-100"
                  value={formik.values.NumberOfVolunteersNeeded}
                  onValueChange={formik.handleChange}
                  disabled={
                    !(
                      wizardData.mode === FormMode.NEW ||
                      wizardData.mode === FormMode.EDIT
                    )
                  }
                />
                {formik.touched.NumberOfVolunteersNeeded &&
                  formik.errors.NumberOfVolunteersNeeded && (
                    <label className="errorMessage w-100">
                      {formik.errors.NumberOfVolunteersNeeded}
                    </label>
                  )}
              </span>
            </div>

            <div className="col-12 mt-1 text-center">
              <span className="text-start">
                <label
                  htmlFor="DepartmentId"
                  className="d-inline-block mt-2  w-100"
                >
                  {t("Name of Department")}
                </label>
                <Dropdown
                  disabled={
                    !(
                      wizardData.mode === FormMode.NEW ||
                      wizardData.mode === FormMode.EDIT
                    )
                  }
                  optionLabel="name"
                  id="DepartmentId"
                  value={
                    departments[
                      departments.findIndex(
                        (obj) => obj.code === formik.values.DepartmentId
                      )
                    ]
                  }
                  options={departments}
                  onChange={(e) => {
                    debugger;
                    formik.setFieldValue("DepartmentId", e.value.code);
                  }}
                  className={`w-100`}
                  placeholder={t("select")}
                />

                {formik.touched.DepartmentId && formik.errors.DepartmentId && (
                  <label className="errorMessage w-100">
                    {formik.errors.DepartmentId}
                  </label>
                )}
              </span>
            </div>
            <div className="col-12 col-md-6 mt-1 text-center">
              <span className="text-start">
                <label htmlFor="From" className="d-inline-block mt-2  w-100">
                  {t("From")}
                </label>
                <Calendar
                  disabled={
                    !(
                      wizardData.mode === FormMode.NEW ||
                      wizardData.mode === FormMode.EDIT
                    )
                  }
                  id="From"
                  className={`block w-100`}
                  name="From"
                  value={formik.values.From ? new Date(formik.values.From) : ""}
                  onChange={(e) => {
                    if (e.value) {
                      formik.setFieldValue(
                        "From",
                        moment(new Date(e.value)).format("YYYY-MM-DD")
                      );
                      formik.setFieldValue("To", null);
                    } else {
                      formik.setFieldValue("From", null);
                    }
                    formik.setFieldValue("To", null);
                  }}
                  showIcon
                />
                {formik.touched.From && formik.errors.From && (
                  <label className="errorMessage w-100">
                    {formik.errors.From}
                  </label>
                )}
              </span>
            </div>
            <div className="col-12 col-md-6 mt-1 text-center">
              <span className="text-start">
                <label htmlFor="To" className="d-inline-block mt-2  w-100">
                  {t("To")}
                </label>
                <Calendar
                  disabled={
                    !(
                      wizardData.mode === FormMode.NEW ||
                      wizardData.mode === FormMode.EDIT
                    ) || !formik.values.From
                  }
                  minDate={
                    formik.values.From ? new Date(formik.values.From) : null
                  }
                  id="To"
                  showIcon
                  className={`block w-100`}
                  name="To"
                  value={formik.values.To ? new Date(formik.values.To) : ""}
                  onChange={(e) => {
                    formik.setFieldValue(
                      "To",
                      moment(new Date(e.value)).format("YYYY-MM-DD")
                    );
                  }}
                />
                {formik.touched.To && formik.errors.To && (
                  <label className="errorMessage w-100">
                    {formik.errors.To}
                  </label>
                )}
              </span>
            </div>
            {/* <div className="col-12 mt-1 text-center ">
              <span className="text-start">
                <label
                  htmlFor="DescriptionEn"
                  className="d-inline-block mt-4  w-100"
                >
                  {t("Project Brief English")}
                </label>
                <InputTextarea
                  disabled={
                    !(
                      wizardData.mode === FormMode.NEW ||
                      wizardData.mode === FormMode.EDIT
                    )
                  }
                  id="DescriptionEn"
                  name="DescriptionEn"
                  rows={5}
                  cols={10}
                  value={formik.values.DescriptionEn}
                  onChange={formik.handleChange}
                  placeholder={t("Write down your Project Brief")}
                />

                {formik.touched.DescriptionEn &&
                  formik.errors.DescriptionEn && (
                    <label className="errorMessage w-100">
                      {formik.errors.DescriptionEn}
                    </label>
                  )}
              </span>
            </div> */}

            <div className="col-12 mt-1 text-center ">
              <span className="text-start">
                <label
                  htmlFor="DescriptionAr"
                  className="d-inline-block mt-4  w-100"
                >
                  {t("Project Brief Arabic")}
                </label>
                <InputTextarea
                  disabled={
                    !(
                      wizardData.mode === FormMode.NEW ||
                      wizardData.mode === FormMode.EDIT
                    )
                  }
                  id="DescriptionAr"
                  name="DescriptionAr"
                  rows={5}
                  cols={10}
                  value={formik.values.DescriptionAr}
                  onChange={formik.handleChange}
                  placeholder={t("Write down your Project Brief")}
                />

                {formik.touched.DescriptionAr &&
                  formik.errors.DescriptionAr && (
                    <label className="errorMessage w-100">
                      {formik.errors.DescriptionAr}
                    </label>
                  )}
              </span>
            </div>

            <div className="col-12 mt-1  ">
              <label htmlFor="ImageFile" className="d-inline-block mt-4  w-100">
                {t("Project Image")}
              </label>
              <span className="text-start">
                <input
                  disabled={
                    !(
                      wizardData.mode === FormMode.NEW ||
                      wizardData.mode === FormMode.EDIT
                    )
                  }
                  type="file"
                  onChange={(e) => {
                    formik.setFieldValue("ImageFile", e.currentTarget.files[0]);
                  }}
                />
                {formik.touched.ImageFile && formik.errors.ImageFile && (
                  <label className="errorMessage w-100">
                    {formik.errors.ImageFile}
                  </label>
                )}
              </span>
            </div>
            {formik.values.ImageFile && (
              <div className="col-12 mt-1  ">
                <label className="d-inline-block mt-4  w-100">
                  {t("Selected Image")}
                </label>
                <div className="">
                  <Image
                    src={URL.createObjectURL(formik.values.ImageFile)}
                    alt="Image"
                    width="250"
                  />
                </div>
              </div>
            )}
            {formik.values.Image && (
              <div className="col-12 mt-1  ">
                <label className="d-inline-block mt-4  w-100">
                  {t("Current Image")}
                </label>
                <div className="">
                  <Image src={formik.values.Image} alt="Image" width="250" />
                </div>
              </div>
            )}

            <div className="col-12 mt-1 text-center">
              <span className="text-start">
                <label
                  htmlFor="ProjectVoluntterGender"
                  className="d-inline-block mt-2  w-100"
                >
                  {t("Volunteers Gender")}
                </label>

                <Dropdown
                  disabled={
                    !(
                      wizardData.mode === FormMode.NEW ||
                      wizardData.mode === FormMode.EDIT
                    )
                  }
                  optionLabel="name"
                  id="ProjectVoluntterGender"
                  value={
                    genders[
                      genders.findIndex(
                        (obj) =>
                          obj.code === formik.values.ProjectVoluntterGender
                      )
                    ]
                  }
                  options={genders}
                  onChange={(e) => {
                    debugger;
                    formik.setFieldValue(
                      "ProjectVoluntterGender",
                      e.value.code
                    );
                  }}
                  className={`w-100`}
                  placeholder={t("select")}
                />
                {formik.touched.ProjectVoluntterGender &&
                  formik.errors.ProjectVoluntterGender && (
                    <label className="errorMessage w-100">
                      {formik.errors.ProjectVoluntterGender}
                    </label>
                  )}
              </span>
            </div>
            <div className="col-12 mt-1 text-center ">
              <span className="text-start">
                <label
                  htmlFor="VolunteeringLocation"
                  className="d-inline-block mt-2  w-100"
                >
                  {t("Volunteering Location")}
                </label>
                <InputText
                  disabled={
                    !(
                      wizardData.mode === FormMode.NEW ||
                      wizardData.mode === FormMode.EDIT
                    )
                  }
                  id="VolunteeringLocation"
                  name="VolunteeringLocation"
                  className="w-100"
                  value={formik.values.VolunteeringLocation}
                  onChange={formik.handleChange}
                />
                {formik.touched.VolunteeringLocation &&
                  formik.errors.VolunteeringLocation && (
                    <label className="errorMessage w-100">
                      {formik.errors.VolunteeringLocation}
                    </label>
                  )}
              </span>
            </div>
            <div className="col-12 mt-1 text-center">
              <span className="text-start">
                <label htmlFor="MajorId" className="d-inline-block mt-2  w-100">
                  {t("Volunteers Major")}
                </label>
                <Dropdown
                  disabled={
                    !(
                      wizardData.mode === FormMode.NEW ||
                      wizardData.mode === FormMode.EDIT
                    )
                  }
                  optionLabel="name"
                  id="MajorId"
                  value={
                    majors[
                      majors.findIndex(
                        (obj) => obj.code === formik.values.MajorId
                      )
                    ]
                  }
                  options={majors}
                  onChange={(e) => {
                    formik.setFieldValue("MajorId", e.value.code);
                  }}
                  className={`w-100`}
                  placeholder={t("select")}
                />
                {formik.touched.MajorId && formik.errors.MajorId && (
                  <label className="errorMessage w-100">
                    {formik.errors.MajorId}
                  </label>
                )}
              </span>
            </div>
            <div className="col-12  mt-1 text-center">
              <span className="text-start">
                <label className="d-inline-block mt-2  w-100">
                  {t("Volunteers Age")}
                </label>
              </span>
            </div>
            <div className="col-12 col-md-6 mt-1 text-center">
              <span className="text-start">
                <label
                  htmlFor="VoulnteerAgeFrom"
                  className="d-inline-block mt-2  w-100"
                >
                  {t("From")}
                </label>
                <InputNumber
                  showButtons
                  min={1}
                  disabled={
                    !(
                      wizardData.mode === FormMode.NEW ||
                      wizardData.mode === FormMode.EDIT
                    )
                  }
                  id="VoulnteerAgeFrom"
                  name="VoulnteerAgeFrom"
                  className="w-100"
                  value={formik.values.VoulnteerAgeFrom}
                  onValueChange={formik.handleChange}
                />
                {formik.touched.VoulnteerAgeFrom &&
                  formik.errors.VoulnteerAgeFrom && (
                    <label className="errorMessage w-100">
                      {formik.errors.VoulnteerAgeFrom}
                    </label>
                  )}
              </span>
            </div>
            <div className="col-12 col-md-6 mt-1 text-center">
              <span className="text-start">
                <label
                  htmlFor="VoulnteerAgeTo"
                  className="d-inline-block mt-2  w-100"
                >
                  {t("To")}
                </label>
                <InputNumber
                  showButtons
                  min={1}
                  disabled={
                    !(
                      wizardData.mode === FormMode.NEW ||
                      wizardData.mode === FormMode.EDIT
                    )
                  }
                  id="VoulnteerAgeTo"
                  name="VoulnteerAgeTo"
                  className="w-100"
                  value={formik.values.VoulnteerAgeTo}
                  onValueChange={formik.handleChange}
                />
                {formik.touched.VoulnteerAgeTo &&
                  formik.errors.VoulnteerAgeTo && (
                    <label className="errorMessage w-100">
                      {formik.errors.VoulnteerAgeTo}
                    </label>
                  )}
              </span>
            </div>
            <div className="col-12 mt-1 text-center ">
              <span className="text-start">
                <label htmlFor="DressCode" className="d-inline-block mt-4  w-100">
                  {t("Dress Code")}
                </label>
                <InputText
                  id="DressCode"
                  name="DressCode"
                  className="w-100"
                  value={formik.values.DressCode}
                  onChange={formik.handleChange}
                  disabled={
                    !(
                      wizardData.mode === FormMode.NEW ||
                      wizardData.mode === FormMode.EDIT
                    )
                  }
                />
                {formik.touched.DressCode && formik.errors.DressCode && (
                  <label className="errorMessage w-100">
                    {formik.errors.DressCode}
                  </label>
                )}
              </span>
            </div>
            <div className="col-12 mt-1 text-center">
              <span className="text-start">
                <label
                  htmlFor="AllowanceProvided"
                  className="d-inline-block mt-2  w-100"
                >
                  {t("Allowance Provided")}
                </label>

                <Dropdown
                  disabled={
                    !(
                      wizardData.mode === FormMode.NEW ||
                      wizardData.mode === FormMode.EDIT
                    )
                  }
                  optionLabel="name"
                  id="AllowanceProvided"
                  value={
                    boolOptions[
                      boolOptions.findIndex(
                        (obj) =>
                          obj.code === formik.values.AllowanceProvided
                      )
                    ]
                  }
                  options={boolOptions}
                  onChange={(e) => {
                    debugger;
                    formik.setFieldValue(
                      "AllowanceProvided",
                      e.value.code
                    );
                  }}
                  className={`w-100`}
                  placeholder={t("select")}
                />
                {formik.touched.AllowanceProvided &&
                  formik.errors.AllowanceProvided && (
                    <label className="errorMessage w-100">
                      {formik.errors.AllowanceProvided}
                    </label>
                  )}
              </span>
            </div>
            <div className="col-12 mt-1 text-center">
              <span className="text-start">
                <label
                  htmlFor="TransportationProvided"
                  className="d-inline-block mt-2  w-100"
                >
                  {t("Transportation Provided")}
                </label>

                <Dropdown
                  disabled={
                    !(
                      wizardData.mode === FormMode.NEW ||
                      wizardData.mode === FormMode.EDIT
                    )
                  }
                  optionLabel="name"
                  id="TransportationProvided"
                  value={
                    boolOptions[
                      boolOptions.findIndex(
                        (obj) =>
                          obj.code === formik.values.TransportationProvided
                      )
                    ]
                  }
                  options={boolOptions}
                  onChange={(e) => {
                    debugger;
                    formik.setFieldValue(
                      "TransportationProvided",
                      e.value.code
                    );
                  }}
                  className={`w-100`}
                  placeholder={t("select")}
                />
                {formik.touched.TransportationProvided &&
                  formik.errors.TransportationProvided && (
                    <label className="errorMessage w-100">
                      {formik.errors.TransportationProvided}
                    </label>
                  )}
              </span>
            </div>

            <div className="col-12 mt-1 text-center">
              <span className="text-start">
                <label
                  htmlFor="ShowSpecialRequirment"
                  className="d-inline-block mt-2  w-100"
                >
                  {t("Show Special Requirement")}
                </label>

                <Dropdown
                  disabled={
                    !(
                      wizardData.mode === FormMode.NEW ||
                      wizardData.mode === FormMode.EDIT
                    )
                  }
                  optionLabel="name"
                  id="ShowSpecialRequirment"
                  value={
                    boolOptions[
                      boolOptions.findIndex(
                        (obj) =>
                          obj.code === formik.values.ShowSpecialRequirment
                      )
                    ]
                  }
                  options={boolOptions}
                  onChange={(e) => {
                    debugger;
                    formik.setFieldValue(
                      "ShowSpecialRequirment",
                      e.value.code
                    );
                  }}
                  className={`w-100`}
                  placeholder={t("select")}
                />
                {formik.touched.ShowSpecialRequirment &&
                  formik.errors.ShowSpecialRequirment && (
                    <label className="errorMessage w-100">
                      {formik.errors.ShowSpecialRequirment}
                    </label>
                  )}
              </span>
            </div>

            <div className="col-12 mt-4 text-center">
              <Button
                label={wizardData.mode === FormMode.VIEW ? t("Next") :  t("Save & Continue")}
                className="btn btn-blue  w-100"
                type="submit"
              />
            </div>
            <div className="col-12 mt-4 text-center">
               <Button
              label={t("Back To All Projects")}
              className="btn p-button-warning w-100"
              type="button"
              onClick={() => {
                navigate("/Projects/List");
              }}
            />
                   </div>
          
          </div>
        </form>
      </div>
    </>
  );
};

export default Step1;
