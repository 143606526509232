import { React } from "react";
import { Button } from "primereact/button";
import { AuthService } from "./Services/AuthService";

const News = ({img,title,description,item}) => {

  return (
    <>
            <div>
              <div className="card mx-2 event-card">
                <div className="card-body ">
                  <div>
                    <img
src={item.image}
              className="card-img img-fluid"
                      alt="img"
                    />
                  </div>
                  <div className="small-title  mt-3">            {AuthService.isArabic() ? item.titleAr : item.titleEn}{" "}
</div>

                  <div className="mt-2 grey font-12">
                  {AuthService.isArabic() ? item.descAr : item.descEn}{" "}                  </div>
                </div>
          
              </div>
            </div>  
    </>
  );
};

export default News;
