import { React,useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { InputText } from "primereact/inputtext";
import { useTranslation } from "react-i18next";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Password } from 'primereact/password';
import { useNavigate  } from "react-router-dom";

const NotFound = ({}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
 
  
    // eslint-disable-next-line
  }, []);
 
  return (
    <>
     Not Found
    </>
  );
};

export default NotFound;
