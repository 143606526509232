import { Rating } from "primereact/rating";
import { useTranslation } from "react-i18next";
import { InputText } from "primereact/inputtext";
import { React, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { ProjectService } from "../../../shared/Services/ProjectService";
import moment from "moment";
import { SharedService } from "../../../shared/Services/sharedService";
import { MessageTypeConstant } from "../../../shared/constant/messageTypeConstant";
import { Button } from "primereact/button";

const EvaluationForm = ({ item,setEvaludationItem,fillMasterTable }) => {


  function fillEvaluation() {
    SharedService.ShowLoader();

    ProjectService.GetEvaluationLookups().then((res) => {
      SharedService.HideLoader();

      if (res) {
        setCriteria(res.data.data);
      }
    });
  }

  useEffect(() => {
    fillEvaluation();
  }, []);

  const { t } = useTranslation();

  const [criteria, setCriteria] = useState([
  
  ]);

  const [ratings, setRatings] = useState({});


  const updateRating = (id, rating) => {
    setRatings({ ...ratings, [id]: rating });
  };

  function evaluate() {
    let model = {
      evaluations:ratings,
      id:item?.id
    }
    SharedService.ShowLoader();

    ProjectService.EvaluateVolunteerTask(model).then((res) => {
      if (res) {
        SharedService.HideLoader();

        SharedService.ShowToast(t("Evaluated Successfully"),MessageTypeConstant.Success);
        fillMasterTable();
        setEvaludationItem(undefined);
        
      }
    });
  }

  return (
    <div>
           <div className="col-12 mt-1 text-center mb-4 ">
              <span className="text-start">
                <label htmlFor="assignedTask" className="d-inline-block mt-4  w-100">
                  {t("Task Name")}
                </label>
                <InputText
                  id="assignedTask"
                  name="assignedTask"
                  className="w-100"
                  value={item?.assignedTask}
                  disabled={true}
                />
              
              </span>
            </div>
      {criteria.map((criterion) => (
        <div key={criterion.id}  className="justify-content-center ">
          <div className="text-center my-2">{criterion.name}</div>
          <Rating
            value={ratings[criterion.id]}
            cancel={false}
            onChange={(e) => updateRating(criterion.id, e.value)}
            stars={5}
            className="justify-content-center"
          />

        </div>
      ))}
        <div className="col-12 mt-5">
              <Button
                  label={t("Save Changes")}
                  className="btn btn-blue w-100"
                  type="button"
                  onClick={()=>{
                    evaluate();
                  }}
                />
          </div>
    </div>
  );
};

export default EvaluationForm;
