import { Roles } from "../constant/Roles";
import http from "./httpService";

export const ProjectService = {

  createOrUpdate,
  list,
  getById,
  approve,
  reject,
  apply,
  volunteerList,
  approveRequestAssignProject,
  getApprovedProjectVolunteer,
  assignProjectTasks,
  getVolunteerById,
  getCurrentVolunteer,
  myTasks,
  startProject,
  fillTask,
  completeProject,
  GetProjectStatusCount,
  GetProjectTasks,
  GetProjectVolunteers,
  GetProjectTaskExecution,
  ApproveTaskExecution,
  RejectTaskExecution,
  GetEvaluationLookups,
  EvaluateVolunteerTask,
  ExtendProject,
  VolunteerProjectList,
  ProjectsOptions,
  KpiList,
  GetProjectTasksKpis,
  GetProjectsDropdownOptions,
  RejectRequestAssignProject
};

function list(data){
    return http.post("/api/Project/List", data);
  }

  function volunteerList(data){
    return http.post("/api/Project/VolunteerList", data);
  }

  function KpiList(data){
    return http.post("/api/Project/GetProjectTasksKpis", data);
  }
  

    function ProjectsOptions(){
    return http.post("/api/Project/GetProjectsDropdownOptions");
  }
  function EvaluateVolunteerTask(data){
    return http.post("/api/Project/EvaluateVolunteerTask", data);
  }

  function getById(data){
    return http.get(`/api/Project/GetById?id=${data}`);
  }
  function approve(data){
    return http.post(`/api/Project/Approve?id=${data}`);
  }

  function apply(data,shiftType){
    return http.post(`/api/Project/RequestAssignProject?projectId=${data}&shiftType=${shiftType}`);
  }

  function reject(data,reason){
    return http.post(`/api/Project/Reject?id=${data}&rejectionReason=${reason}`);
  }

  function approveRequestAssignProject(data){
    return http.post(`/api/Project/ApproveRequestAssignProject?Id=${data}`);
  }

  function RejectRequestAssignProject(data){
    return http.post(`/api/Project/RejectRequestAssignProject?Id=${data}`);
  }

  function getApprovedProjectVolunteer(searchkey,id){
    return http.post(`/api/Project/GetApprovedProjectVolunteer?searchkey=${searchkey}&projectId=${id}`);
  }

  function startProject(id){
    return http.post(`/api/Project/StartProject?Id=${id}`);
  }

  function completeProject(id){
    return http.post(`/api/Project/CompleteProject?Id=${id}`);
  }

  function assignProjectTasks(data){
    return http.post(`/api/Project/AssignProjectTasks`,data);
  }
  function getVolunteerById(data){
    return http.get(`/api/Project/GetVolunteerById?id=${data}`);
  }
  function getCurrentVolunteer(){
    return http.get(`/api/Project/GetCurrentVolunteer`);
  }
  
  function myTasks(data){
    return http.get(`/api/ProjectTask/MyTasks`);
  }
  function GetProjectStatusCount(data){
    return http.get(`/api/Project/GetProjectStatusCount`);
  }

  function fillTask(data){
      return http.postForm("/api/ProjectTask/FillTask", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        }} );
    }

    function GetProjectVolunteers(data){
      return http.get(`/api/Project/GetProjectVolunteers?projectId=${data}`);
    }

    function GetProjectTasks(data){
      return http.get(`/api/Project/GetProjectTasks?projectId=${data}`);
    }
    function GetProjectTaskExecution(data,volunteerId){
      return http.get(`/api/Project/GetProjectTaskExecution?taskId=${data}&volunteerId=${volunteerId}`);
    }

    function GetEvaluationLookups(){
      return http.get(`/api/Project/GetEvaluationLookups`);
    }

    function ApproveTaskExecution(id){
      return http.post(`/api/Project/ApproveTaskExecution?Id=${id}`);
    }
    function RejectTaskExecution(id){
      return http.post(`/api/Project/RejectTaskExecution?Id=${id}`);
    }
    function ExtendProject(item){
      return http.post(`/api/Project/ExtendProject`,item);
    }

    function VolunteerProjectList(data){
      return http.post("/api/Project/VolunteerProjectList", data);
    }

    function GetProjectTasksKpis(data){
      return http.post(`/api/Project/GetProjectTasksKpis`,data);
    }

    function GetProjectsDropdownOptions(){
      return http.get(`/api/Project/GetProjectsDropdownOptions`);
    }

function createOrUpdate(data){
data.ProjectTasks = JSON.stringify(data.ProjectTasks);
  return http.postForm("/api/Project/CreateOrUpdate", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    }} );
}


