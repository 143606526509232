import { React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProgressBar } from "primereact/progressbar";
import { Rating } from "primereact/rating";
import { ProjectService } from "../../../shared/Services/ProjectService";
import moment from "moment";
import { SharedService } from "../../../shared/Services/sharedService";
import { MessageTypeConstant } from "../../../shared/constant/messageTypeConstant";

const TaskExecutionTable = ({ item,fillMasterTable }) => {
  const { t } = useTranslation();
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);


  function fillTable() {
    SharedService.ShowLoader();

    ProjectService.GetProjectTaskExecution(item.id,item.volunteerId).then((res) => {
      SharedService.HideLoader();

      if (res) {
        setRows(res.data.data);
      }
    });
  }

  function approve(id) {
    SharedService.ShowLoader();

    ProjectService.ApproveTaskExecution(id).then((res) => {
      SharedService.HideLoader();

      if (res) {
        SharedService.ShowToast(t("Approved Successfully"),MessageTypeConstant.Success);
        fillTable();
        fillMasterTable();
      }
    });
  }

  function reject(id) {
    SharedService.ShowLoader();

    ProjectService.RejectTaskExecution(id).then((res) => {
      SharedService.HideLoader();

      if (res) {
        SharedService.ShowToast(t("Rejected Successfully"),MessageTypeConstant.Success);
        fillTable();
        fillMasterTable();
      }
    });
  }
  function initializeColumns() {
    setColumns([
      { field: "id", header: t("#ID") },
      { field: "taskName", header: t("Task Name") },
      { field: "volunteerName", header: t("Volunteer Name") },
      { field: "fromDate", header: t("From"),body: fromDateBodyTemplate },
      

      { field: "toDate", header: t("To"),body: toDateBodyTemplate },
      { field: "totalHour", header: t("Total Hour") },
      { field: "statusText", header: t("Status") },
      { field: "kpiCompleted", header: t("kpi Completed") },
      { field: "location", header: t("Location"), body: hrefTemplate },
      {
        field: "actions",
        header: t("Actions"),
        body: actionsBodyTemplate,
      },
    ]);
  }
  const actionsBodyTemplate = (rowData) => {
    return (
      <>
        {rowData.status === 1 && (
          <>
          <span className="actionList">
            <i role="button" className="fa fa-check" aria-hidden="true"  onClick={() => {
               approve(rowData.id)
              }}></i>
          </span>

        <span className="actionList">
          <i role="button" className="fa fa-times" aria-hidden="true"  onClick={() => {
                            reject(rowData.id)

              }}></i>
        </span>
        </>
                )}

      </>
    );
  };
  const fromDateBodyTemplate = (rowData) => {
    return (
      <>
   { moment(new Date( rowData.fromDate)).format("YYYY-MM-DD HH:mm")}

      </>
    );
  };
  const toDateBodyTemplate = (rowData) => {
    return (
      <>
   { moment(new Date( rowData.toDate)).format("YYYY-MM-DD HH:mm")}

      </>
    );
  };
  const hrefTemplate = (rowData) => {
    return (rowData.location && 
   <a href={rowData.location} target="_blank" > location </a>
    );
  };

  const dynamicColumns = columns.map((col) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        body={col.body}
        hidden={col.hidden}
      />
    );
  });

  useEffect(() => {
    initializeColumns();
    fillTable();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="row mt-5 mb-5 table-div">
      <DataTable
        rows={10}
        paginator
        paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
        value={rows}
        responsiveLayout="scroll"
        stripedRows
        className="centeredColumnContent"
        emptyMessage={t("No Records")}
      >
        {dynamicColumns}
      </DataTable>
    </div>
  );
};

export default TaskExecutionTable;
