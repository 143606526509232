import { React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { Steps } from "primereact/steps";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useFormik } from "formik";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { ProgressBar } from "primereact/progressbar";
import { TabMenu } from "primereact/tabmenu";
import TasksTable from "./TasksTable";
import VolunteersTable from "./VolunteersTable";
import { useNavigate, useOutletContext,useParams } from "react-router-dom";

const ProjectOverview = ({}) => {
  const { t } = useTranslation();

  const { isAdmin, isVolunteer, isRequester } = useOutletContext();
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const { id } = useParams();

  const items = [{ label: t("Volunteers") }, { label: t("Tasks") }];

  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="container mt-3 mb-5 ">
        <div className="row">
          <div className="col-12 text-uppercase grey font-12">
            {t("overview")}
          </div>
          <div className="col-12  title">
            {t("Project")} {1}
          </div>
          <div className="col-12 col-md-3  title">
            <Button
              label={t("Back To All Projects")}
              className="btn btn-blue w-75"
              type="button"
              onClick={() => {
                navigate("/Projects/List");
              }}
            />
          </div>
        </div>
        <div className="row mt-4 text-center">
          <TabMenu
            model={items}
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
            className="centered-tab"
          />
        </div>
        <div className="p-4">
      {activeIndex ===0 && (
        <VolunteersTable id={id}/>
      )}
            {activeIndex ===1 && (
        <TasksTable id={id}/>
      )}
      </div>
      </div>
    </>
  );
};

export default ProjectOverview;
