import { Roles } from "../constant/Roles";
import http from "./httpService";

export const AuthService = {
  getTokenFromLocalStorage,
  login,
  getName,
  getImage,
  getUserRole,
  getUserRoleString,
  getRating,
  isLoggedIn,
  isArabic,
  logOut,
  register,
  ForgetPassword,
  ResetPassword
};

function getTokenFromLocalStorage()  {
  return localStorage.getItem("Auth");
}

function login(data){
  return http.post("/api/Account/LogIn",data );
}

function ForgetPassword(data){
  return http.post(`/api/Account/ForgetPassword?email=${data}` );
}


function ResetPassword(data){
  return http.post(`/api/Account/ResetPassword`,data );
}


function getName(data){
  return localStorage.getItem("name");
}

function getRating(data){
  return localStorage.getItem("rating");
}

function getImage(data){
  return localStorage.getItem("profileImage");
}

function getUserRole(data){
  return localStorage.getItem("userRole");
}

function getUserRoleString(data){
  let role =  +localStorage.getItem("userRole");
  if(role === Roles.ADMIN)
  return "ADMIN";

  if(role === Roles.REQUESTER)
  return "REQUESTER";

  if(role === Roles.VOLUNTEER)
  return "VOLUNTEER";

}

function isLoggedIn()  {
  return !(localStorage.getItem("Auth") === null);
}

function isArabic()  {
  return localStorage.getItem("lang") === "ar";
}

function logOut(){
  localStorage.removeItem("rating");
  localStorage.removeItem("Auth");
  localStorage.removeItem("userRole");
  localStorage.removeItem("name");
  localStorage.removeItem("profileImage");
}


function register(data){
    return http.postForm("/api/Account/Register", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      }} );
  }
