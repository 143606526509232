import { React } from "react";
import { AuthService } from "./Services/AuthService";

const HomeTopCard = ({item}) => {

  return (
    <>
             <div className="col-12  title text-center mt-2">
             {AuthService.isArabic() ? item.titleAr : item.titleEn}{" "}            </div>
            <div className="col-12  col-md-6 font-20 grey text-center mt-2">
            {AuthService.isArabic() ? item.descAr : item.descEn}{" "}  
            </div>
    </>
  );
};

export default HomeTopCard;
