import { React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import { SharedService } from "../../shared/Services/sharedService";
import * as Yup from "yup";
import moment from "moment";
import { ReportService } from "../../shared/Services/ReportService";
import { HomeService } from "../../shared/Services/HomeService";
import { AuthService } from "../../shared/Services/AuthService";
const VolunteersDataReport = ({}) => {
  const { t } = useTranslation();

  const [departments, setDepartments] = useState([]);
  const [genders, setGenders] = useState([]);
  const [educationStatuses, setEducationStatuses] = useState([]);
  const [booleanOptions, setBooleanOptions] = useState([]);
  const [fieldOfStudies, setFieldOfStudies] = useState([]);
  const [specialSkills, setSpecialSkills] = useState([]);
  const [certificateTypes, setCertificateTypes] = useState([]);

  function fillEducationStatus() {
    HomeService.getEducationStatuses().then((res) => {
      if (res.data.errorCode === 0) {
        let options = res.data.data.map((x) => {
          return {
            name: AuthService.isArabic() ? x.nameAr : x.nameEn,
            code: x.id,
          };
        });
        setEducationStatuses(options);
      }
    });
  }
  function fillFieldofStudies() {
    HomeService.GetFieldOfStudies().then((res) => {
      if (res.data.errorCode === 0) {
        let options = res.data.data.map((x) => {
          return {
            name: AuthService.isArabic() ? x.nameAr : x.nameEn,
            code: x.id,
          };
        });
        setFieldOfStudies(options);
      }
    });
  }

  function fillSpecialSkills() {
    HomeService.getSpecialSkills().then((res) => {
      if (res.data.errorCode === 0) {
        let options = res.data.data.map((x) => {
          return {
            name: AuthService.isArabic() ? x.nameAr : x.nameEn,
            code: x.id,
          };
        });
        setSpecialSkills(options);
      }
    });
  }

  function fillGenders() {
    setGenders([
      { name: t("Male"), code: 1 },
      { name: t("Female"), code: 2 },
    ]);
  }
  function fillBooleanOptions() {
    setBooleanOptions([
      { name: t("Yes"), code: true },
      { name: t("No"), code: false },
    ]);
  }

  function fillCertificateTypes() {
    setCertificateTypes([
      { name: t("Bronze"), code: 1 },
      { name: t("RejectSilvered"), code: 2 },
      { name: t("Gold"), code: 3 },
      { name: t("Platinum"), code: 4 },
    ]);
  }

  function fillDepartments() {
    HomeService.getDepartmentLookups().then((res) => {
      if (res.data.errorCode === 0) {
        let options = res.data.data.map((x) => {
          return {
            name: AuthService.isArabic() ? x.nameAr : x.nameEn,
            code: x.id,
          };
        });
        setDepartments(options);
      }
    });
  }

  useEffect(() => {
    fillDepartments();
    fillCertificateTypes();
    fillGenders();
    fillSpecialSkills();
    fillFieldofStudies();
    fillEducationStatus();
    fillBooleanOptions();
    // eslint-disable-next-line
  }, []);

  function ExportData(data) {
    SharedService.ShowLoader();
    ReportService.DownloadVolunteerReport(data).then((response) => {
      SharedService.HideLoader();
      if (response && response.status === 200 && response.data) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        const formattedDate = moment().format("YYYY-MM-DD HH:mm");
        const filename = `VolunteerReport ${formattedDate}`;
        link.href = url;
        link.setAttribute("download", `${filename}.xlsx`);
        document.body.appendChild(link);
        link.click();

        link.remove();
      }
    });
  }

  const formik = useFormik({
    initialValues: {
      departmentId: null,
      gender: null,
      minAgeInYear: null,
      maxAgeInYear: null,
      isWillGovernarates: null,
      isWillWeekends: null,
      educationStatusId: null,
      fieldOfStudyId: null,
      specialSkillsId: null,
      certificateType: null,
    },
    validationSchema: Yup.object().shape({
      departmentId: Yup.number().nullable(),
      gender: Yup.string().nullable(),
      minAgeInYear: Yup.number().nullable(),
      maxAgeInYear: Yup.number().nullable(),
      isWillGovernarates: Yup.boolean().nullable(),
      isWillWeekends: Yup.boolean().nullable(),
      educationStatusId: Yup.number().nullable(),
      fieldOfStudyId: Yup.number().nullable(),
      specialSkillsId: Yup.number().nullable(),
      certificateType: Yup.string().nullable(),
    }),
    enableReinitialize: true,
    onSubmit: (data) => {
      ExportData(data);
    },
  });

  return (
    <>
      <div className="container mt-3 mb-5 ">
        <div className="row">
          <div className="col-12  title">{t("Volunteers data")}</div>
        </div>

        <div className="row mt-4">
          <form onSubmit={formik.handleSubmit} className="p-fluid">
            <div className="row">
              <div className="col-6 col-md-3 ">
                <label htmlFor="departmentId" className="d-inline-block mt-2  w-100">
                  {t("Department")}
                </label>
                <Dropdown
                  showClear
                  optionLabel="name"
                  id="departmentId"
                  options={departments}
                  value={
                    departments[
                      departments.findIndex(
                        (obj) => +obj.code === formik.values.departmentId
                      )
                    ]
                  }
                  onChange={(e) => {
                    formik.setFieldValue("departmentId", e?.value?.code);
                  }}
                  className={`w-100`}
                  placeholder={t("select")}
                />
                {formik.touched.departmentId && formik.errors.departmentId && (
                  <label className="errorMessage w-100">
                    {formik.errors.departmentId}
                  </label>
                )}
              </div>
              <div className="col-6 col-md-3 ">
                <label htmlFor="gender" className="d-inline-block mt-2  w-100">
                  {t("Gender")}
                </label>
                <Dropdown
                  showClear
                  optionLabel="name"
                  id="gender"
                  options={genders}
                  value={
                    genders[
                      genders.findIndex(
                        (obj) => +obj.code === formik.values.gender
                      )
                    ]
                  }
                  onChange={(e) => {
                    formik.setFieldValue("gender", e?.value?.code);
                  }}
                  className={`w-100`}
                  placeholder={t("select")}
                />
              
              </div>{" "}
              <div className="col-6 col-md-3 ">
                <label htmlFor="isWillGovernarates" className="d-inline-block mt-2  w-100">
                  {t(
                    "Will Governarates"
                  )}
                </label>
                <Dropdown
                  showClear
                  optionLabel="name"
                  id="isWillGovernarates"
                  options={booleanOptions}
                  value={
                    booleanOptions[
                      booleanOptions.findIndex(
                        (obj) => obj.code === formik.values.isWillGovernarates
                      )
                    ]
                  }
                  onChange={(e) => {
                    formik.setFieldValue("isWillGovernarates", e?.value?.code);
                  }}
                  className={`w-100`}
                  placeholder={t("select")}
                />
              </div>
              <div className="col-6 col-md-3 ">
                <label
                  htmlFor="isWillWeekends"
                  className="d-inline-block mt-2  w-100"
                >
                  {t(
                    "Will Weekends"
                  )}
                </label>
                <Dropdown
                  showClear
                  optionLabel="name"
                  id="isWillWeekends"
                  options={booleanOptions}
                  value={
                    booleanOptions[
                      booleanOptions.findIndex(
                        (obj) => obj.code === formik.values.isWillWeekends
                      )
                    ]
                  }
                  onChange={(e) => {
                    formik.setFieldValue("isWillWeekends", e?.value?.code);
                  }}
                  className={`w-100`}
                  placeholder={t("select")}
                />
              </div>
              <div className="col-6 col-md-3 ">
                <label
                  htmlFor="educationStatusId"
                  className="d-inline-block mt-2  w-100"
                >
                  {t("Education Status")}
                </label>
                <Dropdown
                  showClear
                  optionLabel="name"
                  id="educationStatusId"
                  options={educationStatuses}
                  value={
                    educationStatuses[
                      educationStatuses.findIndex(
                        (obj) => +obj.code === formik.values.educationStatusId
                      )
                    ]
                  }
                  onChange={(e) => {
                    formik.setFieldValue("educationStatusId", e?.value?.code);
                  }}
                  className={`w-100`}
                  placeholder={t("select")}
                />
           
              </div>{" "}
              <div className="col-6 col-md-3 ">
                <label
                  htmlFor="fieldOfStudyId"
                  className="d-inline-block mt-2  w-100"
                >
                  {t("Field Of Study")}
                </label>
                <Dropdown
                  showClear
                  optionLabel="name"
                  id="fieldOfStudyId"
                  options={fieldOfStudies}
                  value={
                    fieldOfStudies[
                      fieldOfStudies.findIndex(
                        (obj) => +obj.code === formik.values.fieldOfStudyId
                      )
                    ]
                  }
                  onChange={(e) => {
                    formik.setFieldValue("fieldOfStudyId", e?.value?.code);
                  }}
                  className={`w-100`}
                  placeholder={t("select")}
                />
              </div>
              <div className="col-6 col-md-3 ">
                <label
                  htmlFor="specialSkillsId"
                  className="d-inline-block mt-2  w-100"
                >
                  {t("Special Skills")}
                </label>
                <Dropdown
                  showClear
                  optionLabel="name"
                  id="specialSkillsId"
                  options={specialSkills}
                  value={
                    specialSkills[
                      specialSkills.findIndex(
                        (obj) => +obj.code === formik.values.specialSkillsId
                      )
                    ]
                  }
                  onChange={(e) => {
                    formik.setFieldValue("specialSkillsId", e?.value?.code);
                  }}
                  className={`w-100`}
                  placeholder={t("select")}
                />
              </div>
              <div className="col-6 col-md-3 ">
                <label
                  htmlFor="certificateType"
                  className="d-inline-block mt-2  w-100"
                >
                  {t("Certificate Type")}
                </label>
                <Dropdown
                  showClear
                  optionLabel="name"
                  id="certificateType"
                  options={certificateTypes}
                  value={
                    certificateTypes[
                      certificateTypes.findIndex(
                        (obj) => +obj.code === formik.values.certificateType
                      )
                    ]
                  }
                  onChange={(e) => {
                    formik.setFieldValue("certificateType", e?.value?.code);
                  }}
                  className={`w-100`}
                  placeholder={t("select")}
                />
              </div>
              <div className="col-6 col-md-3 ">
                <label className="d-inline-block mt-2  w-100"></label>
                <Button
                  label={t("Export")}
                  className="btn btn-blue  w-100 mt-1"
                  type="submit"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default VolunteersDataReport;
