import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "./i18n/config";
import "./assests/main.css";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { LayoutContext } from "./Contexts/LayoutContext";
import { Slide, ToastContainer } from "react-toastify";
import Header from "./pages/Layout/Header";
import Home from "./pages/Home/Home";
import EditProfile from "./pages/RegiterPage/EditProfile";
import RegisterPage from "./pages/RegiterPage/RegisterPage";
import LoginPage from "./pages/LoginPage/LoginPage";
import Dashboard from "./pages/Dashboard/Dashboard";
import NotFound from "./pages/NotFound/NotFound";
import SideBar from "./shared/SideBar";
import TasksList from "./pages/Tasks/TasksList";
import FillTask from "./pages/Tasks/FillTask";
import AvailableProjects from "./pages/Projects/AvailableProjects";
import ProjectDetail from "./pages/Projects/ProjectDetail";
import Certificate from "./pages/Certificate/Certificate";
import ProjectsList from "./pages/Projects/ProjectsList";
import AddProject from "./pages/Projects/AddProject/AddProject";
import ProjectOverview from "./pages/Projects/ProjectOverview/ProjectOverview";
import KPIsList from "./pages/KPIs/KPIsList";
import VolunteersList from "./pages/Volunteers/VolunteersList";
import "react-toastify/dist/ReactToastify.css";
import NotAuthorized from "./pages/NotAuthorized/NotAuthorized";
import { AuthService } from "./shared/Services/AuthService";
import { Roles } from "./shared/constant/Roles";
import UsersList from "./pages/Users/UsersList";
import AddUser from "./pages/Users/AddUser";
import SlidersList from "./pages/CMS/SlidersList";
import AddSlider from "./pages/CMS/AddSlider";
import AppConsts from "./shared/constant/appConsts";
import ForgotPasswordPage from "./pages/ForgotPassword/ForgotPasswordPage";
import AddNews from "./pages/CMS/AddNews";
import NewsList from "./pages/CMS/NewsList";
import WhatsNewList from "./pages/CMS/WhatsNewList";
import AddWhatsNew from "./pages/CMS/AddWhatsNew";
import Reports from "./pages/Reports/Reports";
const App = () => {
  const [showSideBar, setShowSideBar] = useState(true);

  const [isAdmin, setisAdmin] = useState(
    +AuthService.getUserRole() === Roles.ADMIN
  );
  const [isRequester, setisRequester] = useState(
    +AuthService.getUserRole() === Roles.REQUESTER
  );
  const [isVolunteer, setisVolunteer] = useState(
    +AuthService.getUserRole() === Roles.VOLUNTEER
  );
  const [isLoggedIn, setisLoggedIn] = useState(AuthService.isLoggedIn());

  useEffect(() => {

    let dir = localStorage.getItem("lang") === "ar" ? "rtl" : "ltr";
    let lang = localStorage.getItem("lang") === "ar" ? "ar" : "en";
    document.getElementsByTagName("html")[0].setAttribute("dir", dir);
    document.getElementsByTagName("html")[0].setAttribute("lang", lang);
    if (lang === "ar")
      document.getElementsByTagName("html")[0].classList.add("arabic");
  }, []);

  useEffect(() => {
    setisAdmin(+AuthService.getUserRole() === Roles.ADMIN);
    setisRequester(+AuthService.getUserRole() === Roles.REQUESTER);
    setisVolunteer(+AuthService.getUserRole() === Roles.VOLUNTEER);
    setisLoggedIn(AuthService.isLoggedIn());
  }, [window.location.pathname]);

  return (
    // basename="/KHCFFront"
    <BrowserRouter  basename={AppConsts.indexUrl} >
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <LayoutContext.Provider
        value={{
          setShowSideBar,
          showSideBar,
        }}
      >
              <div className="master-loader" id="masterLoader"></div>

        <Header />

        <div className="main-content main-nav">
          <Routes>
            <Route path="/Home" element={<Home />} />

            <Route
              path="/Register"
              element={
                !isLoggedIn ? <RegisterPage /> : <Navigate to="/Dashboard" />
              }
            />


<Route
              path="/EditProfile"
              element={
                isVolunteer ? <EditProfile /> : <Navigate to="/Dashboard" />
              }
            />

            <Route
              path="/Login"
              element={
                !isLoggedIn ? <LoginPage /> : <Navigate to="/Dashboard" />
              }
            />
                        <Route
              path="/ForgotPassword"
              element={
                !isLoggedIn ? <ForgotPasswordPage /> : <Navigate to="/Dashboard" />
              }
            />
            <Route
              path="/Dashboard"
              element={isLoggedIn ? <SideBar /> : <Navigate to="/Login" />}
            >
              <Route index element={<Dashboard />} />
            </Route>
            <Route
              path="/Certificate"
              element={
                isVolunteer ? <SideBar /> : <Navigate to="/NotAuthorized" />
              }
            >
              <Route index element={<Certificate />} />
            </Route>
            <Route
              path="/Tasks"
              element={
                isVolunteer ? <SideBar /> : <Navigate to="/NotAuthorized" />
              }
            >
              <Route index element={<TasksList />} />
              <Route path="Fill/:project/:id" element={<FillTask />} />
            </Route>
            <Route
              path="/KPIs"
              element={isAdmin ? <SideBar /> : <Navigate to="/NotAuthorized" />}
            >
              <Route index element={<KPIsList />} />
            </Route>
            <Route
              path="/Volunteers"
              element={isAdmin ? <SideBar /> : <Navigate to="/NotAuthorized" />}
            >
              <Route index element={<VolunteersList />} />
            </Route>

            <Route
              path="/Projects"
              element={isLoggedIn ? <SideBar /> : <Navigate to="/Login" />}
            >
              <Route index element={<AvailableProjects />} />
              <Route
                path="Details/:id"
                element={
                  isLoggedIn ? <ProjectDetail /> : <Navigate to="/Login" />
                }
              />
              <Route
                path="List"
                element={
                  isAdmin || isRequester ? (
                    <ProjectsList />
                  ) : (
                    <Navigate to="/NotAuthorized" />
                  )
                }
              />
              <Route
                path="new"
                element={
                  isAdmin || isRequester ? (
                    <AddProject />
                  ) : (
                    <Navigate to="/NotAuthorized" />
                  )
                }
              />
              <Route
                path="Overview/:id"
                element={
                  isAdmin || isRequester ? (
                    <ProjectOverview />
                  ) : (
                    <Navigate to="/NotAuthorized" />
                  )
                }
              />
            </Route>
            <Route
              path="/Users"
              element={isAdmin ? <SideBar /> : <Navigate to="/NotAuthorized" />}
            >
              <Route index element={<UsersList />} />
              <Route
                path="new"
                element={
                  isAdmin ? <AddUser /> : <Navigate to="/NotAuthorized" />
                }
              />
            </Route>
            <Route
              path="/CMS"
              element={isAdmin ? <SideBar /> : <Navigate to="/NotAuthorized" />}
            >
              <Route
                path="slider"
                element={
                  isAdmin ? <SlidersList /> : <Navigate to="/NotAuthorized" />
                }
              />
              <Route
                path="slider/new"
                element={
                  isAdmin ? <AddSlider /> : <Navigate to="/NotAuthorized" />
                }
              />
                <Route
                path="News"
                element={
                  isAdmin ? <NewsList /> : <Navigate to="/NotAuthorized" />
                }
              />
              <Route
                path="News/new"
                element={
                  isAdmin ? <AddNews /> : <Navigate to="/NotAuthorized" />
                }
              />
                  <Route
                path="WhatsNew"
                element={
                  isAdmin ? <WhatsNewList /> : <Navigate to="/NotAuthorized" />
                }
              />
              <Route
                path="WhatsNew/new"
                element={
                  isAdmin ? <AddWhatsNew /> : <Navigate to="/NotAuthorized" />
                }
              />
            </Route>
            <Route
              path="/Reports"
              element={isAdmin ? <SideBar /> : <Navigate to="/NotAuthorized" />}
            >
              <Route index element={<Reports />} />
            </Route>
            <Route path="/" element={<Home />} />
            <Route path="/NotAuthorized" element={<NotAuthorized />} />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </LayoutContext.Provider>
    </BrowserRouter>
  );
};

export default App;
