import { React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import AvailableProject from "../../shared/AvailableProject";
import AppConsts from "../../shared/constant/appConsts";
import { ProjectStatusEnum } from "../../shared/constant/ProjectStatusEnum";
import { ProjectService } from "../../shared/Services/ProjectService";
import { SharedService } from "../../shared/Services/sharedService";
const AvailableProjects = ({}) => {
  const { t } = useTranslation();
  const { isAdmin, isVolunteer, isRequester } = useOutletContext();
  const navigate = useNavigate();
  const [totalRecords, setTotalRecords] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);

  const [rows, setRows] = useState([]);

  useEffect(() => {
    fillProjects();
    // eslint-disable-next-line
  }, []);

  function fillProjects(index) {
    let request = {
      status: ProjectStatusEnum.PendingVolunteerAssigment,
      pageNumber: index ? index : 1,
      pageSize: 4,
    };

    ProjectService.VolunteerProjectList(request).then((res) => {
      SharedService.ShowLoader();

      if (res.data.errorCode === 0) {
        SharedService.HideLoader();

        setTotalRecords(res.data.data.totalFilteredRecords);
        setRows(res.data.data.data);
      }
    });
  }

  return (
    <>
      <div className="container mt-3">
        <div className="row">
          <div className="col-12 text-uppercase grey font-12">
            {t("overview")}
          </div>
          <div className="col-12 title">{t("Available Project")}</div>
        </div>
        <div className="row">
          {rows.map((project, i) => {
            return <AvailableProject key={project.id} project={project} />;
          })}
        </div>
        <div className="row">
          <div className="col-12">
            <nav aria-label="Page navigation example ">
              <ul className="pagination  justify-content-center">
                {Array.from({ length: Math.ceil(totalRecords / 4) }, (_, i) => (
                  <li onClick={()=>{
                    setActiveIndex(i);
                    fillProjects(i+1);
                  }}
                    key={i}
                    className={`page-item pointer ${activeIndex === i ? "active" : " "}`}
                  >
                    <span className={"page-link "}>{i + 1}</span>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default AvailableProjects;
