import { React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Carousel } from "react-responsive-carousel";
import Event from "../../shared/Event";
import HomeCard from "../../shared/HomeCard";
import Footer from "../../shared/Footer";
import Slider from "react-slick";
import News from "../../shared/News";
import { HomeService } from "../../shared/Services/HomeService";
import { SharedService } from "../../shared/Services/sharedService";
import HomeTopCard from "../../shared/HomeTopCard";

const Home = () => {
  const [eventsItems, setEvents] = useState([]);
  const [sliders, setSliders] = useState([]);
  const [news, setNews] = useState([]);
  const [whatsNew, setWhatsNew] = useState([]);

  const { t } = useTranslation();
  useEffect(() => {
    getEvents();
    getSliders();
    GetNewsItem();
    GetWhatsNews();
  }, []);

  function getEvents(){
    SharedService.ShowLoader();

    HomeService.getEvents().then((res) => {
      SharedService.HideLoader();

      if (res.data.errorCode === 0) {
        setEvents(res.data.data.data);
      }
    });
  }

  function getSliders(){
    SharedService.ShowLoader();

    HomeService.getSlider().then((res) => {
      SharedService.HideLoader();

      if (res.data.errorCode === 0) {
        setSliders(res.data.data);
      }
    });
  }
  function GetNewsItem(){
    SharedService.ShowLoader();

    HomeService.GetNewsItem().then((res) => {
      SharedService.HideLoader();

      if (res.data.errorCode === 0) {
        setNews(res.data.data);
      }
    });
  }

  function GetWhatsNews(){
    SharedService.ShowLoader();

    HomeService.GetWhatsNews().then((res) => {
      SharedService.HideLoader();

      if (res.data.errorCode === 0) {
        setWhatsNew(res.data.data);
      }
    });
  }
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
  };
  const settings2 = {
    speed: 900,
    dots: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    infinite: false,
    arrows: true,
    className: "events-slider",
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  const settings3 = {
    speed: 900,
    dots: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    infinite: false,
    arrows: true,

    className: "events-slider",
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };
  return (
    <>
      <section className="image-slider-section">
        <Slider {...settings}>

        {sliders.map((slider, i) => {
              return    <div className="" key={slider.titleEn}>
              <picture>
                <source
                  media="(max-width: 600px)"
                  srcSet={slider.imageMobile}
                />
                <img src={slider.imageFull} />
              </picture>
            </div>;
            })}
       
       
        </Slider>
      </section>

      <section className="mb-5 mt-5">
        <div className="container">
          <div className="row  justify-content-center">
            {/* <div className="col-12  font-16 purple text-center mt-2">
              lorem dssdd as
            </div> */}
            {whatsNew.map((item, i) => {
  if (item.isTop) {
    return <HomeTopCard key={item.titleEn + i} item={item} />;
  }
  return null;
})}
          </div>
          <div className="row mt-3">
          {whatsNew.map((item, i) => {
  if (!item.isTop) {
    return <HomeCard key={item.titleEn+ i} item={item} />;
  }
  return null;
})}
          </div>
        </div>
      </section>
      <section className="mb-5">
        <div className="container">
          <div className="row justify-content-center ">
            <div className="col-6 text-center title  mt-5 mb-3 ">
              <h2>{t("Volunteering Opportunities")}</h2>
            </div>
          </div>
          <Slider {...settings2}>
            {eventsItems.map((event, i) => {
              return <Event key={event.nameAr}  event={event}/>;
            })}
          </Slider>
        </div>
      </section>
      <section className="mb-5">
        <div className="container">
          <div className="row justify-content-center ">
            <div className="col-6 text-center title  mt-5 mb-3 ">
              <h2>{t("News & Update")}</h2>
            </div>
          </div>
          <Slider {...settings3}>
          {news.map((item, i) => {
              return <News key={item.titleEn+i}  item={item}/>;
            })}
         
          </Slider>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row justify-content-center ">
            <Footer />
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
