let url = "";
let env = process.env.REACT_APP_ENV;
let indexUrl = "/"
switch (env) {
    case 'production':
      	  url = 'https://siwaralhussein.khcf.jo/khfc/';
            indexUrl="/"
        break;
        case 'staging':
      	  url = 'https://www.hashtechsllc.com/khfc/';
            indexUrl="/KHCFFront"
        break;
        default:
            url = 'https://localhost:7150/';
            indexUrl="/"
        break;
}

class AppConsts {
    static baseURL = url;
    static pageSize = 5;
    static indexUrl = indexUrl;


}

export default AppConsts
