import { React } from "react";
import { AuthService } from "./Services/AuthService";


const HomeCard = ({item}) => {

  return (
    <>
               <div className="col-12 col-xl-4 col-md-6 mt-4">
            <div className="small-title  text-center">{AuthService.isArabic() ? item.titleAr : item.titleEn}</div>
            <div className="grey font-16 text-center">
            {AuthService.isArabic() ? item.descAr : item.descEn}
            </div>
          </div>
    </>
  );
};

export default HomeCard;
