import AppConsts from "../constant/appConsts";
import axios from "axios";
import { AuthService } from "./AuthService";
import { SharedService } from "./sharedService";
import {MessageTypeConstant} from "../constant/messageTypeConstant"

const qs = require("qs");

function handleBody(resp) {
  if (resp && resp.errorCode !== null && resp.errorCode !== undefined) {
    if (resp.errorCode === 0) return 1;
    return 0;
  }
  return -1;
}

const http = axios.create({
  baseURL: AppConsts.baseURL,
  timeout: 30000,
  paramsSerializer: function (params) {
    return qs.stringify(params, {
      encode: false,
    });
  },
});



http.interceptors.request.use(
  function (request) {
    if (!!AuthService.getTokenFromLocalStorage()) {
      request.headers["accept-language"] = localStorage.getItem("lang");
      request.headers["X-Content-Type-Options"] = "nosniff";
      request.headers["strict-transport-security"] = "max-age=31536000";
      request.headers["X-Frame-Options"] = "DENY";
      request.headers["X-XSS-Protection"] = "1; mode=block";
      request.headers["Auth"] = localStorage.getItem("Auth");
        request.headers["Access-Control-Allow-Origin"] ="http://localhost:3000";
    }

    return request;
  },
  function (error) {
    return error;
  }
);

http.interceptors.response.use(
  (resp) => {
    let response = null;
    let data = null;
    if (resp) {
      let result = handleBody(resp.data);
      
      if (result === 1) {
        data = {
          data: resp.data,
          status: true,
          errorCode: resp.data.errorCode,
          errorMessage: resp.data.errorMessage,
        };
        response = resp;
        response["body"] = data.data;
        response.data.status = true;
      } else if (result === 0) {
        data = {
          status: false,
          data: resp.data.errorCode,
          errorCode: resp.data.ErrorCode,
          errorMessage: resp.data?.errorMessage,
        };
        response = resp;
        response["body"] = data.data;
        response.data.status = true;
        SharedService.ShowToast(resp.data?.errorMessage,MessageTypeConstant.Error);

      } else {
        data = resp.data;
        response = resp;
        response["body"] = data.data;
      }
    }

    return response;
  },
  (error) => {
    debugger;
    SharedService.HideLoader();
    SharedService.ShowToast(error?.response?.data?.errorMessage,MessageTypeConstant.Error);
    error.response.data.status = false;
    return error.response;
  }
);

export default http;
