import { React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { InputText } from "primereact/inputtext";

import { ProjectService } from "../../shared/Services/ProjectService";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { SharedService } from "../../shared/Services/sharedService";
import { InputTextarea } from "primereact/inputtextarea";
import { Rating } from "primereact/rating";
import { useNavigate  } from "react-router-dom";

import { AuthService } from "../../shared/Services/AuthService";



const ViewVolunteer = ({ id }) => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const [volunteer, setVolunteer] = useState({});
const genders = [
  { name: t("Male"), code: 0 },

  { name: t("Male"), code: 1 },
  { name: t("Female"), code: 2 },
];
  useEffect(() => {
    SharedService.ShowLoader();

    if(id){
     ProjectService.getVolunteerById(id).then((res) => {
      SharedService.HideLoader();

      if (res.data.errorCode === 0) {
        setVolunteer(res.data.data);
      }
    }); 
    }else {
    ProjectService.getCurrentVolunteer(id).then((res) => {
      SharedService.HideLoader();

      if (res.data.errorCode === 0) {
        setVolunteer(res.data.data);
      }
    }); // eslint-disable-next-
  }
  }, []);

  return (
    <>
    {volunteer && (  <div className="row container">
          <div className="col-4 justify-content-center">
          <div className={`text-center`}>
          <img
            src={volunteer.personnalPhoto}
            className="profile-image mb-5"
            alt="profile"
            width="150"
            height="100"
          />
                    <span className="mx-4 purple page-title">{volunteer?.user?.fullName}</span>
                    <Rating
            value={volunteer.requisterEvaluationAvareg}
            readOnly
            stars={5}
            cancel={false}
            className="justify-content-center"
          />

<li className="nav-item mb-1">
          <Button
                  label={t("Edit")}
                  className="btn btn-primary  w-100"
                  type="button"
                  name="back"
                  onClick={(e) => {
                    navigate("/EditProfile");

                  }}
                />          </li>

        </div>
            
          </div>
          <div className="col-4">



       
            <div className="row">
              <div className="col-12 mt-1 text-center ">
                <span className="text-start">
                  <label
                    htmlFor="FullName"
                    className="d-inline-block mt-4  w-100"
                  >
                    {t("Full Name")}
                  </label>
                  <InputText
                    id="FullName"
                    name="FullName"
                    className="w-100"
                    value={volunteer?.user?.fullName}
                    disabled={true}
                  />
             
                </span>
              </div>
              <div className="col-12 mt-1 text-center ">
                <span className="text-start">
                  <label
                    htmlFor="UserName"
                    className="d-inline-block mt-2  w-100"
                  >
                    {t("Mail or Username")}
                  </label>
                  <InputText
                  id="UserName"
                    name="UserName"
                    className="w-100"
                    value={volunteer?.user?.userName}
                    disabled={true}
                  />
                 
                </span>
              </div>
              <div className="col-12 mt-1 text-center">
                <span className="text-start">
                  <label
                    htmlFor="UserGenderEnum"
                    className="d-inline-block mt-2  w-100"
                  >
                    {t("Gender")}
                  </label>
                  <InputText
                  id="Gender"
                  
                    name="Gender"
                    className="w-100"
                    value={genders[volunteer.userGenderEnum]?.name}
                    disabled={true}
                  />
              
                </span>
              </div>
              <div className="col-12 mt-1 text-center">
                <span className="text-start">
                  <label
                    htmlFor="DateOfBirth"
                    className="d-inline-block mt-2  w-100"
                  >
                    {t("Date Of Birth")}
                  </label>
                  <Calendar
                    showIcon
                    id="DateOfBirth"
                    className={`block w-100`}
                    name="DateOfBirth"
                    value={new Date(volunteer.dateOfBirth) }
                    disabled={true}
                  />
                
                </span>
              </div>
              <div className="col-12 mt-1 text-center">
                <span className="text-start">
                  <label
                    htmlFor="NationalityId"
                    className="d-inline-block mt-2  w-100"
                  >
                    {t("Nationality")}
                  </label>
                  <InputText
                  id="nationalityId"
                    name="nationalityId"
                    className="w-100"
                    value={AuthService.isArabic() ? volunteer.nationalityTextAr : volunteer.nationalityTextEn}
                    disabled={true}
                  />
              
                </span>
              </div>
              <div className="col-12 mt-1 text-center ">
                <span className="text-start">
                  <label
                    htmlFor="IdNumber"
                    className="d-inline-block mt-2  w-100"
                  >
                    {t("ID Number")}
                  </label>
                  <InputText
                    id="IdNumber"
                    name="IdNumber"
                    className="w-100"
                    value={volunteer.idNumber}
                    disabled={true}

                  />
               
                </span>
              </div>
              <div className="col-12 mt-1 text-center">
                <span className="text-start">
                  <label
                    htmlFor="CountryId"
                    className="d-inline-block mt-2  w-100"
                  >
                    {t("Country of Residence")}
                  </label>
                  <InputText
                    id="IdNumber"
                    name="IdNumber"
                    className="w-100"
                    value={AuthService.isArabic() ? volunteer.countryTextAr : volunteer.countryTextEn}
                    disabled={true}

                  />
               
                </span>
              </div>
              <div className="col-12 mt-1 text-center">
                <span className="text-start">
                  <label
                    htmlFor="CityId"
                    className="d-inline-block mt-2  w-100"
                  >
                    {t("City of Residence")}
                  </label>
                  <InputText
                    id="IdNumber"
                    name="IdNumber"
                    className="w-100"
                    value={AuthService.isArabic() ? volunteer.cityTextAr : volunteer.cityTextEn}
                    disabled={true}

                  />
               
                </span>
              </div>
              <div className="col-12 mt-1 text-center">
                <span className="text-start">
                  <label
                    htmlFor="AreaId"
                    className="d-inline-block mt-2  w-100"
                  >
                    {t("District/Area of Residence")}
                  </label>
                  <InputText
                    id="IdNumber"
                    name="IdNumber"
                    className="w-100"
                    value={AuthService.isArabic() ? volunteer.areaTextAr : volunteer.areaTextEn}
                    disabled={true}

                  />
               
                </span>
              </div>

              <div className="col-12 mt-1 text-center ">
                <span className="text-start">
                  <label
                    htmlFor="PhoneNumber"
                    className="d-inline-block mt-4  w-100"
                  >
                    {t("Phone Number")}
                  </label>
                  <InputText
                    id="PhoneNumber"
                    name="PhoneNumber"
                    className="w-100"
                    value={volunteer.phoneNumber}
                    disabled={true}

                  />
               
                </span>
              </div>
            </div>
       

          </div>
          <div className="col-4">
            <div className="row">
          <div className="col-12 mt-1 text-center">
                <span className="text-start">
                  <label
                    htmlFor="EducationStatusId"
                    className="d-inline-block mt-4  w-100"
                  >
                    {t("Educational Status")}
                  </label>
                  <InputText
                    id="PhoneNumber"
                    name="PhoneNumber"
                    className="w-100"
                    value={AuthService.isArabic() ? volunteer.educationStatusTextAr : volunteer.educationStatusTextEn}
                    disabled={true}

                  />
                </span>
              </div>
              


              <div className="col-12 mt-1 text-center">
                <span className="text-start">
                  <label
                    htmlFor="FieldOfStudyId"
                    className="d-inline-block mt-2  w-100"
                  >
                    {t("Field Of Study")}
                  </label>
                  <InputText
                    id="Field"
                    name="Field"
                    className="w-100"
                    value={AuthService.isArabic() ? volunteer.fieldOfStudyTextAr : volunteer.fieldOfStudyTextEn}
                    disabled={true}

                  />
                
                </span>
              </div>
              <div className="col-12 mt-1 text-center">
                <span className="text-start">
                  <label
                    htmlFor="BloodTypeId"
                    className="d-inline-block mt-2  w-100"
                  >
                    {t("Blood Type")}
                  </label>
                  <InputText
                    id="Field"
                    name="Field"
                    className="w-100"
                    value={AuthService.isArabic() ? volunteer.bloodTypeTextAr : volunteer.bloodTypeTextEn}
                    disabled={true}

                  />
                </span>
              </div>
              <div className="col-12 mt-1 text-center ">
                <span className="text-start">
                  <label
                    htmlFor="Experience"
                    className="d-inline-block mt-4  w-100"
                  >
                    {t("Volunteering Experience")}
                  </label>
                  <InputTextarea
                    id="Experience"
                    name="Experience"
                    rows={5}
                    cols={10}
                    value={volunteer.experience}
                    className="w-100"
                    disabled={true}

                  />

               
                </span>
              </div>
              <div className="col-12  mt-2 ">
                <label
                  htmlFor="IsCurrentyWorking"
                  className="d-inline-block mt-2  w-100"
                >
                  {t("Are you currently working?")}
                </label>
                <div className="row mt-2">
                  <div className="col-3">
                    <Checkbox
                      inputId={"IsCurrentyWorking"}
                      name="IsCurrentyWorking"
                      value={volunteer.isCurrentyWorking}
                      checked={volunteer.isCurrentyWorking}
                      disabled={true}

                    />
                    <label className="mx-2 ">{t("Yes")}</label>
                  </div>
                </div>
              </div>
              <div className="col-12  mt-2 ">
                <label
                  htmlFor="IsCurrentlyStudent"
                  className="d-inline-block mt-2  w-100"
                >
                  {t("Are you currently student?")}
                </label>
                <div className="row mt-2">
                  <div className="col-3">
                    <Checkbox
                      inputId={"IsCurrentlyStudent"}
                      name="IsCurrentlyStudent"
                      value={volunteer.isCurrentlyStudent}
                      checked={volunteer.isCurrentlyStudent}
                      disabled={true}

                    />
                    <label className="mx-2 ">{t("Yes")}</label>
                  </div>
                </div>
              </div>

              <div className="col-12  mt-3 ">
                <label
                  htmlFor="IsWillGovernarates"
                  className="d-inline-block mt-2  w-100"
                >
                  {t(
                    "Are you willing to volunteer in all Jordanian Governarates?"
                  )}
                </label>
                <div className="row mt-2">
                  <div className="col-3">
                    <Checkbox
                      inputId={"IsWillGovernarates"}
                      name="IsWillGovernarates"
                      value={volunteer.isWillGovernarates}
                      disabled={true}

                      checked={volunteer.isWillGovernarates}
                    />
                    <label className="mx-2 ">{t("Yes")}</label>
                  </div>
                </div>
              </div>

              <div className="col-12  mt-3 ">
                <label
                  htmlFor="IsWillWeekends"
                  className="d-inline-block mt-2  w-100"
                >
                  {t(
                    "Are you willing to volunteer on weekends,holidays and special occasions?"
                  )}
                </label>
                <div className="row mt-2">
                  <div className="col-3">
                    <Checkbox
                      inputId={"IsWillWeekends"}
                      name="IsWillWeekends"
                      value={volunteer.isWillWeekends}
                      disabled={true}

                      checked={volunteer.isWillWeekends}
                    />
                    <label className="mx-2 ">{t("Yes")}</label>
                  </div>
                </div>
              </div>

              <div className="col-12 mt-1 text-center mt-2">
                <span className="text-start">
                  <label
                    htmlFor="SpecialSkillsId"
                    className="d-inline-block mt-2  w-100"
                  >
                    {t("Special Skills")}
                  </label>
                  <InputText
                    id="Special"
                    name="Special"
                    className="w-100"
                    disabled={true}

                    value={AuthService.isArabic() ? volunteer.Acting : volunteer.specialSkillsTextEn}
                  />
                </span>
              </div>
          </div>
          </div>

            
        </div>)}
      
    </>
  );
};

export default ViewVolunteer;
