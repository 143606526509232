import { React,useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { InputText } from "primereact/inputtext";
import { useTranslation } from "react-i18next";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Password } from 'primereact/password';
import { useNavigate  } from "react-router-dom";
import { AuthService } from "../../shared/Services/AuthService";
import { SharedService } from "../../shared/Services/sharedService";
const LoginPage = ({}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
 
  
    // eslint-disable-next-line
  }, []);
  const formik = useFormik({
    initialValues: {
        username:"",
        password:"",
        rememberMe:false
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
        // email: yup.string().required(t("RequiredField")).email(t("pleaseEnterValidEmail")),
        password: yup.string().required(t("Required Field")),
        username: yup.string().required(t("Required Field"))


    }),

    onSubmit: (data) => {
      SharedService.ShowLoader();

      AuthService.login(data).then((res) => {
        SharedService.HideLoader();
        if (res.data.errorCode === 0) {
          localStorage.setItem("Auth", res.data.data.token);
          localStorage.setItem("userRole", res.data.data.userRole);
          localStorage.setItem("name", res.data.data.name);
          localStorage.setItem("rating", res.data.data.rating);
          localStorage.setItem("profileImage", res.data.data.profileImage);
          window.location.reload();
        }
      });
    },
  });
  return (
    <>
      <form onSubmit={formik.handleSubmit} className="p-fluid">
        <div className="row justify-content-center p-1 p-md-5">
          <div className="col-12 col-md-9 col-xl-4">
            <div className="row justify-content-center">
              <div className="col-12 text-center">
                <img
                  src={require("../../assests/Images/footer-logo.png")}
                  className="footer-logo"
                  alt="footer"
                />
              </div>
              <div className="col-12 title text-center mt-3">
                {t("Log in to your account")}
              </div>
              <div className="col-12 grey font-16 text-center mt-3">
              {t("Welcome Back! Please enter your details")}

                
              </div>

              <div className="col-12 mt-1 text-center ">
                <span className="text-start">
                <label htmlFor="username" className="d-inline-block mt-4 grey w-75">
                  {t("Username")}
                </label>
                <InputText
                  id="username"
                  name="username"
                  className="w-75"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                />
                {formik.touched.username && formik.errors.username && (
                  <label className="errorMessage w-75">{formik.errors.username}</label>
                )}
                </span>
              </div>
              <div className="col-9 mt-1 text-center">
              <span className="text-start">

                <label htmlFor="password" className="d-inline-block mt-2 grey w-100">
                  {t("Password")}
                </label>
                <Password
                feedback={false}
                  toggleMask 
                  id="password"
                  name="password"
                  className="block w-100 text-center"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                />
                {formik.touched.password && formik.errors.password && (
                  <label className="errorMessage w-100">{formik.errors.password}</label>
                )}
                </span>
              </div>
              <div className="col-12 col-md-6  mt-2 text-center">
                <Checkbox
                  inputId={"rememberMe"}
                  name="rememberMe"
                  value={formik.values.rememberMe}
                  onChange={(e) => {
                    formik.setFieldValue("rememberMe", e.checked);
                  }}
                  checked={formik.values.rememberMe}
                />
                <label htmlFor={"rememberMe"} className="mx-2 grey">
                  {t("remember for 30 days")}
                </label>
              </div>
              <div className="col-12 col-md-4 mt-2 text-center">
                <span className="light-purple pointer" onClick={(e) => {
                    navigate("/ForgotPassword");

                  }}>
                  {t("Forgot Password")}
                </span>
              </div>
              <div className="col-12 mt-4 text-center">
                <Button
                  label={t("Sign in")}
                  className="btn btn-primary  w-75"
                  type="submit"
                />
              </div>
              <div className="col-12 mt-4 text-center">
                <span className="grey">{t("don't have an account?")} </span>
                <span className="light-purple pointer"    onClick={(e) => {
                    navigate("/Register");

                  }}> {t("Sign up")}</span>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default LoginPage;
