import { toast } from "react-toastify";
import { MessageTypeConstant } from "../constant/messageTypeConstant";

export const SharedService = {
  ShowToast,
  ShowLoader,
  HideLoader
};

function ShowToast(message, type) {
  message = message ? message : "an error occured!";
  switch (type) {
    case MessageTypeConstant.Success:
      toast.success(message);
      break;
    case MessageTypeConstant.Info:
      toast.info(message);
      break;
    case MessageTypeConstant.Error:
      toast.error(message);
      break;
    case MessageTypeConstant.Warn:
      toast.warn(message);
      break;
    default:
  }
}

function ShowLoader() {
  try{
    document.getElementById("masterLoader").style.display = "block";
  }
  catch(e){
  }
}
function HideLoader() {
  try{
  document.getElementById("masterLoader").style.display = "none";
}
catch(e){
}
}


