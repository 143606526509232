import { React, useEffect,useState } from "react";
import { useTranslation } from "react-i18next";
import Task from "../../shared/Task";
import { useNavigate, useOutletContext } from "react-router-dom";
import { ProjectService } from "../../shared/Services/ProjectService";
import { SharedService } from "../../shared/Services/sharedService";
const TasksList = ({}) => {
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);

function fillTasks(){
  SharedService.ShowLoader();

  ProjectService.myTasks().then((res) => {
        SharedService.HideLoader();

    if (res.data.errorCode === 0) {
      setRows(res.data.data);
    }
  });
}
  
  useEffect(() => {
    fillTasks();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="container mt-3">
        <div className="row">
          <div className="col-12 text-uppercase grey font-12">
            {t("overview")}
          </div>
          <div className="col-12 title">{t("My Tasks")}</div>
        </div>
        <div className="row">
          {rows.length === 0 && (
            <>
            <div className="col-12 text-center font-25 primary-color mt-5">
              {t("No Tasks Yet")}</div></>
          )}
        {rows.map((rowData, i) => {
            return (
              <Task task={rowData} key={`task + ${i}`} />
            );
          })}

       
        </div>
      </div>
    </>
  );
};

export default TasksList;
