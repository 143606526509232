import { React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { HomeService } from "../../shared/Services/HomeService";
import { useNavigate, useOutletContext } from "react-router-dom";
import { SharedService } from "../../shared/Services/sharedService";
const Certificate = ({}) => {
  const { t } = useTranslation();
  const { isAdmin, isVolunteer, isRequester } = useOutletContext();
  const [dashboardResponse, setDashboardResponse] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    getVolunteerDashboard();
    // eslint-disable-next-line
  }, []);

  function getVolunteerDashboard() {
    SharedService.ShowLoader();
    HomeService.GetVolunteerDashboard().then((res) => {
      if (res.data.data) {
        SharedService.HideLoader();

        setDashboardResponse(res.data.data);
      }
    });
  }

  const getTextForCertificate = (type) => {
    switch (type) {
      case "Silver":
        return t("You are eligible for a silver certificate");
      case "Gold":
        return t("You are eligible for a gold certificate");
      case "Platinum":
        return t("You are eligible for a platinum certificate");
      case "Bronze":
        return t("You are eligible for a bronze certificate");
      default:
        return t("You are not eligible to obtain a certificate yet");
    }
  };

  function downloadImage(){
    SharedService.ShowLoader();
    HomeService.GetCertificate().then((response) => {
      SharedService.HideLoader();
      if (response && response.status === 200 && response.data) {
        const blob = new Blob([response.data], { type: 'image/jpeg' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'certificate.jpg');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } else {
        console.error('Failed to get the certificate image.');
      }

    });
  }

  return (
    <>
      <div className="container mt-3">
        <div className="row">
          <div className="col-12 text-uppercase grey font-12">
            {t("overview")}
          </div>
          <div className="col-12 title">{t("My Dashboard")}</div>
        </div>
        <div className="row mt-3 justify-content-md-start justify-content-center">
          <div className="col-6 col-md-4 col-xl-3 m-2 box blue-box">
            <div className="font-20 mb-2">{dashboardResponse?.totalhours}</div>
            <div>{t("Hours Logged")}</div>
            <div>{t("In Total")}</div>
          </div>
          <div className="col-6 col-md-4 col-xl-3 m-2  box blue-box">
            <div className="font-20 mb-2">
              {" "}
              {dashboardResponse?.totalworkedonprojects}
            </div>
            <div>{t("Projects Worked On")}</div>
            <div>{t("In The Last Month")}</div>
          </div>
        </div>
        <div className="row font-weight-bold mt-5 px-3">
          {getTextForCertificate(dashboardResponse?.certificateType)}
        </div>
        {dashboardResponse?.certificateType && (
          <>
            <div className="row  font-weight-bold px-3">
              {t("Click the button below to request you certificate")}
            </div>
            <div className="col-12 mt-5">
              <div className="row  mt-5 mb-5">
                <div className="col-12 col-md-4 ">
                  <Button
                    label={t("Get Certificate")}
                    className="btn btn-blue w-100"
                    type="button"
                    onClick={() => {
                      downloadImage()
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Certificate;
