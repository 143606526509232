import { React, useState, useContext } from "react";
import { LayoutContext } from "../Contexts/LayoutContext";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import  {useNavigate} from "react-router-dom"
import { AuthService } from "./Services/AuthService";
import moment from "moment";

const AvailableProject = ({ project }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
const isArabic = AuthService.isArabic();


  return (
    <>
         <div className="col-12 col-md-6 p-1 p-md-5">
            <div className="details-card">
            <div className="row justify-content-end">
            <div className="col-12  mb-3 mt-2">{isArabic ? project.nameAr : project.nameEn}</div>

              <div className="col-12 col-md-6 mt-2">{t("Department")}:</div>
              <div className="col-12 col-md-6 mt-2 blue">{isArabic ? project.departmentNameAr : project.departmentNameEn}</div>
              <div className="col-12 col-md-6 mt-2">{t("Date")}:</div>
              <div className="col-12 col-md-6 mt-2 blue">  { moment(new Date( project.fromDate)).format("YYYY-MM-DD")} - { moment(new Date( project.toDate)).format("YYYY-MM-DD")}</div>
              <div className="col-12 col-md-6 mt-2">{t("Requester")}:</div>
              <div className="col-12 col-md-6 mt-2 blue">{ project.client}</div>
              <div className="col-12 col-md-6 mt-2"></div>
              <div className="col-12 col-md-6 mt-2 blue"></div>
              <div className="col-12 col-md-3 mt-4 text-end"> <Button
                  label={t("More Info")}
                  className="btn btn-primary  w-100 "
                  type="button"
                  onClick={()=>{
                    navigate(`/Projects/Details/${project.id}`)
                  }}
                /></div>


            </div>

         
            </div>
          </div>
    </>
  );
};

export default AvailableProject;
