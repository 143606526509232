import { React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProgressBar } from "primereact/progressbar";
import { Rating } from "primereact/rating";
import { ProjectService } from "../../../shared/Services/ProjectService";
import {Dialog} from "primereact/dialog";
import TaskExecutionTable from "./TaskExecutionTable";
import EvaluationForm from "./EvaluationForm";
import { SharedService } from "../../../shared/Services/sharedService";

const VolunteersTable = ({ id }) => {
  const { t } = useTranslation();
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [dialogItem, setDialogItem] = useState(undefined);
  const [showDialog, setShowDialog] = useState(false);
  const [evaludationItem, setEvaludationItem] = useState(undefined);

  const { isAdmin, isVolunteer, isRequester } = useOutletContext();
  const navigate = useNavigate();

  function fillTable() {
    SharedService.ShowLoader();

    ProjectService.GetProjectVolunteers(id).then((res) => {
      SharedService.HideLoader();

      if (res) {
        setRows(res.data.data);
      }
    });
  }
  function initializeColumns() {
    setColumns([
      { field: "id", header: t("#ID") },
      { field: "volunteerName", header: t("Volunteer Name") },

      {
        field: "taskDone",
        header: t("Tasks Done"),
        body: progressBodyTemplate,
      },
      { field: "assignedTask", header: t("Assigned Tasks") },

      {
        field: "evaluation",
        header: t("Evaluation"),
        body: evaluationBodyTemplate,
      },
      {
        field: "actions",
        header: t("Actions"),
        body: actionsBodyTemplate,
      },
    ]);
  }
  const actionsBodyTemplate = (rowData) => {
    return (
      <>
        {rowData.evaluation === null && rowData.taskDone >= 1 && (
          <span className="actionList" onClick={() => {
            setEvaludationItem(rowData);
          }}>
            <i role="button" className="fa fa-star" aria-hidden="true"></i>
          </span>
        )}

        <span className="actionList"  onClick={() => {
                setDialogItem({
                  id:rowData.projectTaskId,
                });
                setShowDialog(true);
              }}>
          <i role="button" className="fa fa-pencil" aria-hidden="true"></i>
        </span>
      </>
    );
  };
  const evaluationBodyTemplate = (rowData) => {
    return (
      <>
        {rowData.evaluation && (
          <Rating
            value={rowData.evaluation}
            readOnly
            stars={5}
            cancel={false}
            className="justify-content-center"
          />
        )}
        {!rowData.evaluation && <span>{t("Not Rated")}</span>}
      </>
    );
  };
  const progressBodyTemplate = (rowData) => {
    return (
      <>
        <span>{rowData.taskDone * 100 + "%"}</span>
        <ProgressBar
          value={rowData.taskDone * 100}
          style={{ height: "8px" }}
          showValue={false}
          color={"#1A507A"}
          className="d-block"
        ></ProgressBar>
      </>
    );
  };

  const dynamicColumns = columns.map((col) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        body={col.body}
        hidden={col.hidden}
      />
    );
  });

  useEffect(() => {
    initializeColumns();
    fillTable();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="row mt-5 mb-5 table-div">
          <Dialog
      visible={showDialog && dialogItem !==undefined}
      style={{minWidth:"80vw", minHeight:"80vh"}}
      onHide={
        ()=>{
          setShowDialog(false);
          setDialogItem(undefined);
        }
      }
>
  <TaskExecutionTable  fillMasterTable={fillTable} item={dialogItem} />

    </Dialog>
    <Dialog
      header={evaludationItem?.volunteerName}
      visible={evaludationItem !==undefined}
      style={{minWidth:"30vw", minHeight:"50vh"}}
      onHide={
        ()=>{
          setEvaludationItem(undefined);
        }
      }
>
  <EvaluationForm fillMasterTable={fillTable} item={evaludationItem} setEvaludationItem={setEvaludationItem}/>

    </Dialog>
      <DataTable
        rows={10}
        paginator
        paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
        value={rows}
        responsiveLayout="scroll"
        stripedRows
        className="centeredColumnContent"
        emptyMessage={t("No Records")}
      >
        {dynamicColumns}
      </DataTable>
    </div>
  );
};

export default VolunteersTable;
