import { useTranslation } from "react-i18next";
import React, { useState, useEffect, useContext } from "react";
import { FileUpload } from 'primereact/fileupload';
import { InputTextarea } from "primereact/inputtextarea";
import { useFormik } from "formik";
import * as yup from "yup";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import moment from "moment";
import { UserService } from "../../shared/Services/UserService";
import { SharedService } from "../../shared/Services/sharedService";
import { MessageTypeConstant } from "../../shared/constant/messageTypeConstant";
import { useNavigate  } from "react-router-dom";



const AddUser = () => {
  let query = new URLSearchParams(window.location.search);
  let id = +query.get("ID");
  const navigate = useNavigate();

  const { t } = useTranslation();
const [status, setStatus] = useState([]);
const [rules, setRules] = useState([]);
const [user, setUser] = useState(null);


  function GetUserRoleEnumsLookups(){
 
    UserService.GetUserRoleEnumsLookups().then((res) => {
      if (res) {
        debugger;
        setRules(Object.entries(res.data).map(([code,  name]) => ({code, name})));
      }
    });
  
}

function getUser(id){
  SharedService.ShowLoader();

  UserService.GetById(id).then((res) => {
    SharedService.HideLoader();

    if (res.data.data) {
      setUser(res.data.data);
    }
  });

}
  useEffect(() => {
    if(id){
      getUser(id);
    }
    GetUserRoleEnumsLookups();

    FillStatus();
  }, []);


function FillStatus() {
  setStatus([
    { name: t("Active"), code: true },
    { name: t("In Active"), code: false },
  ]);
}

  const formik = useFormik({

    initialValues:  {
      Id:user ? user.id : 0,
      password: user ? user.password :"",
      userName:user ? user.userName :"",
      fullName:user ? user.fullName :"",
      userRole:user ? user.userRole :"",
      IsActive:!user ? true : user?.isActive,
    },
    validationSchema: yup.object().shape({

      password: yup.string().required(t("Required Field")),
      userName: yup.string().required(t("Required Field")),
      fullName: yup.string().required(t("Required Field")),
      userRole: yup.string().required(t("Required Field")),
   
  }),
    enableReinitialize: true,

    onSubmit: (data) => {
      data.isActive = data.isActive === 1 ? true : false;
      SharedService.ShowLoader();
      
      UserService.CreateOrUpdate(data).then((res) => {
        SharedService.HideLoader();

        if (res.data.errorCode === 0) {
          SharedService.ShowToast(t("User Added Successfully"),MessageTypeConstant.Success);
             navigate("/Users")

        }
      });
      
    
    },
  });

  return (
    <>
      <div >
        {" "}
        <form onSubmit={formik.handleSubmit} className="p-fluid">
          <div className="row">
            <div className="col-12 mt-1 text-center ">
              <span className="text-start">
                <label htmlFor="userName" className="d-inline-block mt-4  w-100">
                  {t("User Name")}
                </label>
                <InputText
                  id="userName"
                  name="userName"
                  className="w-100"
                  value={formik.values.userName}
                  onChange={formik.handleChange}
               
                />
                {formik.touched.userName && formik.errors.userName && (
                  <label className="errorMessage w-100">
                    {formik.errors.userName}
                  </label>
                )}
              </span>
            </div>
            <div className="col-12 mt-1 text-center ">
              <span className="text-start">
                <label htmlFor="fullName" className="d-inline-block mt-4  w-100">
                  {t("Full Name")}
                </label>
                <InputText
                  id="fullName"
                  name="fullName"
                  className="w-100"
                  value={formik.values.fullName}
                  onChange={formik.handleChange}
                
                />
                {formik.touched.fullName && formik.errors.fullName && (
                  <label className="errorMessage w-100">
                    {formik.errors.fullName}
                  </label>
                )}
              </span>
            </div>
            <div className="col-12 mt-1 text-center ">
              <span className="text-start">
                <label htmlFor="password" className="d-inline-block mt-4  w-100">
                  {t("Password")}
                </label>
                <InputText
                  id="password"
                  name="password"
                  className="w-100"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                
                />
                {formik.touched.password && formik.errors.password && (
                  <label className="errorMessage w-100">
                    {formik.errors.password}
                  </label>
                )}
              </span>
            </div>
        

            <div className="col-12 mt-1 text-center">
              <span className="text-start">
                <label
                  htmlFor="userRole"
                  className="d-inline-block mt-2  w-100"
                >
                  {t("User Role")}
                </label>
                <Dropdown
                 
                  optionLabel="name"
                  id="userRole"
                  value={
                    rules[
                      rules.findIndex(
                        (obj) => +obj.code === +formik.values.userRole
                      )
                    ]
                  }
                  options={rules}
                  onChange={(e) => {
                    debugger;
                    formik.setFieldValue("userRole", +e.value.code);
                  }}
                  className={`w-100`}
                  placeholder={t("select")}
                />

                {formik.touched.userRole && formik.errors.userRole && (
                  <label className="errorMessage w-100">
                    {formik.errors.userRole}
                  </label>
                )}
              </span>
            </div>
            <div className="col-12 mt-1 text-center">
              <span className="text-start">
                <label
                  htmlFor="isActive"
                  className="d-inline-block mt-2  w-100"
                >
                  {t("Status")}
                </label>
                <Dropdown
                 
                  optionLabel="name"
                  id="isActive"
                  value={
                    status[
                      status.findIndex(
                        (obj) => +obj.code === +formik.values.isActive
                      )
                    ]
                  }
                  options={status}
                  onChange={(e) => {
                    debugger;
                    formik.setFieldValue("isActive", +e.value.code);
                  }}
                  className={`w-100`}
                  placeholder={t("select")}
                />

                {formik.touched.isActive && formik.errors.isActive && (
                  <label className="errorMessage w-100">
                    {formik.errors.isActive}
                  </label>
                )}
              </span>
            </div>
          
         
        

         

            <div className="col-12 mt-4 text-center">
              <Button
                label={t("Save")}
                className="btn btn-blue  w-100"
                type="submit"
              />
            </div>
          
          </div>
        </form>
      </div>
    </>
  );
};

export default AddUser;
