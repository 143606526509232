import { React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { SharedService } from "../../shared/Services/sharedService";
import moment from 'moment';
import { ReportService } from "../../shared/Services/ReportService";

const CertificatesReports = ({}) => {
  const { t } = useTranslation();



  function  ExportData() {
    SharedService.ShowLoader();
    ReportService.DownloadCertificateReport().then((response) => {
      SharedService.HideLoader();
      debugger;
      if (response && response.status === 200 && response.data) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        const formattedDate = moment().format('YYYY-MM-DD HH:mm');
        const filename = `Certificates ${formattedDate}`;
        link.href = url;
        link.setAttribute(
            'download',
            `${filename}.xlsx`,
        );
        document.body.appendChild(link);
        link.click();
        
        link.remove();
      }
    });
  }

  const formik = useFormik({
    initialValues: {
      projectId: "",
    },
    enableReinitialize: true,
    onSubmit: (data) => {
      ExportData();
    },
  });





  return (
    <>
      <div className="container mt-3 mb-5 ">
        <div className="row">
          <div className="col-12  title">{t("Certificates Report")}</div>
        </div>
        <div className="row mt-4">
          <form onSubmit={formik.handleSubmit} className="p-fluid">
            <div className="row">
             

              <div className="col-6 col-md-3 ">
                <label
                  htmlFor="department"
                  className="d-inline-block mt-2  w-100"
                ></label>
                <Button
                  label={t("Export")}
                  className="btn btn-blue  w-100 mt-1"
                  type="submit"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CertificatesReports;
