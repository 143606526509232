import { React, useState, useContext, useEffect } from "react";
import { Button } from "primereact/button";
import { Outlet } from "react-router-dom";
import { LayoutContext } from "../Contexts/LayoutContext";
import { URLEnum } from "./constant/URLEnum";
import { Link } from "react-router-dom";
import { Sidebar } from "primereact/sidebar";
import { AuthService } from "./Services/AuthService";
import { SharedService } from "./Services/sharedService";
import { Roles } from "./constant/Roles";
import { useTranslation } from "react-i18next";
import { Rating } from "primereact/rating";
import {Dialog} from "primereact/dialog";
import ViewVolunteer from "../pages/Volunteers/ViewVolunteer";


const SideBar = ({ img, title, description }) => {
  const { t } = useTranslation();

  const [showMenu, setShowMenu] = useState(true);
  const { setShowSideBar, showSideBar } = useContext(LayoutContext);
  const [activeUrl, setActiveIndex] = useState(true);
  const [links, setLinks] = useState([]);
  const [vId, setVId] = useState(null);

  const isAdmin = +AuthService.getUserRole() === Roles.ADMIN;
  const isRequester = +AuthService.getUserRole() === Roles.REQUESTER;
  const isVolunteer = +AuthService.getUserRole() === Roles.VOLUNTEER;
  useEffect(() => {
fillLinks();
    let path = window.location.pathname;
    setActiveURL(path.toLowerCase());
  }, [window.location.pathname]);


  function fillLinks(){
   if(isAdmin){
    setLinks([
      {
        activeLink:URLEnum.Projects,
        to:"/Projects/List",
        label:t("Projects"),
        fontAwesome:"fas fa-list-check fa-fw me-3"
      },
      {
        activeLink:URLEnum.Dashboard,
        to:"/dashboard",
        label:t("My Dashboard"),
        fontAwesome:"fas fa-chart-area fa-fw me-3"
      },
      {
        activeLink:URLEnum.Volunteer,
        to:"/Volunteers",
        label:t("Volunteers"),
        fontAwesome:"fas fa-medal fa-fw me-3"
      },
      // {
      //   activeLink:URLEnum.Settings,
      //   to:"#",
      //   label:t("Settings"),
      //   fontAwesome:"fas fa-gear fa-fw me-3"
      // },
      {
        activeLink:URLEnum.Kpis,
        to:"/KPIs",
        label:t("KPIs"),
        fontAwesome:"fas fa-people-group fa-fw me-3"
      },
      {
        activeLink:URLEnum.Users,
        to:"/Users",
        label:t("Users"),
        fontAwesome:"fas fa-people-group fa-fw me-3"
      },
      {
        activeLink:URLEnum.Slider,
        to:"/CMS/slider",
        label:t("Sliders"),
        fontAwesome:"fas fa-gear fa-fw me-3"
      },
      {
        activeLink:URLEnum.News,
        to:"/CMS/News",
        label:t("News"),
        fontAwesome:"fas fa-gear fa-fw me-3"
      },
      {
        activeLink:URLEnum.WhatsNews,
        to:"/CMS/WhatsNew",
        label:t("What New"),
        fontAwesome:"fas fa-gear fa-fw me-3"
      },
      {
        activeLink:URLEnum.Reports,
        to:"/Reports",
        label:t("Reports"),
        fontAwesome:"fas fa-file fa-fw me-3"
      },
    ]);
   }


   if(isVolunteer){
    setLinks([
      {
        activeLink:URLEnum.Projects,
        to:"/Projects",
        label:t("Projects"),
        fontAwesome:"fas fa-list-check fa-fw me-3"
      },
      {
        activeLink:URLEnum.Dashboard,
        to:"/dashboard",
        label:t("My Dashboard"),
        fontAwesome:"fas fa-chart-area fa-fw me-3"
      },
      {
        activeLink:URLEnum.Task,
        to:"/Tasks",
        label:t("Tasks"),
        fontAwesome:"fas fa-people-group fa-fw me-3"
      },
      // {
      //   activeLink:URLEnum.Settings,
      //   to:"#",
      //   label:t("Settings"),
      //   fontAwesome:"fas fa-gear fa-fw me-3"
      // },
      {
        activeLink:URLEnum.Certificate,
        to:"/Certificate",
        label:t("Certificate"),
        fontAwesome:"fas fa-thin fa-hand-holding-heart fa-fw me-3"
      },
    ]);
   }

   if(isRequester){
    setLinks([
      {
        activeLink:URLEnum.Projects,
        to:"/Projects/List",
        label:t("Projects"),
        fontAwesome:"fas fa-list-check fa-fw me-3"
      },
      {
        activeLink:URLEnum.Dashboard,
        to:"/dashboard",
        label:t("My Dashboard"),
        fontAwesome:"fas fa-chart-area fa-fw me-3"
      },
      // {
      //   activeLink:URLEnum.Settings,
      //   to:"#",
      //   label:t("Settings"),
      //   fontAwesome:"fas fa-gear fa-fw me-3"
      // },
    
    ]);
   }


  }
  function setActiveURL(path) {
    if (path.includes("dashboard")) setActiveIndex(URLEnum.Dashboard);
    if (path.includes("Certificate".toLowerCase()))
      setActiveIndex(URLEnum.Certificate);
    if (path.includes("Tasks".toLowerCase())) setActiveIndex(URLEnum.Task);
    if (path.includes("Projects".toLowerCase()))
      setActiveIndex(URLEnum.Projects);
    if (path.includes("KPIs".toLowerCase())) setActiveIndex(URLEnum.Kpis);
    if (path.includes("Volunteers".toLowerCase()))
      setActiveIndex(URLEnum.Volunteer);
      if (path.includes("Users".toLowerCase()))
      setActiveIndex(URLEnum.Users);
      if (path.includes("CMS/slider".toLowerCase()))
      setActiveIndex(URLEnum.Slider);
      if (path.includes("CMS/News".toLowerCase()))
      setActiveIndex(URLEnum.News);
      if (path.includes("CMS/whatsNew".toLowerCase()))
      setActiveIndex(URLEnum.WhatsNews);
      if (path.includes("Reports".toLowerCase()))
      setActiveIndex(URLEnum.Reports);
  }
  return (
    <>
        <Dialog
      header={AuthService.getName()}
      visible={vId !==null}
      style={{minWidth:"30vw", maxHeight:"95vh"}}
      onHide={
        ()=>{
          setVId(null);
        }
      }
>
  {(vId)&& <ViewVolunteer  />}

    </Dialog>

      {!showSideBar && (
        <div className="position-sticky m-2 side-bar-icon">
          <Button
            icon="pi pi-arrow-right position-sticky m-2 side-bar-icon "
            onClick={() => setShowSideBar(true)}
            className="mr-2 btn-primary"
          />
        </div>
      )}

      <Sidebar
        visible={showSideBar}
        onHide={() => setShowSideBar(false)}
        blockScroll="true"
      >
        <div className={`text-center ${showSideBar ? "" : " d-none"}`}>
          <img
            src={AuthService.getImage()}
            className="profile-image mb-5"
            alt="profile"
            width="150"
            height="100"
          />
        </div>
        <div className={`text-center ${showSideBar ? "" : " d-none"}`}>
          <div className="purple page-title">{AuthService.getName()}</div>
          <div className="grey font-12 mt-1 mb-5">{AuthService.getUserRoleString()}</div>


          {isVolunteer && (
        <div >
          <Button
            icon="pi pi-user  "
            onClick={() => setVId(true)}
            className="mr-2 btn-primary"
          />
        </div>
      )}

          
         {AuthService.getUserRole() == Roles.VOLUNTEER  &&  <Rating
            value={AuthService.getRating()}
            readOnly
            stars={5}
            cancel={false}
            className="justify-content-center"
          />
         }
        </div>
        <div
          className={`list-group list-group-flush mx-3 mt-5 ${
            showSideBar ? "" : " d-none"
          }`}
        >

{links.map((link, i) => {
              return       <Link
              key={link.label}
              onClick={() => setShowSideBar(false)}
              to={link.to}
              className={`list-group-item list-group-item-action py-2 ripple ${
                activeUrl === link.activeLink ? "active" : ""
              }`}
            >
              <i className={link.fontAwesome}></i>
              <span>{link.label}</span>
            </Link>
            })}
        </div>
      </Sidebar>

      <div className="container dashboard-container">
        <Outlet context={{ isAdmin, isRequester, isVolunteer }} />
      </div>
    </>
  );
};

export default SideBar;
