import { React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Button } from "primereact/button";
import { Steps } from "primereact/steps";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { ProgressBar } from "primereact/progressbar";
import AppConsts from "../../shared/constant/appConsts";
import { ProjectService } from "../../shared/Services/ProjectService";
import { SharedService } from "../../shared/Services/sharedService";
import { MessageTypeConstant } from "../../shared/constant/messageTypeConstant";
import { Dialog } from "primereact/dialog";
import KPIsReport from "./KPIsReport";
import CertificatesReports from "./CertificatesReports";
import RequestsReports from "./RequestsReports";
import ActivitiesReports from "./ActivitiesReports";
import VolunteeringHoursReport from "./VolunteeringHoursReport";
import VolunteersDataReport from "./VolunteersDataReport";

const Reports = ({}) => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    // eslint-disable-next-line
  }, [activeIndex]);

  const TabsTemplate = (rowData) => {
    return (
      <>
        <div
          className={`col-3 col-md-6 justify-content-center ${
            activeIndex === rowData.index ? "active" : ""
          }`}
        >
          <div>{rowData.label}</div>
          <div></div>
        </div>
      </>
    );
  };
  const tabsItem = [
    {
      label: t("Volunteers data"),
      total: 10,
      template: TabsTemplate,
      index: 0,

      command: (event) => {},
    },
    // {
    //   label: t("Volunteering hours"),
    //   command: (event) => {},
    //   index: 1,
    //   total: 40,

    //   template: TabsTemplate,
    // },

    {
      label: t("Activities reports"),
      command: (event) => {},
      total: 30,

      index: 2,
      template: TabsTemplate,
    },
    {
      label: t("Requests reports"),
      total: 20,
      command: (event) => {},
      index: 3,
      template: TabsTemplate,
    },
    {
      label: t("Certificates"),
      total: 20,
      command: (event) => {},
      index: 4,
      template: TabsTemplate,
    },
    {
      label: t("KPIs reports"),
      total: 20,
      command: (event) => {},
      index: 5,
      template: TabsTemplate,
    },
  ];

  return (
    <>
      <div className="container mt-3 mb-5 ">
        <div className="row justify-content-between">
          <div className="col-6 col-md-3 title">{t("Reports")}</div>
        </div>
        <div className="row mt-4">
          {tabsItem.map((rowData, i) => {
            return (
              <div
                className={`col-md-2 col-6 mt-2 text-center tab-box pointer py-5 ${
                  "tab-box" + rowData.index
                } ${activeIndex === rowData.index ? "active" : ""}`}
                onClick={() => {
                  setActiveIndex(rowData.index);
                }}
                key={"tab" + rowData.index}
              >
                <div className="label">{rowData.label}</div>
                <div></div>
              </div>
            );
          })}
        </div>
        <div className="row mt-5 mb-5 table-div">
          {activeIndex === 0 && <VolunteersDataReport/>}
          {/* {activeIndex === 1 && <VolunteeringHoursReport />} */}
          {activeIndex === 2 && <ActivitiesReports />}
          {activeIndex === 3 && <RequestsReports />}
          {activeIndex === 4 && <CertificatesReports />}
          {activeIndex === 5 && <KPIsReport />}
        </div>
      </div>
    </>
  );
};

export default Reports;
