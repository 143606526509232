import { React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import Task from "../../shared/Task";
import { useNavigate, useOutletContext } from "react-router-dom";
import { HomeService } from "../../shared/Services/HomeService";
import { AuthService } from "../../shared/Services/AuthService";
import { FormMode } from "../../shared/constant/FormMode";
import { ProgressBar } from 'primereact/progressbar';
import { SharedService } from "../../shared/Services/sharedService";

const Dashboard = ({}) => {
  const { t } = useTranslation();
  const [dashboardResponse, setDashboardResponse] = useState(null);

  const { isAdmin, isVolunteer, isRequester } = useOutletContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (isVolunteer) {
      getVolunteerDashboard();
    }
    if (isAdmin) {
      getAdminDashboard();
    }
    if (isRequester) {
      getRequesterDashboard();
    }
    // eslint-disable-next-line
  }, []);

  function getVolunteerDashboard() {
    SharedService.ShowLoader();

    HomeService.GetVolunteerDashboard().then((res) => {
      SharedService.HideLoader();

      if (res.data.data) {

        setDashboardResponse(res.data.data);
      }
    });
  }

  function getAdminDashboard() {
    SharedService.ShowLoader();

    HomeService.GetAdminDashboard().then((res) => {
      SharedService.HideLoader();

      if (res.data.data) {
        setDashboardResponse(res.data.data);
      }
    });
  }
  function getRequesterDashboard() {
    SharedService.ShowLoader();

    HomeService.GetRequesterDashboard().then((res) => {
      SharedService.HideLoader();

      if (res.data.data) {
        setDashboardResponse(res.data.data);
      }
    });
  }
  const getPercent = (fraction) => {
    const [numerator, denominator] = fraction.split('/');
    return numerator / denominator * 100;
  };
  return (
    <>
      <div className="container mt-3">
        <div className="row">
          <div className="col-12 text-uppercase grey font-12">
            {t("overview")}
          </div>
          <div className="row justify-content-between">
            <div className="col-6 col-md-3 title mt-2">{t("My Dashboard")}</div>
            {isRequester && (
              <div className="col-12 col-md-3 title mt-2">
                <Button
                  label={t("Create New Project")}
                  className="btn btn-blue w-75"
                  type="button"
                  onClick={() => {
                    navigate("/Projects/new");
                  }}
                />
              </div>
            )}
          </div>
        </div>

        {isVolunteer && dashboardResponse && (
          <div className="row mt-3 justify-content-md-start justify-content-center">
            <div className="col-6 col-md-4 col-xl-3 m-2 box blue-box">
              <div className="font-20 mb-2">{dashboardResponse.totalhours}</div>
              <div>{t("Hours Logged")}</div>
              <div>{t("In The Last Month")}</div>
            </div>
            <div className="col-6 col-md-4 col-xl-3 m-2  box blue-box">
              <div className="font-20 mb-2">
                {dashboardResponse.totalworkedonprojects}
              </div>
              <div>{t("Projects Worked On")}</div>
              <div>{t("In The Last Month")}</div>
            </div>

            <div className="row mt-3 justify-content-md-start justify-content-center">
              <div className="col-6 col-md-4 col-xl-3 m-2  font-20 box blue-box justify-content-center">
                <div>{t("Welcome Back!")}</div>
                <div>{AuthService.getName()}</div>
              </div>
              {/* <div className="col-6 col-md-4 col-xl-3 m-2 font-20  box white-box  justify-content-center">
                <div className="row grey">
                <div className="col-6">
                  <div>930</div>
                  <div>{t("Hours")}</div>
                  <div className="fa fa-clock"></div>
                </div>
                <div className="col-6 ">
                  <div>82</div>
                  <div>{t("Projects")}</div>
                  <div className="fa fa-check"></div>
                </div>
              </div>
              </div> */}
              <div className="row mt-3">
                <div className="col-12 task-title">
                  {t("Today Task Update")}
                </div>
              </div>

              <div className="row">
                <div className="col-12 ">
                  <div className="row font-16">
                    <div className="col-12 box white-box">
                      <div className="task-title">{t("Pending Tasks")}</div>
                      <div className="orange">
                        {dashboardResponse.pendingtask}
                      </div>
                      <div className=" w-100 text-end">
                        <i
                          className="fa fa-gear"
                          onClick={() => {
                            navigate("/Tasks");
                          }}
                        ></i>
                      </div>
                    </div>
                    <div className="col-12 box white-box">
                      <div className="task-title">{t("Completed Tasks")}</div>
                      <div className="green">
                        {dashboardResponse.completedTasks}
                      </div>
                      <div className=" w-100 text-end">
                        <i
                          className="fa fa-gear"
                          onClick={() => {
                            navigate("/Tasks");
                          }}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
                {dashboardResponse.tasksList.map((rowData, i) => {
                  return <Task task={rowData} key={`task + ${i}`} />;
                })}
              </div>
            </div>
          </div>
        )}
        {isAdmin && dashboardResponse && (
          <div className="row mt-3 justify-content-md-start justify-content-center">
            <div className="row mt-3 justify-content-md-start justify-content-center">
              <div className="col-6 col-md-4 col-xl-3 m-2  font-20 box blue-box justify-content-center">
                <div>{t("Welcome Back!")}</div>
                <div>{AuthService.getName()}</div>
              </div>
              <div className="row">
                <div className="col-12 ">
                  <div className="row font-16">
                    <div className="col-12 col-md-4 col-xl-3 box white-box">
                      <div className="task-title">{t("Ongoin Projects")}</div>
                      <div className="orange">
                        {dashboardResponse.ongoingproject}
                      </div>
                      <div className=" w-100 text-end">
                        <i
                          className="fa fa-gear"
                          onClick={() => {
                            navigate("/Projects/List");
                          }}
                        ></i>
                      </div>
                    </div>
                    <div className="col-12 col-md-4 col-xl-3 box white-box">
                      <div className="task-title">
                        {t("Completed Projects")}
                      </div>
                      <div className="green">
                        {dashboardResponse.completedprojects}
                      </div>
                      <div className=" w-100 text-end">
                        <i
                          className="fa fa-gear"
                          onClick={() => {
                            navigate("/Projects/List");
                          }}
                        ></i>
                      </div>
                    </div>
                    <div className="col-12 col-md-4 col-xl-3 box white-box">
                      <div className="task-title">
                        {t("Unpublished Projects")}
                      </div>
                      <div className="orange">
                        {dashboardResponse.unpublishedproject}
                      </div>
                      <div className=" w-100 text-end">
                        <i
                          className="fa fa-gear"
                          onClick={() => {
                            navigate("/Projects/List");
                          }}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 ">
                  <div className="row font-16">
                    <div className="col-12 col-md-4 col-xl-3 box white-box">
                      <div className="text-center admin-label">
                        {dashboardResponse.totalprojects}
                      </div>
                      <div className="text-center admin-value">
                        {t("Projects")}
                      </div>
                    </div>
                    <div className="col-12 col-md-4 col-xl-3 box white-box">
                      <div className="text-center admin-label">
                        {dashboardResponse.totalvolunteer}
                      </div>
                      <div className="text-center admin-value">
                        {t("Volunteers")}
                      </div>
                    </div>
                    <div className="col-12 col-md-4 col-xl-3 box white-box">
                      <div className=" text-center admin-label">
                        {dashboardResponse.totalTasks}
                      </div>
                      <div className="text-center admin-value">
                        {t("Tasks")}
                      </div>
                    </div>
                    <div className="col-12 col-md-4 col-xl-3 box white-box">
                      <div className=" text-center admin-label">
                        {dashboardResponse.totalHours}
                      </div>
                      <div className="text-center admin-value">
                        {t("Hours")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isRequester && dashboardResponse && (
          <div className="row mt-3 justify-content-md-start justify-content-center">
            <div className="row mt-3 justify-content-md-start justify-content-center">
              <div className="col-6 col-md-4 col-xl-3 m-2  font-20 box blue-box justify-content-center">
                <div>{t("Welcome Back!")}</div>
                <div>{AuthService.getName()}</div>
              </div>
              <div className="col-6 col-md-4 col-xl-3 m-2  justify-content-center">
                <div className="row">
                  <div className="col-12 col-md-4 col-xl-3 box white-box">
                    <div className="text-center admin-label">
                      {dashboardResponse.totalprojects}
                    </div>
                    <div className="col-12 text-uppercase grey font-12 text-center">
                      {t("Projects")}
                    </div>
                    <div className="text-center">
                      <i className="fa fa-check"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 ">
                  <div className="row font-16">
                    <div className="col-12 col-md-4 col-xl-3 box white-box">
                      <div className="task-title">{t("Pending Projects")}</div>
                      <div className="orange">
                        {dashboardResponse.pendingproject}
                      </div>
                      <div className=" w-100 text-end">
                        <i
                          className="fa fa-gear"
                          onClick={() => {
                            navigate("/Projects/List");
                          }}
                        ></i>
                      </div>
                    </div>
                    <div className="col-12 col-md-4 col-xl-3 box white-box">
                      <div className="task-title">
                        {t("Completed Projects")}
                      </div>
                      <div className="green">
                        {dashboardResponse.completedprojects}
                      </div>
                      <div className=" w-100 text-end">
                        <i
                          className="fa fa-gear"
                          onClick={() => {
                            navigate("/Projects/List");
                          }}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 ">
                  <div className="row ">
                    {dashboardResponse.projectlist.map((rowData, i) => {
                      return (
                        <div
                          className="col-12 col-md-4 col-xl-3 box white-box"
                          key={`project${rowData.id}`}
                        >
                          <div className=" w-100 text-end">
                            <i
                              className="fa fa-eye pointer"
                              onClick={() => {
                                navigate(
                                  `/Projects/new?MODE=${FormMode.VIEW}&ID=${rowData.id}`
                                );
                              }}
                            ></i>
                          </div>
                          <div className="task-title">{rowData.name}</div>
                          <>
                            <div className="mb-2">
                              <small className="small task-title">
                                {t("Tasks Completed")}
                              </small>
                              <small className="small  mx-2">
                                {rowData.taskcompleted}
                              </small>
                            </div>
                            <ProgressBar
                              value={getPercent(rowData.taskcompleted)}
                              showValue={false}
                              style={{ height: "6px" }}
                              color={getPercent(rowData.taskcompleted) >=100 ? "#0DCD94" : "#F34932"}
                            ></ProgressBar>
                          </>
                          <>
                            <div className="mb-2 mt-2">
                              <small className="task-title small">
                                {t("KPIs Completed")}
                              </small>
                              <small className="small mx-2">
                                {rowData.taskcompleted}
                              </small>
                            </div>
                            <ProgressBar
                              value={getPercent(rowData.kpiScompleted)}
                              showValue={false}
                              style={{ height: "6px" }}
                              color={getPercent(rowData.kpiScompleted) >=100 ? "#0DCD94" : "#F34932"}
                            ></ProgressBar>
                          </>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Dashboard;
