export const URLEnum = {
    Dashboard: 1,
    Projects: 2,
    Volunteer: 3,
    Settings: 4,
    Kpis: 5,
    Task: 6,
    Certificate: 7,
    Users:8,
    Slider:9,
    News:10,
    WhatsNews:11,
    Reports:12,



  };
  