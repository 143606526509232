import { useTranslation } from "react-i18next";
import React, { useState, useEffect, useContext } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { useFormik } from "formik";
import * as yup from "yup";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import moment from "moment";
import { UserService } from "../../shared/Services/UserService";
import { SharedService } from "../../shared/Services/sharedService";
import { MessageTypeConstant } from "../../shared/constant/messageTypeConstant";
import { useNavigate  } from "react-router-dom";
import { CMSService } from "../../shared/Services/CMSService";


const AddWhatsNew = () => {
  let query = new URLSearchParams(window.location.search);
  let id = +query.get("ID");
  const navigate = useNavigate();

  const { t } = useTranslation();
const [status, setStatus] = useState([]);
const [isTops, setIsTops] = useState([]);

const [rules, setRules] = useState([]);
const [item, setItem] = useState(null);


 

function getbyId(id){
  SharedService.ShowLoader();

  CMSService.GetWhatsNewById(id).then((res) => {
    if (res.data.data) {
      SharedService.HideLoader();

      setItem(res.data.data);
    }
  });

}
  useEffect(() => {
    if(id){
      getbyId(id);
    }

    FillStatus();
    FillIsTop();
  }, []);


function FillStatus() {
  setStatus([
    { name: t("Active"), code: true },
    { name: t("In Active"), code: false },
  ]);
}


function FillIsTop() {
  setIsTops([
    { name: t("Yes"), code: true },
    { name: t("No"), code: false },
  ]);
}

  const formik = useFormik({

    initialValues:  {
      Id:item ? item.id : 0,
      TitleAr:item ? item.titleAr :"",
      TitleEn:item ? item.titleEn :"",
      DescEn:item ? item.descEn :"",
      DescAr:item ? item.descAr :"",
      IsActive:!item ? true : item?.isActive,
      IsTop:item?.isTop ? true :false,

    },
    validationSchema: yup.object().shape({

      TitleEn: yup.string().required(t("Required Field")),
      TitleAr: yup.string().required(t("Required Field")),
      DescEn: yup.string().required(t("Required Field")),
      DescAr: yup.string().required(t("Required Field")),
 

   
  }),
    enableReinitialize: true,

    onSubmit: (data) => {
      SharedService.ShowLoader();

      CMSService.CreateOrUpdateWhatsNew(data).then((res) => {
        if (res.data.errorCode === 0) {
          SharedService.HideLoader();

          SharedService.ShowToast(t("News Added Successfully"),MessageTypeConstant.Success);
             navigate("/CMS/WhatsNew")

        }
      });
      
    
    },
  });

  return (
    <>
      <div >
        {" "}
        <form onSubmit={formik.handleSubmit} className="p-fluid">
          <div className="row">
            <div className="col-12 mt-1 text-center ">
              <span className="text-start">
                <label htmlFor="TitleAr" className="d-inline-block mt-4  w-100">
                  {t("Title Ar")}
                </label>
                <InputText
                  id="TitleAr"
                  name="TitleAr"
                  className="w-100"
                  value={formik.values.TitleAr}
                  onChange={formik.handleChange}
               
                />
                {formik.touched.TitleAr && formik.errors.TitleAr && (
                  <label className="errorMessage w-100">
                    {formik.errors.TitleAr}
                  </label>
                )}
              </span>
            </div>
            <div className="col-12 mt-1 text-center ">
              <span className="text-start">
                <label htmlFor="TitleEn" className="d-inline-block mt-4  w-100">
                  {t("Title En")}
                </label>
                <InputText
                  id="TitleEn"
                  name="TitleEn"
                  className="w-100"
                  value={formik.values.TitleEn}
                  onChange={formik.handleChange}
                
                />
                {formik.touched.TitleEn && formik.errors.TitleEn && (
                  <label className="errorMessage w-100">
                    {formik.errors.TitleEn}
                  </label>
                )}
              </span>
            </div>
            <div className="col-12 mt-1 text-center ">
              <span className="text-start">
                <label htmlFor="DescAr" className="d-inline-block mt-4  w-100">
                  {t("Description Ar")}
                </label>
                <InputText
                  id="DescAr"
                  name="DescAr"
                  className="w-100"
                  value={formik.values.DescAr}
                  onChange={formik.handleChange}
               
                />
                {formik.touched.DescAr && formik.errors.DescAr && (
                  <label className="errorMessage w-100">
                    {formik.errors.DescAr}
                  </label>
                )}
              </span>
            </div>
            <div className="col-12 mt-1 text-center ">
              <span className="text-start">
                <label htmlFor="DescEn" className="d-inline-block mt-4  w-100">
                  {t("Description En")}
                </label>
                <InputText
                  id="DescEn"
                  name="DescEn"
                  className="w-100"
                  value={formik.values.DescEn}
                  onChange={formik.handleChange}
                
                />
                {formik.touched.DescEn && formik.errors.DescEn && (
                  <label className="errorMessage w-100">
                    {formik.errors.DescEn}
                  </label>
                )}
              </span>
            </div>
       
           
          
        

          
            <div className="col-12 mt-1 text-center">
              <span className="text-start">
                <label
                  htmlFor="IsTop"
                  className="d-inline-block mt-2  w-100"
                >
                  {t("Is Top")}
                </label>
                <Dropdown
                 
                  optionLabel="name"
                  id="IsTop"
                  value={
                    isTops[
                      isTops.findIndex(
                        (obj) => +obj.code === +formik.values.IsTop
                      )
                    ]
                  }
                  options={isTops}
                  onChange={(e) => {
                    debugger;
                    formik.setFieldValue("IsTop", e.value.code);
                  }}
                  className={`w-100`}
                  placeholder={t("select")}
                />

                {formik.touched.IsTop && formik.errors.IsTop && (
                  <label className="errorMessage w-100">
                    {formik.errors.IsTop}
                  </label>
                )}
              </span>
            </div>
            <div className="col-12 mt-1 text-center">
              <span className="text-start">
                <label
                  htmlFor="IsActive"
                  className="d-inline-block mt-2  w-100"
                >
                  {t("Status")}
                </label>
                <Dropdown
                 
                  optionLabel="name"
                  id="IsActive"
                  value={
                    status[
                      status.findIndex(
                        (obj) => +obj.code === +formik.values.IsActive
                      )
                    ]
                  }
                  options={status}
                  onChange={(e) => {
                    debugger;
                    formik.setFieldValue("IsActive", e.value.code);
                  }}
                  className={`w-100`}
                  placeholder={t("select")}
                />

                {formik.touched.IsActive && formik.errors.IsActive && (
                  <label className="errorMessage w-100">
                    {formik.errors.IsActive}
                  </label>
                )}
              </span>
            </div>
          
         
        

         
            <div className="col-12 mt-4 text-center">
              <Button
                label={t("Save")}
                className="btn btn-blue  w-100"
                type="submit"
              />
            </div>
          
          </div>
        </form>
      </div>
    </>
  );
};

export default AddWhatsNew;
