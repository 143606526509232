import { React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Button } from "primereact/button";
import { Steps } from "primereact/steps";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useFormik } from "formik";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { ProgressBar } from 'primereact/progressbar';
import { HomeService } from "../../shared/Services/HomeService";
import moment from "moment";
import { ProjectService } from "../../shared/Services/ProjectService";
import AppConsts from "../../shared/constant/appConsts";
import { FormMode } from "../../shared/constant/FormMode";
import { ProjectStatusEnum } from "../../shared/constant/ProjectStatusEnum";
import { SharedService } from "../../shared/Services/sharedService";
import { MessageTypeConstant } from "../../shared/constant/messageTypeConstant";
import {Dialog} from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";

import * as yup from "yup";
const ProjectsList = ({}) => {
  const [showDialog, setShowDialog] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [toCancelId, setToCancelId] = useState(null);

  const { t } = useTranslation();
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [projectStatus, setProjectStatus] = useState([]);

  const { isAdmin, isVolunteer, isRequester } = useOutletContext();
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
  });

  function fillDepartments(){
 
    HomeService.getDepartmentLookups().then((res) => {
      if (res.data.errorCode === 0) {
        let options = res.data.data.map((x) => {
          return { name: x.nameEn , code: x.id };
        });
        setDepartments(options);
      }
    });
  
}
  function initializeColumns() {
    setColumns([
      { field: "id", header: t("#ID"),  },
      { field: "nameEn", header: t("Project Title") },

      { field: "client", header: t("Client") },
      {
        field: "volunteers",
        header: t("Volunteers"),
        hidden: activeIndex !==0
      },
     
      { field: "departmentNameEn", header: t("Department") },
      { field: "fromDate", header: t("Start Date"),
      body: startDateBodyTemplate,
    },
      { field: "toDate", header: t("Dead Line"),
      body: endDateBodyTemplate,
    },

      {
        field: "workingProgress",
        header: t("Work Progress"),
        body: progressBodyTemplate,
        hidden: activeIndex !==0 


      },
      {
        field: "status",
        header: t("Status"),
        body: statusBodyTemplate,
      },
      {
        field: "rejectionReason",
        header: t("Rejection Reason"),
        hidden: activeIndex !==5
      },
      {
        field: "actions",
        header: t("Actions"),
        body: actionsBodyTemplate,
      },
      {
        field: "AssignActions",
        header: t("Assign Volunteers"),
        body: assignBodyTemplate,
        hidden: activeIndex !== ProjectStatusEnum.PendingVolunteerAssigment 
        &&  activeIndex !== ProjectStatusEnum.OnGoing 

      },
    ]);
  }
  const actionsBodyTemplate = (rowData) => {
    return (
      <>
        <span
          className="actionList "
          onClick={() => {
            navigate(`/Projects/new?MODE=${FormMode.VIEW}&ID=${rowData.id}`);
          }}
        >
          <i role="button" className="fa fa-eye" aria-hidden="true" title={t("View")} ></i>

        </span>
        {isRequester &&
          rowData.statusId === ProjectStatusEnum.PendingApproval && (
            <span
              className="actionList"
              onClick={() => {
                navigate(
                  `/Projects/new?MODE=${FormMode.EDIT}&ID=${rowData.id}`
                );
              }}
            >
              <i role="button" className="fa fa-pencil" aria-hidden="true"></i>
            </span>
          )}
        {isAdmin && rowData.statusId === ProjectStatusEnum.PendingApproval && (
          <span
            className="actionList"
            onClick={() => {
              navigate(
                `/Projects/new?MODE=${FormMode.STATUS}&ID=${rowData.id}`
              );
            }}
          >
            <i role="button" className="fa fa-pencil" aria-hidden="true" title={t("View")}></i>
          </span>
        )}
        {isAdmin &&
          rowData.statusId === ProjectStatusEnum.PendingVolunteerAssigment && (
            <span
              className="actionList"
              onClick={() => {
                SharedService.ShowLoader();

                ProjectService.startProject(rowData.id).then((res) => {
                  SharedService.HideLoader();

                  if (res.data.errorCode === 0) {
                    SharedService.ShowToast(t("Project Started Succesfully"),MessageTypeConstant.Success);
                    formik.setFieldValue("status",rowData.index);
    
                    setActiveIndex(3);
                  }
                });
            
            
              }}
            >
              <i role="button" className="fa fa-circle-play" aria-hidden="true" title={t("Start")}></i>
            </span>
          )}
            {isAdmin &&
          rowData.statusId === ProjectStatusEnum.OnGoing && (
            <>
            <span
              className="actionList"
              onClick={() => {
                SharedService.ShowLoader();

                ProjectService.completeProject(rowData.id).then((res) => {
                  SharedService.HideLoader();

                  if (res.data.errorCode === 0) {
                    SharedService.ShowToast(t("Project Completed Succesfully"),MessageTypeConstant.Success);
                    formik.setFieldValue("status",rowData.statusId);
    
                    setActiveIndex(1);
                  }
                });
            
            
              }}
            >
              <i role="button" className="fa fa-circle-check" aria-hidden="true" title={t("Complete")}></i>
            </span>
              <span
              className="actionList"
              onClick={() => {
                deleteFormik.handleReset();
                setToCancelId(rowData);
                setShowCancelDialog(true);
              }}
            >
              <i role="button" className="fa fa-stop" aria-hidden="true" title={t("Cancel")}></i>
            </span>
            </>
          )}
          
       
          

            <span
              className="actionList"
              onClick={() => {
                navigate(
                  `/Projects/Overview/${rowData.id}`
                );
              }}
            >
              <i role="button" className="fa fa-arrow-right" aria-hidden="true" title={t("Overview")}></i>
            </span>

            {isAdmin && rowData.statusId === ProjectStatusEnum.OnGoing && (
          <span
            className="actionList"
            onClick={() => {
              timeFormik.setFieldValue("id", rowData.id);
              setShowDialog(true)
                        }}
          >
            <i role="button" className="fa fa-clock" aria-hidden="true" title={t("Extend")}></i>
          </span>
        )}

    
      </>
    );
  };

  function extendTime(){
    debugger;
    let model = {
      id:timeFormik.values.id,
      toDate:timeFormik.values.toDate
    }
    SharedService.ShowLoader();

    ProjectService.ExtendProject(model).then((res) => {
      SharedService.HideLoader();

      if (res) {
        SharedService.ShowToast(t("Project Date Updated Succesfully"),MessageTypeConstant.Success);
        formik.setFieldValue("status",3);
setShowDialog(false);
        setActiveIndex(3);
      }
    });
  }
  const assignBodyTemplate = (rowData) => {
    return (
      <>
        <Button
                  label={t("Assign Volunteers")}
                  icon="pi pi-plus"
                  className="btn btn-blue  w-100 mt-1"
                  type="submit"
                  onClick={()=>{
                    navigate(`/Projects/new?MODE=${FormMode.ASSIGN}&ID=${rowData.id}`);
           
                   }}
                />
    

      </>
    );
  };
  const progressBodyTemplate = (rowData) => {
    let color = "#F34932"
    switch (rowData.status) {
      case 1:
      color =  "#F34932";
          break;
      case 2:
        color =  "#E3B113";
          break;
      case 3:
        color =  "#F34932";
          break;
      case 4:
        color =  "#F7284A";
          break;
          case 5:
            color =  "#0DCD94";
              break;
          default:
            color = '#F34932';
          break;
  }
    return (
      <>
      <div className="mb-2">
        <small className="small">{t("Project Status")}</small>
        <small className="small mx-2">{rowData.workingProgress + '%'}</small>

      </div>
       <ProgressBar value={rowData.workingProgress}  showValue={false}  style={{ height: '6px' }} color={color}></ProgressBar>

      </>
    );
  };
  const startDateBodyTemplate = (rowData) => {
    return (
      <>
 { moment(new Date( rowData.fromDate)).format("YYYY-MM-DD")}
      </>
    );
  };
  const endDateBodyTemplate = (rowData) => {
    return (
      <>
   { moment(new Date( rowData.toDate)).format("YYYY-MM-DD")}

      </>
    );
  };
  const statusBodyTemplate = (rowData) => {
    return (
      <>
 
          <span className={`status-shadow status-${rowData.statusId}`}>
            {rowData.statusText}
          </span>
        
   
      </>
    );
  };

  function getProjectCount(){
    SharedService.ShowLoader();

    ProjectService.GetProjectStatusCount().then((res) => {
      SharedService.HideLoader();

      if (res) {
        setProjectStatus(res.data.data);
      }
    });
  
}

  function fillDataTable(data,reset,status){
    if(status){
      data.status= status;
    }
    if(reset){
      formik.setFieldValue("pageCount", 1);
      formik.setFieldValue("pageNumber", 1);
      data.pageCount = 1;
      data.pageNumber = 1;
      setLazyParams({
        first:0,
        rows:AppConsts.pageSize,
        page:1,
        sortField:null,
        sortOrder:null
      })
    }
    SharedService.ShowLoader();

    ProjectService.list(data).then((res) => {
      SharedService.HideLoader();

      if (res.data.errorCode === 0) {
        setTotalRecords(res.data.data.totalFilteredRecords);
        setRows(res.data.data.data);
      }
    });
  }

  const deleteFormik = useFormik({
    initialValues: {
      reason: "",
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      reason: yup.string().required(t("Required Field")),
    }),
    onSubmit: (data) => {
      SharedService.ShowLoader();

      ProjectService.reject(toCancelId.id, data.reason).then((res) => {
        SharedService.HideLoader();

        if (res.data.errorCode === 0) {
          SharedService.ShowToast(
            t("Project Canceled Successfully"),
            MessageTypeConstant.Success
          );
          formik.setFieldValue("status",toCancelId.statusId);
    setShowCancelDialog(false);
          setActiveIndex(1);        }
      });
    },
  });

  const timeFormik = useFormik({
    initialValues: {
      toDate: "",
      id:""
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      toDate: yup.string().required(t("Required Field")),
    }),
    onSubmit: (data) => {
     extendTime();
    },
  });

  const onPageChanged = (e) => {
   setLazyParams(e);
    formik.setFieldValue("pageCount", e.page + 1);
    formik.setFieldValue("pageNumber", e.page + 1);
    formik.values.pageCount = e.page + 1;
    formik.values.pageNumber = e.page + 1;


fillDataTable(formik.values);
  


  };
  const dynamicColumns = columns.map((col) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        body={col.body}
        hidden={col.hidden}
      />
    );
  });
  const formik = useFormik({
    initialValues: {
      from: undefined,
      to: undefined,
      deparment: undefined,
      status:0,
      pageNumber: 1,
      pageCount: 1,
      pageSize: AppConsts.pageSize,
    },
    enableReinitialize: true,

    onSubmit: (data) => {
      fillDataTable(data,true)
    },
  });
  const TabsTemplate = (rowData) => {
    return (
      <>
        <div
          className={`col-3 col-md-6 justify-content-center ${
            activeIndex === rowData.index ? "active" : ""
          }`}
        >
          <div>{rowData.total}</div>
          <div>{rowData.label}</div>

          <div></div>
        </div>
      </>
    );
  };
  const tabsItem = [
    {
      label: t("Total Projects"),
      total: projectStatus.reduce((acc, item) => acc + item.count, 0),
      template: TabsTemplate,
      index: 0,

      command: (event) => {},
    },
    {
      label: t("Pending Approval Porjects"),
      total:  projectStatus[projectStatus.findIndex((obj) => obj.status === 1)]?.count,
      command: (event) => {},
      index: 1,
      template: TabsTemplate,
    },
    {
      label: t("Ongoing Porjects"),
      command: (event) => {},
      total:  projectStatus[projectStatus.findIndex((obj) => obj.status === 2)]?.count,

      index: 2,
      template: TabsTemplate,
    },
    {
      label: t("Projects Pending Volunteer Assignment"),
      command: (event) => {},
      index: 3,
      total:  projectStatus[projectStatus.findIndex((obj) => obj.status === 3)]?.count,

      template: TabsTemplate,
    },
    {
      label: t("Completed Projects"),
      command: (event) => {},
      index: 4,
      total:  projectStatus[projectStatus.findIndex((obj) => obj.status === 4)]?.count,

      template: TabsTemplate,
    },
    {
      label: t("Canceled Projects"),
      command: (event) => {},
      index: 5,
      total:  projectStatus[projectStatus.findIndex((obj) => obj.status === 5)]?.count,

      template: TabsTemplate,
    },
  ];

  useEffect(() => {
    getProjectCount();
    fillDepartments();
    initializeColumns();
    fillDataTable(formik.values);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    initializeColumns();
    fillDataTable(formik.values,true,activeIndex)

    // eslint-disable-next-line
  }, [activeIndex]);


  return (
    <>
      <Dialog
        header={t("Cancel Project")}
        visible={showCancelDialog}
        style={{ minWidth: "50vw", minHeight: "50vh" }}
        onHide={() => {
          setShowCancelDialog(false);
          deleteFormik.handleReset();
        }}
      >
        <form onSubmit={deleteFormik.handleSubmit} className="p-fluid">
          <div className="row">
            <div className="col-12 mt-1 text-center ">
              <span className="text-start">
                <label htmlFor="reason" className="d-inline-block mt-4  w-100">
                  {t("Cancel Reason")}
                </label>
                <InputTextarea

                  id="reason"
                  name="reason"
                  rows={5}
                  cols={10}
                  value={deleteFormik.values.reason}
                  onChange={deleteFormik.handleChange}
                  placeholder={t("Write down your Comments")}
                />

                {deleteFormik.touched.reason && deleteFormik.errors.reason && (
                  <label className="errorMessage w-100">
                    {deleteFormik.errors.reason}
                  </label>
                )}
              </span>
            </div>
            <div className="col-12 mt-4 text-center">
              <Button
                label={t("Submit")}
                className="btn btn-blue  w-50"
                type="submit"
              />
            </div>
            


          </div>
        </form>
      </Dialog>
    <Dialog
      header={t("Extend Project")}
      visible={showDialog}
      style={{minWidth:"20vw", minHeight:"40vh"}}
      onHide={
        ()=>{
          setShowDialog(false);
          timeFormik.handleReset();
        }
      }
>
<form onSubmit={timeFormik.handleSubmit} className="p-fluid">
          <div className="row">
            
          <div className="col-12 mt-1">
              <span className="text-start">
                <label htmlFor="toDate" className="d-inline-block mt-2  w-100">
                  {t("To")}
                </label>
                <Calendar
                  id="toDate"
                  className={`block w-100`}
                  name="toDate"
                  value={timeFormik.values.toDate ? new Date(timeFormik.values.toDate) : ""}
                  onChange={(e) => {
                    if (e.value) {
                      timeFormik.setFieldValue(
                        "toDate",
                        moment(new Date(e.value)).format("YYYY-MM-DD")
                      );
                    } else {
                      timeFormik.setFieldValue("toDate", null);
                    }
                  }}
                  showIcon
                />
                {timeFormik.touched.toDate && timeFormik.errors.toDate && (
                  <label className="errorMessage w-100">
                    {timeFormik.errors.toDate}
                  </label>
                )}
              </span>
            </div>
              <div className="col-12 mt-4 mb-5 ">
                <Button
                  label={t("Submit")}
                  className="btn btn-blue  w-100"
                  type="submit"
                />
              </div>
          

          
          </div>
        </form>
    </Dialog>
      <div className="container mt-3 mb-5 ">
        <div className="row justify-content-between">
          <div className="col-12 col-md-3 title">{t("Projects List")}</div>
          <div className="col-12 col-md-3 title">
            {isRequester && (  <Button
              label={t("Create New Project")}
              className="btn btn-blue w-75"
              type="button"
              onClick={() => {
                navigate("/Projects/new");
              }}
            />)}
          
          </div>
        </div>
        <div className="row mt-4">
          {tabsItem.map((rowData, i) => {
            return (
              <div
                className={`col-md-2 col-6 mt-2 text-center tab-box py-4 pointer ${
                  "tab-box" + rowData.index
                } ${activeIndex === rowData.index ? "active" : ""}`}
                onClick={() => {
                  formik.setFieldValue("status",rowData.index);

                  setActiveIndex(rowData.index);

                }}
                key={"tab" + rowData.index}
              >
                <span className="number">{rowData.total}</span>
                <div className="label">{rowData.label}</div>

                <div></div>
              </div>
            );
          })}
        </div>
        <div className="row mt-5">
          <div className="col-12 navy-blue">{t("Recent Porject Summary")}</div>
        </div>
        <div className="row mt-4">
          <form onSubmit={formik.handleSubmit} className="p-fluid">
            <div className="row">
              <div className="col-6 col-md-3">
                <label htmlFor="from" className="d-inline-block mt-2  w-100">
                  {t("From")}
                </label>
                <Calendar
                  id="from"
                  className={`block w-100`}
                  name="from"
                  value={formik.values.from ?new Date(formik.values.from) : undefined}
                  onChange={(e) => {
                    if(e.value){
                      formik.setFieldValue("from", moment(new Date( e.value)).format("YYYY-MM-DD"));

                    }else{
                      formik.setFieldValue("from", undefined);

                    }
                  }}
                  showIcon
                />
                {formik.touched.from && formik.errors.from && (
                  <label className="errorMessage w-100">
                    {formik.errors.from}
                  </label>
                )}
              </div>
              <div className="col-6 col-md-3">
                <label htmlFor="to" className="d-inline-block mt-2">
                  {t("To")}
                </label>
                <Calendar
                  id="to"
                  className={`block w-100`}
                  name="to"
                  value={formik.values.to ?new Date(formik.values.to) : undefined}
                  onChange={(e) => {
                    if(e.value){
                      formik.setFieldValue("to", moment(new Date( e.value)).format("YYYY-MM-DD"));

                    }else{
                      formik.setFieldValue("to", undefined);

                    }
                  }}
                  showIcon 
                />
                {formik.touched.to && formik.errors.to && (
                  <label className="errorMessage w-100">
                    {formik.errors.to}
                  </label>
                )}
              </div>
              <div className="col-6 col-md-3 ">
                <label
                  htmlFor="Deparment"
                  className="d-inline-block mt-2  w-100"
                >
                  {t("Department")}
                </label>
                <Dropdown
                showClear
                  optionLabel="name"
                  id="deparment"
                  value={
                    departments[
                      departments.findIndex(
                        (obj) => obj.code === formik.values.deparment
                      )
                    ]
                  }
                 
                  options={departments}
                  onChange={(e) => {
                    debugger;
                    formik.setFieldValue("deparment", e?.value?.code);
                  }}
                  className={`w-100`}
                  placeholder={t("select")}
                />
              </div>

              <div className="col-6 col-md-3 ">
                <label
                  htmlFor="deparment"
                  className="d-inline-block mt-2  w-100"
                >
                  {t("")}
                </label>
                <Button
                  label={t("Search")}
                  className="btn btn-blue  w-100 mt-1"
                  type="submit"
                />
              </div>
            </div>
          </form>
        </div>
        <div className="row mt-5 mb-5 table-div">
          <DataTable
            rows={AppConsts.pageSize}
            paginator
            paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
            value={rows}
            responsiveLayout="scroll"
            stripedRows
            className="centeredColumnContent"
            lazy={true}
            first={lazyParams.first}
            totalRecords={totalRecords}
            onPage={onPageChanged}
            emptyMessage={t("No Records")}
          >
            {dynamicColumns}
          </DataTable>
        </div>
    

      </div>
    </>
  );
};

export default ProjectsList;
