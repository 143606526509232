import { useTranslation } from "react-i18next";
import React, { useState, useEffect, useContext } from "react";
import { FileUpload } from "primereact/fileupload";
import { InputTextarea } from "primereact/inputtextarea";
import { useFormik } from "formik";
import * as yup from "yup";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { useNavigate  } from "react-router-dom";
import { SharedService } from "../../../shared/Services/sharedService";
import { WizardDataContext } from "../../../Contexts/WizardDataContext";
import { ProjectService } from "../../../shared/Services/ProjectService";
import { MessageTypeConstant } from "../../../shared/constant/messageTypeConstant";
import { FormMode } from "../../../shared/constant/FormMode";
import {Dialog} from "primereact/dialog";
const Step3 = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
const [showConfirm, setShowConfirm] = useState(false);
   const { wizardData, setWizardData, setActiveIndex, setStep3, step3,step2,step1 } =
    useContext(WizardDataContext);

  useEffect(() => {}, []);

  const deleteFormik = useFormik({
    initialValues: {
      reason: "",
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      reason: yup.string().required(t("Required Field")),
    }),
    onSubmit: (data) => {
      SharedService.ShowLoader();

      ProjectService.reject(wizardData.id, data.reason).then((res) => {
        SharedService.HideLoader();

        if (res.data.errorCode === 0) {
          SharedService.ShowToast(
            t("Project Declined Successfully"),
            MessageTypeConstant.Success
          );
          navigate("/Projects/List");
        }
      });
    },
  });
  const formik = useFormik({
    initialValues: step3 ?step3 :{
      SpecialRequirment:"",
      Comment:"",
      accept:wizardData.mode !== FormMode.NEW

    },
    validationSchema: yup.object().shape({
      SpecialRequirment: yup.string().required(t("Required Field")),
      Comment: yup.string().required(t("Required Field")),
      accept: yup
      .boolean()
      .test(
        "grantPermission",
        t("Required Field"),
        (value) => value === true
      ),
  }
  ),
    enableReinitialize: true,

    onSubmit: (data) => {


      setStep3(data);
      Object.assign(data, step1);
      Object.assign(data, step2);
      SharedService.ShowLoader();

      ProjectService.createOrUpdate(data).then((res) => {
        SharedService.HideLoader();

        if (res.data.errorCode === 0) {
          SharedService.ShowToast(t("Project Added Successfully"),MessageTypeConstant.Success);
             navigate("/Projects/List")

        }
      });

    },
  });
  function approve(){
    SharedService.ShowLoader();

    ProjectService.approve(wizardData.id).then((res) => {
      SharedService.HideLoader();

      if (res.data.errorCode === 0) {
        SharedService.ShowToast(t("Project Approved Successfully"),MessageTypeConstant.Success);
           navigate("/Projects/List")
      }
    });
  }

  return (
    <>
      <Dialog
        header={t("Decline Project")}
        visible={showConfirm}
        style={{ minWidth: "50vw", minHeight: "50vh" }}
        onHide={() => {
          setShowConfirm(false);
          deleteFormik.handleReset();
        }}
      >
        <form onSubmit={deleteFormik.handleSubmit} className="p-fluid">
          <div className="row">
            <div className="col-12 mt-1 text-center ">
              <span className="text-start">
                <label htmlFor="reason" className="d-inline-block mt-4  w-100">
                  {t("Decline Reason")}
                </label>
                <InputTextarea

                  id="reason"
                  name="reason"
                  rows={5}
                  cols={10}
                  value={deleteFormik.values.reason}
                  onChange={deleteFormik.handleChange}
                  placeholder={t("Write down your Comments")}
                />

                {deleteFormik.touched.reason && deleteFormik.errors.reason && (
                  <label className="errorMessage w-100">
                    {deleteFormik.errors.reason}
                  </label>
                )}
              </span>
            </div>
            <div className="col-12 mt-4 text-center">
              <Button
                label={t("Submit")}
                className="btn btn-blue  w-50"
                type="submit"
              />
            </div>
            


          </div>
        </form>
      </Dialog>
      <div className="container">
        <form onSubmit={formik.handleSubmit} className="p-fluid">
          <div className="row">
            <div className="col-12 mt-1 text-center ">
              <span className="text-start">
                <label
                  htmlFor="SpecialRequirment"
                  className="d-inline-block mt-4  w-100"
                >
                  {t("Special Requirments")}
                </label>
                <InputTextarea
                  disabled={
                    !(
                      wizardData.mode === FormMode.NEW ||
                      wizardData.mode === FormMode.EDIT
                    )
                  }
                  id="SpecialRequirment"
                  name="SpecialRequirment"
                  rows={5}
                  cols={10}
                  value={formik.values.SpecialRequirment}
                  onChange={formik.handleChange}
                  placeholder={t("Write down your Special Requirments")}
                />

                {formik.touched.SpecialRequirment &&
                  formik.errors.SpecialRequirment && (
                    <label className="errorMessage w-100">
                      {formik.errors.SpecialRequirment}
                    </label>
                  )}
              </span>
            </div>
            <div className="col-12 mt-1 text-center ">
              <span className="text-start">
                <label htmlFor="Comment" className="d-inline-block mt-4  w-100">
                  {t("Comment")}
                </label>
                <InputTextarea
                  disabled={
                    !(
                      wizardData.mode === FormMode.NEW ||
                      wizardData.mode === FormMode.EDIT
                    )
                  }
                  id="Comment"
                  name="Comment"
                  rows={5}
                  cols={10}
                  value={formik.values.Comment}
                  onChange={formik.handleChange}
                  placeholder={t("Write down your Comments")}
                />

                {formik.touched.Comment && formik.errors.Comment && (
                  <label className="errorMessage w-100">
                    {formik.errors.Comment}
                  </label>
                )}
              </span>
            </div>
            {step3?.rejectionReason && (
              <div className="col-12 mt-1 text-center ">
                <span className="text-start">
                  <label
                    htmlFor="reason"
                    className="d-inline-block mt-4  w-100"
                  >
                    {t("Decline Reason")}
                  </label>
                  <InputTextarea
                    disabled={true}
                    id="rejectionReason"
                    name="rejectionReason"
                    rows={5}
                    cols={10}
                    value={formik.values.rejectionReason}
                    onChange={formik.handleChange}
                    />

                  {deleteFormik.touched.reason &&
                    deleteFormik.errors.reason && (
                      <label className="errorMessage w-100">
                        {deleteFormik.errors.reason}
                      </label>
                    )}
                </span>
              </div>
            )}

            <div className="col-12  mt-4 ">
              <Checkbox
                inputId={"accept"}
                name="accept"
                value={formik.values.accept}
                onChange={(e) => {
                  formik.setFieldValue("accept", e.checked);
                }}
                disabled={
                  !(
                    wizardData.mode === FormMode.NEW ||
                    wizardData.mode === FormMode.EDIT
                  )
                }
                checked={formik.values.accept}
              />
              <label htmlFor={"accept"} className="mx-2 ">
                {t("I agree to the Terms of services and Privacy policy")}
              </label>
              {formik.touched.accept && formik.errors.accept && (
                <label className="errorMessage w-100">
                  {formik.errors.accept}
                </label>
              )}
            </div>
            

            {(wizardData.mode === FormMode.NEW ||
              wizardData.mode === FormMode.EDIT) && (
              <div className="col-12 mt-4 text-center">
                <Button
                  label={t("Submit for Approval")}
                  className="btn btn-blue  w-100"
                  type="submit"
                />
              </div>
            )}

            {wizardData.mode === FormMode.STATUS &&(
              <>
                <div className="col-12 mt-4 text-center">
                  <Button
                    label={t("Approve and Publish")}
                    className="btn btn-blue  w-100"
                    onClick={approve}
                    type="button"
                  />
                </div>
                <div className="col-12 mt-4 text-center">
                  <Button
                    label={t("Decline")}
                    className="btn p-button-danger  w-100"
                    type="button"
                    onClick={() => setShowConfirm(true)}
                    
                  />
                </div>
              </>
            )}
            <div className="col-12 mt-5 text-center">
              <Button
                label={t("Back")}
                className="btn btn-primary  w-100"
                type="button"
                onClick={() => {
                  setStep3(formik.values);
                  setActiveIndex(1);
                }}
              />
            </div>
            <div className="col-12 mt-4 text-center">
              <Button
                label={t("Back To All Projects")}
                className="btn p-button-warning w-100"
                type="button"
                onClick={() => {
                  navigate("/Projects/List");
                }}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Step3;
