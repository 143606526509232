import { React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { InputTextarea } from "primereact/inputtextarea";
import { FileUpload } from "primereact/fileupload";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { ProjectService } from "../../shared/Services/ProjectService";
import { SharedService } from "../../shared/Services/sharedService";
import { MessageTypeConstant } from "../../shared/constant/messageTypeConstant";
import { InputNumber } from 'primereact/inputnumber';
import moment from "moment";

import * as yup from "yup";



const FillTask = ({}) => {
  const { t } = useTranslation();
  const { isAdmin, isVolunteer, isRequester } = useOutletContext();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const navigate = useNavigate();
  const { id, project } = useParams();
  let query = new URLSearchParams(window.location.search);
  let hours = +query.get("hours");
  let pName = +query.get("pn");
  let tName = +query.get("tn");

  const [location, setLocation] = useState({
    latitude: null,
    longitude: null,
  });

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        setLocation({ latitude, longitude });
      });
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  };

  getLocation();

  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const formik = useFormik({
    initialValues: {
      From: null,
      To: null,
      TaskDetails: "",
      Attachment: "",
      KpiValue :0
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      From: yup.date().nullable(true).required(t("Required Field")),
      To: yup.date().nullable(true).required(t("Required Field")),
      TaskDetails: yup.string().required(t("Required Field")),
      Attachment: yup.string().required(t("Required Field")),
      KpiValue : yup.number().min(1,t("Required Field") ).required(t("Required Field")),

    }),

    onSubmit: (data) => {
      setIsSubmitted(true);
    },
  });
  const formik2 = useFormik({
    initialValues: {
      Evalutaion: "",
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      Evalutaion: yup.string().required(t("Required Field")),
    }),

    onSubmit: (data) => {
      data.TaskId =id;
      Object.assign(data, formik.values);
     
      data.Lat = location.latitude;
      data.Long = location.longitude;

      SharedService.ShowLoader();

      ProjectService.fillTask(data).then((res) => {
        SharedService.HideLoader();

        if (res.data.errorCode === 0) {
          SharedService.ShowToast(
            t("Task Filled Successfully"),
            MessageTypeConstant.Success
          );
          navigate("/Tasks");
        }
      });
    },
  });
  return (
    <>
      <div className="container mt-3 p-5">
        <div className="row">
          <div className="col-12 title">
            {t("Tasks")} #{tName}
          </div>

          <div className="col-12  grey font-12">
            {t("Project")} #{pName}
          </div>
        </div>
        {!isSubmitted && (
          <div className="row mt-3">
            <form onSubmit={formik.handleSubmit} className="p-fluid">
              <div className="row">
                <div className="col-12 col-md-6">
                  <label htmlFor="From" className="d-inline-block mt-2  w-100">
                    {t("From")}
                  </label>
                  <Calendar
                    id="From"
                    hourFormat="12"
                    className={`block w-75`}
                    name="From"
                    value={formik.values.From ?new Date(formik.values.From) : null}
                    maxDate={new Date()}
                    showTime 
                    onChange={(e) => {
                      formik.setFieldValue("From", moment(e.value).format("YYYY-MM-DD HH:mm:ss"));
                    }}
                  />
                  {formik.touched.From && formik.errors.From && (
                    <label className="errorMessage w-100">
                      {formik.errors.From}
                    </label>
                  )}
                </div>
                <div className="col-12 col-md-6">
                  <label htmlFor="To" className="d-inline-block mt-2">
                    {t("To")}
                  </label>
                  <Calendar
                  showTime 
                  hourFormat="12"
                    id="To"
                    className={`block w-75`}
                    name="To"
                    value={formik.values.To ?new Date(formik.values.To) : null}
                    maxDate={new Date()}
                    onChange={(e) => {
                      formik.setFieldValue("To", moment(e.value).format("YYYY-MM-DD HH:mm:ss"));
                    }}
                  />
                  {formik.touched.To && formik.errors.To && (
                    <label className="errorMessage w-100">
                      {formik.errors.To}
                    </label>
                  )}
                </div>
                <div className="col-12">
                  <span className="text-start">
                    <label
                      htmlFor="total"
                      className="d-inline-block mt-4  w-100"
                    >
                      {t("Total Hours in Project")}
                    </label>
                    <InputText
                      id="total"
                      name="total"
                      className="w-25"
                      value={hours}
                      disabled={true}
                    />
                  </span>
                </div>
                <div className="col-12 mt-1 text-center ">
              <span className="text-start">
                <label
                  htmlFor="KpiValue "
                  className="d-inline-block mt-2  w-100"
                >
                  {t("Kpi Value")}
                </label>
                <InputNumber 
                showButtons 
                min={0}
                  id="KpiValue"
                  name="KpiValue"
                  className="w-100"
                  value={formik.values.KpiValue }
                  onValueChange={formik.handleChange}

                />
                {formik.touched.KpiValue  &&
                  formik.errors.KpiValue  && (
                    <label className="errorMessage w-100">
                      {formik.errors.KpiValue }
                    </label>
                  )}
              </span>
            </div>

                <div className="col-12 mt-1  ">
                  <label
                    htmlFor="Attachment"
                    className="d-inline-block mt-4  w-100"
                  >
                    {t("Upload Photo/Video")}
                  </label>
                  <span className="custom-file-upload">
                    <input
                      type="file"
                      onChange={(e) => {
                        formik.setFieldValue(
                          "Attachment",
                          e.currentTarget.files[0]
                        );
                      }}
                    />
                    {formik.touched.Attachment && formik.errors.Attachment && (
                      <label className="errorMessage w-100">
                        {formik.errors.Attachment}
                      </label>
                    )}
                  </span>
                </div>

                <div className="col-12">
                  <label
                    htmlFor="TaskDetails"
                    className="d-inline-block mt-4  w-100"
                  >
                    {t("Task Details")}
                  </label>
                  <InputTextarea
                    id="TaskDetails"
                    name="TaskDetails"
                    rows={5}
                    cols={10}
                    value={formik.values.TaskDetails}
                    onChange={formik.handleChange}
                    placeholder={t("Write Down Your Task Details")}
                  />
                        {formik.touched.TaskDetails && formik.errors.TaskDetails && (
                      <label className="errorMessage w-100">
                        {formik.errors.TaskDetails}
                      </label>
                    )}
                </div>
                <div className="col-12 mt-4 text-center">
                  <Button
                    label={t("Fill Task")}
                    className="btn btn-primary  w-100"
                    type="submit"
                  />
                </div>
              </div>
            </form>
          </div>
        )}
        {isSubmitted && (
          <div className="row mt-3">
            <form onSubmit={formik2.handleSubmit} className="p-fluid">
              <div className="row">
                <div className="col-12">
                  <label
                    htmlFor="Evalutaion"
                    className="d-inline-block mt-4  w-100"
                  >
                    {t("Task Evaluation Details")}
                  </label>
                  <InputTextarea
                    id="Evalutaion"
                    name="Evalutaion"
                    rows={5}
                    cols={10}
                    value={formik2.values.Evalutaion}
                    onChange={formik2.handleChange}
                    placeholder={t("Write Down Your Task Details")}
                  />

                  {formik2.touched.Evalutaion && formik2.errors.Evalutaion && (
                    <label className="errorMessage w-100">
                      {formik2.errors.Evalutaion}
                    </label>
                  )}
                </div>
                <div className="col-12 mt-4 text-center">
                  <Button
                    label={t("Submit")}
                    className="btn btn-primary  w-100"
                    type="submit"
                  />
                </div>
              </div>
            </form>
          </div>
        )}

      </div>
    </>
  );
};

export default FillTask;
