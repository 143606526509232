import { React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Button } from "primereact/button";
import { Steps } from "primereact/steps";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useFormik } from "formik";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { ProgressBar } from "primereact/progressbar";
import { ProjectService } from "../../shared/Services/ProjectService";
import { SharedService } from "../../shared/Services/sharedService";
import AppConsts from "../../shared/constant/appConsts";
import * as yup from "yup";
import moment from 'moment';
import { ReportService } from "../../shared/Services/ReportService";
const RequestsReports = ({}) => {
  const { t } = useTranslation();

  const [projectStatus, setProjectStatus] = useState([]);


  function  ExportData(projectStatus) {
    SharedService.ShowLoader();
    ReportService.DownloadProjectReport(projectStatus).then((response) => {
      SharedService.HideLoader();
      debugger;
      if (response && response.status === 200 && response.data) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        const formattedDate = moment().format('YYYY-MM-DD HH:mm');
        const filename = `Project Report ${formattedDate}`;
        link.href = url;
        link.setAttribute(
            'download',
            `${filename}.xlsx`,
        );
        document.body.appendChild(link);
        link.click();
        
        link.remove();
      }
    });
  }

  const formik = useFormik({
    initialValues: {
      projectStatus: null,
    },
    validationSchema: yup.object().shape({
      projectStatus: yup.string().nullable(),
    }),
    enableReinitialize: true,
    onSubmit: (data) => {
      ExportData(data.projectStatus);
    },
  });

  useEffect(() => {
    fillProjectsstatus();
    // eslint-disable-next-line
  }, []);

  function fillProjectsstatus() {
    setProjectStatus([
      { name: t("Pending Approval"), code: 1 },
      { name: t("On Going"), code: 2 },
      { name: t("Pending Volunteer Assigment"), code: 3 },
      { name: t("Completed"), code: 4 },
      { name: t("Canceled"), code: 5 },

    ]);
  }

  return (
    <>
      <div className="container mt-3 mb-5 ">
        <div className="row">
          <div className="col-12  title">{t("Requests Reports")}</div>
        </div>

        <div className="row mt-4">
          <form onSubmit={formik.handleSubmit} className="p-fluid">
            <div className="row">
              <div className="col-6 col-md-3 ">
                <label htmlFor="project" className="d-inline-block mt-2  w-100">
                  {t("Project Status")}
                </label>
                <Dropdown
                showClear
                  optionLabel="name"
                  id="projectStatus"
                  options={projectStatus}
                  value={
                    projectStatus[
                      projectStatus.findIndex(
                        (obj) => +obj.code === formik.values.projectStatus
                      )
                    ]
                  }
                  onChange={(e) => {
                    formik.setFieldValue("projectStatus", e?.value?.code);
                  }}
                  className={`w-100`}
                  placeholder={t("select")}
                />
                {formik.touched.projectStatus && formik.errors.projectStatus && (
                  <label className="errorMessage w-100">
                    {formik.errors.projectStatus}
                  </label>
                )}
              </div>

              <div className="col-6 col-md-3 ">
                <label
                  htmlFor="department"
                  className="d-inline-block mt-2  w-100"
                ></label>
                <Button
                  label={t("Export")}
                  className="btn btn-blue  w-100 mt-1"
                  type="submit"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default RequestsReports;
