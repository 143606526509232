import { React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import AppConsts from "../../shared/constant/appConsts";
import { FormMode } from "../../shared/constant/FormMode";
import { SharedService } from "../../shared/Services/sharedService";
import { MessageTypeConstant } from "../../shared/constant/messageTypeConstant";
import { InputText } from "primereact/inputtext";
import { CMSService } from "../../shared/Services/CMSService";

const WhatsNewList = ({}) => {

  const { t } = useTranslation();
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();
  const [totalRecords, setTotalRecords] = useState(0);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
  });

 
  function initializeColumns() {
    setColumns([
      { field: "id", header: t("#ID"),  },
      { field: "titleAr", header: t("Title Ar") },

      { field: "titleEn", header: t("Title En") },
      { field: "descAr", header: t("Description Ar") },
      { field: "descEn", header: t("Description En") },
      {
        field: "isTop",
        header: t("is Top"),
      },
    
      {
        field: "isActive",
        header: t("is Active"),
      },
    

      {
        field: "actions",
        header: t("Actions"),
        body: actionsBodyTemplate,
      },
    ]);
  }
  const actionsBodyTemplate = (rowData) => {
    return (
      <>
            <span
              className="actionList"
              onClick={() => {
                navigate(
                  `/CMS/WhatsNew/new?MODE=${FormMode.EDIT}&ID=${rowData.id}`
                );
              }}
            >
              <i role="button" className="fa fa-pencil" aria-hidden="true"></i>
            </span>
      </>
    );
  };


 

  function fillDataTable(data,reset,status){
    if(status){
      data.status= status;
    }
    if(reset){
      formik.setFieldValue("pageCount", 1);
      formik.setFieldValue("pageNumber", 1);
      data.pageCount = 1;
      data.pageNumber = 1;
      setLazyParams({
        first:0,
        rows:AppConsts.pageSize,
        page:1,
        sortField:null,
        sortOrder:null
      })
    }
    CMSService.WhatsNewList(data).then((res) => {
      SharedService.ShowLoader();

      if (res.data.errorCode === 0) {
        SharedService.HideLoader();

        setTotalRecords(res.data.data.totalFilteredRecords);
        setRows(res.data.data.data);
      }
    });
  }

  const onPageChanged = (e) => {
   setLazyParams(e);
    formik.setFieldValue("pageCount", e.page + 1);
    formik.setFieldValue("pageNumber", e.page + 1);
    formik.values.pageCount = e.page + 1;
    formik.values.pageNumber = e.page + 1;

fillDataTable(formik.values);

  };
  const dynamicColumns = columns.map((col) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        body={col.body}
        hidden={col.hidden}
      />
    );
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      pageNumber: 1,
      pageSize: AppConsts.pageSize,
    },
    enableReinitialize: true,

    onSubmit: (data) => {
      fillDataTable(data,true)
    },
  });


  useEffect(() => {
    initializeColumns();
    fillDataTable(formik.values);
    // eslint-disable-next-line
  }, []);




  return (
    <>
      <div className="container mt-3 mb-5 ">
        <div className="row justify-content-between">
          <div className="col-12 col-md-3 title">{t("WhatsNew List")}</div>
          <div className="col-12 col-md-3 title">
           <Button
              label={t("Create New WhatsNew")}
              className="btn btn-blue w-75"
              type="button"
              onClick={() => {
                navigate("/CMS/WhatsNew/new");
              }}
            />
          
          </div>
        </div>
    
     
        <div className="row mt-4">
          <form onSubmit={formik.handleSubmit} className="p-fluid">
            <div className="row">
              <div className="col-6 col-md-3 ">
                <label
                  htmlFor="name"
                  className="d-inline-block mt-2  w-100"
                >
                  {t("Name")}
                </label>
                <InputText
                  id="name"
                  name="name"
                  className="w-100"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="col-6 col-md-3 ">
                <label
                  htmlFor="deparment"
                  className="d-inline-block mt-2  w-100"
                >
                </label>
                <Button
                  label={t("Search")}
                  className="btn btn-blue  w-100 mt-1"
                  type="submit"
                />
              </div>
            </div>
          </form>
        </div>
        <div className="row mt-5 mb-5 table-div">
          <DataTable
            rows={AppConsts.pageSize}
            paginator
            paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
            value={rows}
            responsiveLayout="scroll"
            stripedRows
            className="centeredColumnContent"
            lazy={true}
            first={lazyParams.first}
            totalRecords={totalRecords}
            onPage={onPageChanged}
            emptyMessage={t("No Records")}
          >
            {dynamicColumns}
          </DataTable>
        </div>
    

      </div>
    </>
  );
};

export default WhatsNewList;
