export const FormMode = {
    NEW: 1,
    VIEW: 2,
    EDIT: 3,
    STATUS: 4,
    ASSIGN: 5,




  };
  