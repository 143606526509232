import { React, useState, useContext } from "react";
import { LayoutContext } from "../Contexts/LayoutContext";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import { AuthService } from "./Services/AuthService";
import moment from "moment";
import { ProjectStatusEnum } from "./constant/ProjectStatusEnum";
const Task = ({ task }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <div className="col-12 col-md-6 p-1 p-md-4">
        <div className="details-card">
          <div className="task-title">
            {AuthService.isArabic() ? task.projectNameAr : task.projectNameEn}
          </div>
          <div className="grey mt-2">
            {AuthService.isArabic()
              ? task.departmentNameAr
              : task.departmentNameEn}
          </div>
          <div className="grey mt-3">
            {t("From")} {moment(new Date(task.from)).format("YYYY-MM-DD")} -{" "}
            {moment(new Date(task.from)).format("YYYY-MM-DD")}
          </div>
          <div className="grey mt-3 row">
            <div className="col-6">{task.requesterName}</div>
            <div className="col-6">{task.supervisorName}</div>
          </div>
          <div className="row mt-5 py-5 border-task">
            <div className="col-9">
              <div className="task-title">
                {t("Pending Tasks")}{" "}
                <span className="orange"> {task.taskCount}</span>
              </div>
            </div>
            <div className="col-3 text-end">
              {task.projectStatus === ProjectStatusEnum.OnGoing && (
                <i
                  className="fa fa-arrow-right pointer"
                  onClick={() => {
                    navigate(
                      `/Tasks/Fill/${task.projectId}/${task.id}?hours=${task.totalHour};pName=${AuthService.isArabic() ? task.projectNameAr : task.projectNameEn};tName=${AuthService.isArabic() ? task.taskNameAr : task.taskNameEn}`
                    );
                  }}
                ></i>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Task;
