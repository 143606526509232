import { React,useEffect,useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { InputText } from "primereact/inputtext";
import { useTranslation } from "react-i18next";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Password } from 'primereact/password';
import { useNavigate  } from "react-router-dom";
import { AuthService } from "../../shared/Services/AuthService";
import { SharedService } from "../../shared/Services/sharedService";
import { MessageTypeConstant } from "../../shared/constant/messageTypeConstant";
const ForgotPasswordPage = ({}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const[isOtpSent,setIsOtpSent]= useState(false);
  useEffect(() => {
 
  
    // eslint-disable-next-line
  }, []);
  const formik = useFormik({
    initialValues: {
      email:"",
  
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
        // email: yup.string().required(t("RequiredField")).email(t("pleaseEnterValidEmail")),
        email: yup.string().required(t("Required Field")).email(t("Please Enter Valid Email")),



    }),

    onSubmit: (data) => {
      SharedService.ShowLoader();

      AuthService.ForgetPassword(data.email).then((res) => {
        SharedService.HideLoader();
        if (res.data.errorCode === 0) {
          setIsOtpSent(true);
        }
      });
    },
  });

  const formik2 = useFormik({
    initialValues: {
        newPassword:"",
        confirmPassword:"",
        otp:""
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
        // email: yup.string().required(t("RequiredField")).email(t("pleaseEnterValidEmail")),
        newPassword: yup.string().required(t("Required Field")),
    confirmPassword: yup
      .string()
      .required(t("Required Field"))
      .oneOf([yup.ref("newPassword"), null], t("Passwords must match")),
      otp: yup.string().required(t("Required Field")).matches(/^[0-9]{6}$/, t('Verification code must be exactly 6 digits')),

      


    }),

    onSubmit: (data) => {
      SharedService.ShowLoader();
      data.email = formik.values.email;
      AuthService.ResetPassword(data).then((res) => {
        SharedService.HideLoader();
        if (res.data.errorCode === 0) {
          SharedService.ShowToast(t("Password Set Successfully"),MessageTypeConstant.Success);

          if (res.data.errorCode === 0) {
            localStorage.setItem("Auth", res.data.data.token);
            localStorage.setItem("userRole", res.data.data.userRole);
            localStorage.setItem("name", res.data.data.name);
            localStorage.setItem("rating", res.data.data.rating);
            localStorage.setItem("profileImage", res.data.data.profileImage);
            window.location.reload();
          }
        }
      });
    },
  });
  return (
    <>
    {!isOtpSent && (  <form onSubmit={formik.handleSubmit} className="p-fluid">
        <div className="row justify-content-center p-1 p-md-5">
          <div className="col-12 col-md-9 col-xl-4">
            <div className="row justify-content-center">
              <div className="col-12 text-center">
                <img
                  src={require("../../assests/Images/footer-logo.png")}
                  className="footer-logo"
                  alt="footer"
                />
              </div>
              <div className="col-12 title text-center mt-3">
                {t("Forgot Password")}
              </div>
              <div className="col-12 grey font-16 text-center mt-3">
              {t("Please enter the email associated with your account below, and we will send you a verification code.")}

                
              </div>

              <div className="col-12 mt-1 text-center ">
                <span className="text-start">
                <label htmlFor="email" className="d-inline-block mt-4 grey w-75">
                  {t("Email")}
                </label>
                <InputText
                  id="email"
                  name="email"
                  className="w-75"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
                {formik.touched.email && formik.errors.email && (
                  <label className="errorMessage w-75">{formik.errors.email}</label>
                )}
                </span>
              </div>
            
              <div className="col-12 mt-4 text-center">
                <Button
                  label={t("Forgot Password")}
                  className="btn btn-primary  w-75"
                  type="submit"
                />
              </div>
            </div>
          </div>
        </div>
      </form>)}
      {isOtpSent && (  <form onSubmit={formik2.handleSubmit} className="p-fluid">
        <div className="row justify-content-center p-1 p-md-5">
          <div className="col-12 col-md-9 col-xl-4">
            <div className="row justify-content-center">
              <div className="col-12 text-center">
                <img
                  src={require("../../assests/Images/footer-logo.png")}
                  className="footer-logo"
                  alt="footer"
                />
              </div>
              <div className="col-12 title text-center mt-3">
              {t("Forgot Password")}
              </div>
              <div className="col-12 grey font-16 text-center mt-3">
              {t("Please enter the verification code.")}

                
              </div>

              <div className="col-12 mt-1 text-center ">
                <span className="text-start">
                <label htmlFor="otp" className="d-inline-block mt-4 grey w-75">
                  {t("Verification code")}
                </label>
                <InputText
                  id="otp"
                  name="otp"
                  className="w-75"
                  value={formik2.values.otp}
                  onChange={formik2.handleChange}
                  keyfilter="pint"
                  maxLength={6}
                />
                {formik2.touched.otp && formik2.errors.otp && (
                  <label className="errorMessage w-75">{formik2.errors.otp}</label>
                )}
                </span>
              </div>
              <div className="col-9 mt-1 text-center">
                <span className="text-start">
                  <label
                    htmlFor="newPassword"
                    className="d-inline-block mt-2  w-100"
                  >
                    {t("Password")}
                  </label>
                  <Password
                    feedback={false}
                    toggleMask
                    id="newPassword"
                    name="newPassword"
                    className="block w-100 text-center"
                    value={formik2.values.newPassword}
                    onChange={formik2.handleChange}
                  />
                  {formik2.touched.newPassword && formik2.errors.newPassword && (
                    <label className="errorMessage w-100">
                      {formik2.errors.newPassword}
                    </label>
                  )}
                </span>
              </div>
              <div className="col-9 mt-1 text-center">
                <span className="text-start">
                  <label
                    htmlFor="confirmPassword"
                    className="d-inline-block mt-2  w-100"
                  >
                    {t("Confirm Password")}
                  </label>
                  <Password
                    feedback={false}
                    toggleMask
                    id="confirmPassword"
                    name="confirmPassword"
                    className="block w-100 text-center"
                    value={formik2.values.confirmPassword}
                    onChange={formik2.handleChange}
                  />
                  {formik2.touched.confirmPassword &&
                    formik2.errors.confirmPassword && (
                      <label className="errorMessage w-100">
                        {formik2.errors.confirmPassword}
                      </label>
                    )}
                </span>
              </div>
              <div className="col-12 mt-4 text-center">
                <Button
                  label={t("Reset password")}
                  className="btn btn-primary  w-75"
                  type="submit"
                />
              </div>
            </div>
          </div>
        </div>
      </form>)}
    
    </>
  );
};

export default ForgotPasswordPage;
