import { useTranslation } from "react-i18next";
import { WizardDataContext } from "../../../Contexts/WizardDataContext";
import { React, useEffect, useContext, useState } from "react";
import "./wizard.css"
import { Steps } from "primereact/steps";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import { ProjectService } from "../../../shared/Services/ProjectService";
import { responsivePropType } from "react-bootstrap/esm/createUtilityClasses";
// import { Modal } from "bootstrap";
import { FormMode } from "../../../shared/constant/FormMode";
import { SharedService } from "../../../shared/Services/sharedService";

const AddProject = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [wizardData, setWizardData] = useState({});
  const [step1, setStep1] = useState(undefined);
  const [step2, setStep2] = useState(undefined);
  const [step3, setStep3] = useState(undefined);
  let query = new URLSearchParams(window.location.search);
  let mode = +query.get("MODE");
  let id = +query.get("ID");
  const { t } = useTranslation();
  const wizardTemplate = (rowData) => {
    return (
      <>
        <div
          className={`wizard-item w-75 h-50 ${
            activeIndex === rowData.index ? "active" : ""
          } ${activeIndex > rowData.index ? "passed" : ""}`}
        >
          <div className="label1">{rowData.label}</div>
          <div className="label2">{rowData.label2}</div>
        </div>
      </>
    );
  };
  const wizardItems = [
    {
      label: t("Project Informtaion"),
      label2: t("Project Details"),
      template: wizardTemplate,
      index: 0,
      command: (event) => {},
    },
    {
      label: t("Tasks"),
      label2: t("Add Tasks"),
      template: wizardTemplate,
      index: 1,
      command: (event) => {},
    },
    {
      label: t("Comment & Submit"),
      label2: t("Submit for Aprroval"),
      command: (event) => {},
      index: 2,
      template: wizardTemplate,
    },
  ];

  useEffect(() => {
    if(mode){
      if(mode === FormMode.ASSIGN){
        setActiveIndex(1);
      }
      wizardData.mode = mode;
      wizardData.id = id;
      setWizardData(wizardData);
      SharedService.ShowLoader();

ProjectService.getById(id).then((res) => {
  SharedService.HideLoader();

  if (res.data.errorCode === 0) {
   setStep3({
    Comment:res.data.data.comment,
    SpecialRequirment:res.data.data.specialRequirment,
    accept:true,
    rejectionReason:res.data.data.rejectionReason
   })
   setStep2({
    ProjectTasks:JSON.parse( JSON.stringify(res.data.data.projectTasks))
   })
  

   setStep1({
    Id:id,
    NameAr:res.data.data.nameAr,
    NameEn:res.data.data.nameEn,
    DescriptionAr:res.data.data.descriptionAr,
    DescriptionEn:res.data.data.descriptionEn,
    NumberOfVolunteersNeeded:res.data.data.numberOfVolunteersNeeded,
    VolunteeringLocation: res.data.data.volunteeringLocation,
    DepartmentId: res.data.data.departmentId,
    From:res.data.data.from,
    To:res.data.data.to,
    ImageFile:res.data.data.imageFile,
    Image:res.data.data.image,
    MajorId:res.data.data.majorId,
    VoulnteerAgeFrom:res.data.data.voulnteerAgeFrom,
    VoulnteerAgeTo:res.data.data.voulnteerAgeTo,
    ProjectVoluntterGender:res.data.data.projectVoluntterGender,
    TransportationProvided:res.data.data.transportationProvided,
    DressCode:res.data.data.dressCode,
    AllowanceProvided:res.data.data.allowanceProvided,
    ShowSpecialRequirment:res.data.data.showSpecialRequirment


  });

  }
});
    }else{
      wizardData.mode = FormMode.NEW;
      setWizardData(wizardData)

    }
    // eslint-disable-next-line
  }, []);

  return (
    <WizardDataContext.Provider
      value={{
        wizardData,
        setWizardData,
        step1,
        setStep1,
        step2,
        setStep2,
        step3,
        setStep3,
        setActiveIndex,
      }}
    >
      <>
        <div className="container m-1 m-md-4">
        <div className="row">
    <div className="col-12 title">{t("New Project")}</div>
    <div className="col-12  grey font-12">{t("Create New Project")}</div>

  </div>
          <div className="row">
            <Steps
              model={wizardItems}
              activeIndex={activeIndex}
              onSelect={(e) => setActiveIndex(e.index)}
              readOnly={false}
              className="project-wizard"
            />
          </div>
        </div>
      
          <div className="home-container mb-5">
            {activeIndex === 0 && <Step1 />}

            {activeIndex === 1 && <Step2 />}

            {activeIndex === 2 && <Step3 />}
          </div>
        
      </>
    </WizardDataContext.Provider>
  );
};

export default AddProject;
