import { React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Button } from "primereact/button";
import { Steps } from "primereact/steps";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useFormik } from "formik";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { ProgressBar } from "primereact/progressbar";
import { ProjectService } from "../../shared/Services/ProjectService";
import { SharedService } from "../../shared/Services/sharedService";
import AppConsts from "../../shared/constant/appConsts";

const KPIsList = ({}) => {

  const { t } = useTranslation();
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);

  const { isAdmin, isVolunteer, isRequester } = useOutletContext();
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: AppConsts.pageSize,
    page: 1,
    sortField: null,
    sortOrder: null,
  });
  function initializeColumns() {
    setColumns([
      { field: "projectName", header: t("Project") },
      { field: "taskName", header: t("Tasks") },

      { field: "kpiName", header: t("KPIName") },
      {
        field: "kpiUnit",
        header: t("KPIUnit"),
      },
      {
        field: "donePercentage",
        header: t("KPI Done"),
        body: progressBodyTemplate,
      },
    ]);
  }
  function fillDataTable(projectId,pageNumber) {
    ProjectService.GetProjectTasksKpis({
      projectId: projectId ?? null,
      pageNumber: pageNumber ?? 1,
      pageSize: AppConsts.pageSize,
    }).then((res) => {
      SharedService.ShowLoader();

      if (res.data.errorCode === 0) {
        SharedService.HideLoader();

        setTotalRecords(res.data.data.totalFilteredRecords);
        setRows(res.data.data.data);
      }
    });
  }

  const progressBodyTemplate = (rowData) => {
    return (
      <>
        <span>{rowData.donePercentage  *100 + "%"}</span>
        <ProgressBar
          value={rowData.donePercentage * 100}
          style={{ height: "8px" }}
          showValue={false}
          color={"#1A507A"}
          className="d-block"
        ></ProgressBar>
      </>
    );
  };

  const onPageChanged = (e) => {
    setLazyParams(e);


    fillDataTable(selectedProject,  e.page + 1);


  };
  const dynamicColumns = columns.map((col) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        body={col.body}
        hidden={col.hidden}
      />
    );
  });
  const formik = useFormik({
    initialValues: {
      projectId: "",
    },
    enableReinitialize: true,

    onSubmit: (data) => {
      setLazyParams({
        first:0,
        rows:AppConsts.pageSize,
        page:1,
        sortField:null,
        sortOrder:null
      })
      setSelectedProject(data.projectId);
      fillDataTable(data.projectId,  1);

    },
  });
 
 

  useEffect(() => {
    initializeColumns();
    fillDataTable();
    fillProjects();

    // eslint-disable-next-line
  }, []);

  function fillProjects(){
    ProjectService.GetProjectsDropdownOptions().then((res) => {
      if (res.data.errorCode === 0) {
        let options = res.data.data.map((x) => {
          return { name: x.name , code: x.id };
        });
        setProjects(options);
      }
    });
  }

  return (
    <>
      <div className="container mt-3 mb-5 ">
        <div className="row">
          <div className="col-12 text-uppercase grey font-12">
            {t("overview")}
          </div>
          <div className="col-12  title">{t("All KPIs")}</div>
        </div>

        <div className="row mt-4">
          <form onSubmit={formik.handleSubmit} className="p-fluid">
            <div className="row">
              <div className="col-6 col-md-3 ">
                <label htmlFor="project" className="d-inline-block mt-2  w-100">
                  {t("Seleect Project")}
                </label>
                <Dropdown
                showClear
                  optionLabel="name"
                  id="projectId"
                  options={projects}
                  value={
                    projects[
                      projects.findIndex(
                        (obj) => +obj.code === formik.values.projectId
                      )
                    ]
                  }                 
                  onChange={(e) => {
                    formik.setFieldValue("projectId", e?.value?.code);
                  }}
                  className={`w-100`}
                  placeholder={t("select")}
                />
              </div>

              <div className="col-6 col-md-3 ">
                <label
                  htmlFor="department"
                  className="d-inline-block mt-2  w-100"
                >
                </label>
                <Button
                  label={t("Search")}
                  className="btn btn-blue  w-100 mt-1"
                  type="submit"
                />
              </div>
            </div>
          </form>
        </div>
        <div className="row mt-5 mb-5 table-div">
          <DataTable
            rows={AppConsts.pageSize}
            paginator
            paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
            value={rows}
            responsiveLayout="scroll"
            stripedRows
            className="centeredColumnContent"
            lazy={true}
            first={lazyParams.first}
            totalRecords={totalRecords}
            onPage={onPageChanged}
            emptyMessage={t("No Records")}
          >
            {dynamicColumns}
          </DataTable>
        </div>
      </div>
    </>
  );
};

export default KPIsList;
