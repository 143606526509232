import { React } from "react";
import { Button } from "primereact/button";
import { SharedService } from "./Services/sharedService";
import { AuthService } from "./Services/AuthService";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Event = ({ event }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  return (
    <>
      <div className="card mx-2 event-card">
        <div className="card-body ">
          <div className="event-image-div">
            <img src={event.image} className="card-img img-fluid" alt="img" />
          </div>
          <div className="small-title  mt-3">
            {AuthService.isArabic() ? event.nameAr : event.nameEn}
          </div>

          <div className="mt-2 grey font-12">
            {AuthService.isArabic() ? event.descriptionAr : event.descriptionEn}{" "}
          </div>

        </div>
        <div className="mt-4 grey font-12">
            <Button
              label={t("Apply Now")}
              className="btn btn-blue  w-100"
              type="button"
              name="back"
              onClick={() => navigate(`/Projects/Details/${event.id}`)}
            />{" "}
          </div>
      </div>
    </>
  );
};

export default Event;
