import { React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProgressBar } from 'primereact/progressbar';
import { Rating } from 'primereact/rating';
import { ProjectService } from "../../../shared/Services/ProjectService";
import {Dialog} from "primereact/dialog";
import TaskExecutionTable from "./TaskExecutionTable";
import { SharedService } from "../../../shared/Services/sharedService";

const TasksTable = ({id}) => {

  const [dialogItem, setDialogItem] = useState(undefined);
  const [showDialog, setShowDialog] = useState(false);
  
  const { t } = useTranslation();
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  const { isAdmin, isVolunteer, isRequester } = useOutletContext();
  const navigate = useNavigate();
  function initializeColumns() {
    setColumns([
        { field: "taskName", header: t("Task Name") },
        { field: "numberOfVolunteer", header: t("Number Of Volunteers") },

      {
        field: "taskDone",
        header: t("Tasks Done"),
        body: progressBodyTemplate,

      },

    
      {
        field: "actions",
        header: t("Actions"),
        body: actionsBodyTemplate,
      },
    ]);
  }
  const actionsBodyTemplate = (rowData) => {
    return (
      <>
        <span className="actionList" onClick={() => {
                setDialogItem({
                  id:rowData.projectTaskId,
                  volunteerId:null
                });
                setShowDialog(true);
              }}>
          <i
            role="button"
            className="fa fa-pencil"
            aria-hidden="true"
          ></i>
        </span>
    

      </>
    );
  };
 
  const progressBodyTemplate = (rowData) => {
  
    return (
      <>
   <span>{rowData.taskDone * 100+"%"}</span>
       <ProgressBar value={rowData.taskDone * 100}    style={{ height: '8px' }} showValue={false} color={"#1A507A"} className="d-block"></ProgressBar> 

      </>
    );
  };




  const dynamicColumns = columns.map((col) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        body={col.body}
        hidden={col.hidden}
      />
    );
  });


  useEffect(() => {
    initializeColumns();
    fillTable();
    // eslint-disable-next-line
  }, []);

  function fillTable(){
    SharedService.ShowLoader();

    ProjectService.GetProjectTasks(id).then((res) => {
      SharedService.HideLoader();

      if (res) {
        setRows(res.data.data);
      }
    });
  }

  return (
   
    
    <div className="row mt-5 mb-5 table-div">
             <Dialog
      visible={showDialog && dialogItem !==undefined}
      style={{minWidth:"80vw", minHeight:"80vh"}}
      onHide={
        ()=>{
          setShowDialog(false);
          setDialogItem(undefined);
        }
      }
>
  <TaskExecutionTable  item={dialogItem}  fillMasterTable={fillTable}/>

    </Dialog>
    <DataTable
      rows={10}
      paginator
      paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
      value={rows}
      responsiveLayout="scroll"
      stripedRows
      className="centeredColumnContent"
      emptyMessage={t("No Records")}
    >
      {dynamicColumns}
    </DataTable>
  </div>
   
  );
};

export default TasksTable;
