import { Roles } from "../constant/Roles";
import http from "./httpService";

export const CMSService = {

  SliderList,
  CreateOrUpdateSlider,
  GetSliderById,
  GetNewsById,
  NewsList,
  CreateOrUpdateNews,
  GetWhatsNewById,
  WhatsNewList,
  CreateOrUpdateWhatsNew,
  EditProfile
};



  function GetSliderById(id){
    return http.get(`/api/Slider/GetById?id=${id}`);
  }

  function SliderList(data){
    return http.post(`/api/Slider/List`, data);
  }

  
  function CreateOrUpdateSlider(data){
    data.ProjectTasks = JSON.stringify(data.ProjectTasks);
      return http.postForm("/api/Slider/CreateOrUpdate", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        }} );
    }

    function GetNewsById(id){
      return http.get(`/api/News/GetById?id=${id}`);
    }
  
    function NewsList(data){
      return http.post(`/api/News/List`, data);
    }

    function CreateOrUpdateNews(data){
      data.ProjectTasks = JSON.stringify(data.ProjectTasks);
        return http.postForm("/api/News/CreateOrUpdate", data, {
          headers: {
            "Content-Type": "multipart/form-data",
          }} );
      }

      function GetWhatsNewById(id){
        return http.get(`/api/WhatsNew/GetById?id=${id}`);
      }
    
      function WhatsNewList(data){
        return http.post(`/api/WhatsNew/List`, data);
      }

      function EditProfile(data){
        return http.postForm("/api/Account/EditProfile", data, {
          headers: {
            "Content-Type": "multipart/form-data",
          }} );
      }

      
      
      function CreateOrUpdateWhatsNew(data){
        data.ProjectTasks = JSON.stringify(data.ProjectTasks);
          return http.postForm("/api/WhatsNew/CreateOrUpdate", data, {
            headers: {
              "Content-Type": "multipart/form-data",
            }} );
        }
    



