import { React, useEffect,useState } from "react";
import { useTranslation } from "react-i18next";
import Task from "../../shared/Task";
import { useNavigate, useOutletContext,useParams } from "react-router-dom";
import { Button } from "primereact/button";
import { ProjectService } from "../../shared/Services/ProjectService";
import { SharedService } from "../../shared/Services/sharedService";
import { MessageTypeConstant } from "../../shared/constant/messageTypeConstant";
import { AuthService } from "../../shared/Services/AuthService";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import { HomeService } from "../../shared/Services/HomeService";
import { ProjectStatusEnum } from "../../shared/constant/ProjectStatusEnum";

const ProjectDetail = ({}) => {
  const { t } = useTranslation();
  const [shiftTypes, setShiftTypes] = useState([]);
  const [selectedShiftType, setSelectedShiftType] = useState(null);
  const { isAdmin, isVolunteer, isRequester } = useOutletContext();
  const navigate = useNavigate();
  const { id } = useParams();
  const [ project, setProject ] = useState({});

const isArabic= AuthService.isArabic();

  useEffect(() => {
    SharedService.ShowLoader();
    fillShifts();
    ProjectService.getById(id).then((res) => {
      SharedService.HideLoader();
      if (res.data.errorCode === 0) {
      setProject(res.data.data);
    
      }
    });
  }, []);

  function fillShifts(){
 
    HomeService.getShiftsLookups().then((res) => {
      if (res.data.errorCode === 0) {
        let options = res.data.data.map((x) => {
          return { name: AuthService.isArabic() ? x.nameAr : x.nameEn, code: x.id };
        });
        setShiftTypes(options);
      }
    });
  
}

  function apply(){
    SharedService.ShowLoader();

    ProjectService.apply(id,selectedShiftType).then((res) => {
      if (res.data.errorCode === 0) {
        SharedService.HideLoader();

        SharedService.ShowToast(t("Applied Successfully"),MessageTypeConstant.Success);
           navigate("/Dashboard")
      }
    });
  }

  return (
    <>
      <div className="container mt-3 mb-5">
        <div className="row">
          <div className="col-12 text-uppercase grey font-12">
            {t("overview")}
          </div>
          <div className="col-12 title">{isArabic ? project.nameAr : project.nameEn}</div>
        </div>
        <div className="row">
        <div className="col-12 col-md-6 p-1 p-md-5">
            <div className="details-card">
            <div className="row">
              
              <div className="col-12 col-md-6 mt-2">{t("Department")}:</div>
              <div className="col-12 col-md-6 mt-2 blue">{isArabic ? project.departmentNameAr : project.departmentNameEn}</div>
              <div className="col-12 col-md-6 mt-2">{t("Date")}:</div>
              <div className="col-12 col-md-6 mt-2 blue">{t("From")}   { moment(new Date( project.from)).format("YYYY-MM-DD")} - { moment(new Date( project.to)).format("YYYY-MM-DD")}</div>
              <div className="col-12 col-md-6 mt-2">{t("Requester")}:</div>
              <div className="col-12 col-md-6 mt-2 blue">{ project.client}</div>
              <div className="col-12 col-md-6 mt-2"></div>
              <div className="col-12 col-md-6 mt-2 blue"></div>
              


            </div>

         
            </div>
          </div>
          <div className="col-12 col-md-6 p-1 p-md-5">
            <div className="details-card">
            <div className="row">
              
              <div className="col-12 col-md-6 mt-2">{t("Description")}:</div>
              <div className="col-12 col-md-6 mt-2 blue">{isArabic ? project.descriptionAr : project.descriptionEn}</div>
          
              


            </div>

         
            </div>
          </div>
          {/* <div className="col-12 col-md-6 p-1 p-md-5">
            <div className="details-card">
            <div className="row">
              
              <div className="col-12 mt-3 navy-blue">{t("Types Of Tasks Required")}</div>
              <div className="col-12 mt-3">-attending booths at the mails and different location </div>
              <div className="col-12 mt-3">-attending booths at the mails and different location </div>
              <div className="col-12 mt-3">-attending booths at the mails and different location </div>


              


            </div>

         
            </div>
          </div> */}
          <div className="col-12 col-md-6 p-1 p-md-5">
            <div className="details-card">
            <div className="row">
              
              <div className="col-12 col-md-6 mt-2 navy-blue">{t("Total Number Of Volunteers Needed")}:</div>
              <div className="col-12 col-md-6 mt-2 blue">{ project.numberOfVolunteersNeeded}</div>

              <div className="col-12 col-md-6 mt-2 navy-blue">{t("Locations")}:</div>
              <div className="col-12 col-md-6 mt-2 blue">{ project.volunteeringLocation}</div>

              <div className="col-12 col-md-6 mt-2 navy-blue">{t("Dress Code")}:</div>
              <div className="col-12 col-md-6 mt-2 blue">{ project.dressCode}</div>

              <div className="col-12 col-md-6 mt-2 navy-blue">{t("Transporation Provided")}:</div>
              <div className="col-12 col-md-6 mt-2 blue">{ project.transportationProvided ? t("Yes") : t("No")}</div>

              <div className="col-12 col-md-6 mt-2 navy-blue">{t("Allowancec Provided")}:</div>
              <div className="col-12 col-md-6 mt-2 blue">{ project.allowanceProvided ? t("Yes") : t("No")}</div>
{  project.showSpecialRequirment && (<>      <div className="col-12 col-md-6 mt-2 navy-blue">{t("Special Requirements")}:</div>
              <div className="col-12 col-md-6 mt-2 blue">{ project.specialRequirment}</div></>)}
        
          
        
              


            </div>

         
            </div>
          </div>
{project.projectStatus === ProjectStatusEnum.PendingVolunteerAssigment && (<>

  <div className="col-12 mt-1 text-center mb-5">
              <span className="text-start">
                <label
                  htmlFor="shiftTime"
                  className="d-inline-block mt-2  w-100"
                >
                  {t("Shift")}
                </label>
                <Dropdown
                 
                  optionLabel="name"
                  id="shiftTime"
                  value={
                    shiftTypes[
                      shiftTypes.findIndex(
                        (obj) => obj.code === selectedShiftType
                      )
                    ]
                  }
                  options={shiftTypes}
                  onChange={(e) => {
                    setSelectedShiftType( e.value.code);
                  }}
                  className={`w-100`}
                  placeholder={t("select")}
                />
               
              </span>
            </div>
      
          <div className="col-12 mt-3">
            <div className="row justify-content-end">
              <div className="col-12 col-md-4 text-end">
              <Button
                  label={t("Apply Now")}
                  className="btn btn-blue w-100"
                  type="button"
                  onClick={()=>{
                    debugger;
                    if(selectedShiftType <0 || selectedShiftType === null) {
                      SharedService.ShowToast(t("Please Select Shift Type"),MessageTypeConstant.Error);
                      return ;
                    }
                    apply();
                  }}
                />
              </div>
            </div>
          </div>
</>)}
        </div>
      
      </div>
    </>
  );
};

export default ProjectDetail;
